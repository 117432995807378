
html {
  background-color: #000000; }

body {
  min-height: 100vh!important;
  background-color: #000000!important; }

img {
  max-width: 100%; }

a {
  outline: 0; }

a:focus {
  outline: 0!important; }

.fail-block {
  text-align: center; }

.fail__text {
  font-family: 'RobotoMono-Bold';
  font-size: 50px;
  color: #ffffff; }

.fail__404 {
  font-family: 'RobotoMono-Bold';
  font-size: 100px;
  color: #ffffff;

 }  // Fonts, sizes

.title-black {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000; }


// Aligns

.align-center {
  text-align: center; }

.align-left {
  text-align: left; }

.little-title {
  text-transform: none; }

// Paddings
.no-padding {
  padding: 0; }


// Colors

.bg-orange {
  background-color: #f6a623; }

.bg-blue {
  background-color: #3852d5; }

.bg-green {
  background-color: green; }

body .btn-green {
  background-image: linear-gradient(343deg, #61e365, #86e989); }

body .btn-blue {
  background-image: linear-gradient(340deg,#3d4dd4,#4b6cd6); }

.bg-gray {
  background-color: #eeeeee; }

.color_gray {
  color: rgba(155, 155, 155, 0.5) !important; }


// Form

body {
  input[type=button], input[type=email],input[type=tel], input[type=submit], input[type=text], select, textarea {
    -webkit-appearance: none;
    border-radius: 0; }

  input:focus {
    padding-left: 5px; }

  textarea:focus {
    padding-left: 5px; }

  .wpcf7-validation-errors {
    display: none!important; }

  .wpcf7-not-valid {
    border: 1px solid #d0011b!important; }

  .wpcf7-not-valid-tip {
    font-family: 'OpenSans-Regular';
    font-size: 8px!important;
    color: #d0011b; }

  .wpcf7-mail-sent-ok {
    display: block;
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    margin: 0!important;
    font-weight: normal;
    color: #80e883;
    border: 0!important;
    text-align: center; } }


.page-id-41 #carousel {
  width: 800px;
  margin: 40px auto 0;
  height: 600px;
  position: relative; }


// update

.rev-box-avatar img {
  border-radius: 50%; }

.spu-content {
  font-family: 'OpenSans-Regular';
  font-size: 14px; }

body .spu-content p {
  padding: 5px 25px;
  margin: 0; }

body .spu-close {
  color: #fff!important;
  opacity: 1;
  font-family: 'OpenSans-Regular';
  transition: all 0.1s;
  text-shadow: none; }

body .spu-close:hover, .spu-close:focus {
  color: #000;
  opacity: 1;
  transition: all 0.1s; }

body .spu-box {
  transition: 0s; }

body .spu-box {
  border: solid 1px #3852d5;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 10px 1px rgba(0,0,0,.06);
  box-shadow: 0 1px 10px 1px rgba(0,0,0,.06);
  padding: 0 0 25px 0!important;
  transition: 0s; }


body .spu-content h3 {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  background-color: #3852d5;
  padding: 15px 0!important;
  text-align: center;
  margin: 0; }

body .spu-content h4 {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  background-color: #3852d5;
  padding: 55px 0!important;
  text-align: center;
  margin: 0; }

/**/

body .rev-box-info {
  vertical-align: top;
  margin-left: 10px; }


.page-template {
  background: #000!important; }


.page__default {
  padding: 25px 40px!important;
  top: -100px;
  border: 1px solid #3852d5;
  background: #ffffff;
  position: relative;
  font-family: 'OpenSans-Regular'; }

.page__default p,
.page__default li,
.page__default a {
  font-family: 'OpenSans-Regular'; }


.alignleft {
  float: left;
  margin-right: 10px; }


// Slick

.middle__slider_item {
  display: none; }

.middle__slider_item:first-child {
  display: block; }

.slick-initialized .middle__slider_item {
  display: block; }


// Headhesive

.headhesive {
  position: fixed;
  width: 100%;
  background: #3852d5;
  //background: url("../img/headhesive.jpg") no-repeat
  background-size: cover;
  transform: translateY(-120%);
  z-index: 9;
  height: 89px; }

.headhesive--stick {
  transform: translateY(0); }

.headhesive--stick .header-nav {
  padding-top: 30px; }


.header__sticky {
  position: fixed;
  width: 100%;
  background-color: #4664d5;
  padding: 20px 0;
  top: 0;
  transition: 0.5s;
  z-index: 4;
  .header-nav {
    padding-top: 15px; } }


// Error 404

.error404 .front-page {
  height: 100vh;
  position: relative; }

.error404 .footer {
  position: absolute;
  width: 100%; }

.error404 .front-block {
  background-position: 0 0;
  height: 100%; }

.error {
  text-align: center;
  color: #000;
  padding: 50px 3%;
  position: relative;
  height: 73vh;
  background-color: #fff; }

.error404 p {
  font-size: 20px;
  font-family: 'OpenSans-Regular'; }

.error404 h2, .error404 h3 {
  font-size: 26px;
  font-weight: bold;
  font-family: 'OpenSans-Regular'; }


// Offer

.offer {
  color: #9b9b9b;
  border-color: #9b9b9b;
  font-family: 'OpenSans-Regular';
  font-size: 10px; }

.offer thead {
  background-color: #eee; }

.offer td {
  padding: 5px; }


.offer__bg {
  min-height: 250px!important; }

.page__offer {
  padding: 25px 0; }

.page__offer h3 {
  font-family: 'OpenSans-Regular';
  color: #9b9b9b;
  font-size: 14px; }

.page__offer p {
  font-family: 'OpenSans-Regular';
  color: #9b9b9b;
  font-size: 10px;
  margin-bottom: 2px; }

.offer__link {
  display: block;
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #4a4a4a;
  text-align: right; }

.offer__link:hover {
  text-decoration: none;
  color: #455fd5; }


// tabs

.tabs-hidden {
  display: none; }

// overlay

.overflow {
  overflow: hidden;
  width: 100%; }


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 2;
  display: none; }

.overlay.active {
  display: block; }

#sent_catalog .sent_wrap__icon {
  text-align: center;
  margin-bottom: 20px; }


// Custom



.page-id-1581 .price-block {
  display: none; }
