$break-little: 200px;
$break-default: 607px;
$break-767: 767px;
$break-768: 768px;
$break-992: 992px;
$break-1024: 1024px;
$break-1200: 1200px;

body {
  // Phone
  @media only screen and (min-width: $break-little) and (max-width: $break-768 - 1) {
    overflow-x: hidden;

    .banner_top {
      position: relative;
      &__close {
        text-align: right;
        cursor: pointer;
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 1;
        img {
          width: 50%; } } }

    //Banner 1

    .banner_1 {
      height: 100px;
      .container {
        width: 300px;
        margin: 0 auto;
        max-width: 100%;
        padding: 0; }
      &__common {
        z-index: 1;
        display: flex;
        flex-direction: column; }
      &__wrapper {
        height: 100px; } }
    .banner_1_wrap {
      padding: 20px 0;
      height: 100px;
      &__img_1 {
        display: none; }
      &__title {
        margin-right: 20px;
        width: initial;
        justify-content: center;
        margin-left: 0;
        .title {
          text-align: left;
          font-size: 30px;
          span {
            display: block;
            margin: 5px 0 0 0; } } }
      &__img_2 {
        position: absolute;
        left: initial;
        right: -60px;
        img {
          width: initial;
          height: 100px; } }
      &__text {
        width: 150px;
        margin-bottom: 8px;
        p {
          text-align: left;
          font-size: 10px; } }
      &__btn {
        a {
          width: 150px;
          font-size: 12px; } }
      &__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        top: initial; } }

    // Banner 2

    .banner_2 {
      background-position: 0;
      height: 100px;
      .container {
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0; }
      &__common {
        z-index: 1;
        display: flex;
        flex-direction: column; } }
    .banner_2_wrap {
      height: 100px;
      &__img_1 {
        display: none; }
      &__img_2 {
        margin-right: 15px;
        img {
          width: 143px; } }
      &__amazon {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        p {
          font-size: 10px;
          position: initial; }
        img {
          width: 61px;
          margin: 0 5px; } }
      &__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        position: initial;
        top: initial; }
      &__btn {
        a {
          width: 150px;
          font-size: 12px; } } }

    // Banner 3

    .banner_3 {
      height: 100px;
      .container {
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0; }
      &__common {
        width: 150px;
        z-index: 1;
        display: flex;
        flex-direction: column; } }
    .banner_3_wrap {
      height: 100px;
      &__title {
        justify-content: center;
        margin-left: 0;
        .title {
          font-size: 30px; } }
      &__img_1 {
        margin-right: 20px;
        position: initial;
        display: block;
        img {
          height: auto;
          width: 100%; } }
      &__text {
        width: 300px;
        max-width: 100%;
        text-align: center;
        margin-bottom: 5px;
        p {
          text-align: left;
          font-size: 10px;
          display: flex;
          align-items: center; }
        span {
          display: flex;
          align-items: center;
          width: 70px;
          font-size: 10px;
          padding: 3px 6px; } }
      &__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        position: initial;
        top: initial; }
      &__btn {
        a {
          width: 150px;
          font-size: 12px; } } }

    // Banner 4

    .banner_4 {
      height: 100px;
      .container {
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0; }
      &__common {
        z-index: 1;
        display: flex;
        flex-direction: column; } }
    .banner_4_wrap {
      height: 100px;
      &__title {
        justify-content: center;
        margin: 0 15px 0 0;
        .title {
          font-size: 30px; } }
      &__text {
        width: 300px;
        max-width: 100%;
        text-align: center;
        margin: 0 0 10px 0;
        display: flex;
        p {
          text-align: left;
          font-size: 10px;
          margin-right: 5px;
          width: 85px; }
        img {
          width: 61px;
          top: initial;
          left: initial; } }
      &__img_1 {
        left: -420px;
        img {
          height: 100px; } }
      &__img_2 {
        right: -460px;
        img {
          height: 100px; } }
      &__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        position: initial;
        top: initial; }
      &__btn {
        a {
          width: 150px;
          font-size: 12px; } } } }

  // tablet
  @media only screen and (min-width: $break-768) and (max-width: $break-992 - 1) {

    // Banner 1

    .banner_1 {
      height: 150px;
      &__wrapper {
        height: 150px; } }
    .banner_1_wrap {
      &__img_1 img {
        opacity: 0.3;
        width: 509px; }
      &__img_2 {
        display: none; }
      &__title {
        margin-left: 0; }
      .title {
        margin-left: 0; }
      &__text p {
        font-size: 14px; }
      &__free {
        position: absolute;
        top: 0;
        right: 0; } }

    // Banner 2

    .banner_2_wrap {
      &__img_1 {
        margin-right: 0;
        img {
          height: 90px;
          transform: rotate(-20deg);
          position: relative;
          top: 10px;
          left: -30px; } }
      &__img_2 {
        img {
          width: 140px; } } }

    // Banner 3

    .banner_3_wrap {
      &__img_1 {
        img {
          opacity: 0.3; } }
      &__title {
        margin-left: 0; } }

    // Banner 4

    .banner_4 {
      height: 150px;
      &__wrapper {
        height: 150px; } }
    .banner_4_wrap {
      &__img_2 {
        img {
          opacity: 0.3;
          height: 150px; } }
      &__free {
        position: absolute;
        top: 0;
        right: 0; }
      &__text {
        margin-right: 85px;
        width: 290px; } } }

  // Phone
  @media only screen and (min-width: $break-992) and (max-width: $break-1200 - 1) {
    .banner_1_wrap {
      &__title {
        margin-left: 0; }
      &__text {
        width: 115px; } }
    .banner_2_wrap__img_1 {
      margin-right: 0; }
    .banner_3_wrap {
      &__title {
        margin-left: 0; }
      &__img_1 img {
        opacity: 0.3; } }
    .banner_4_wrap {
      &__text {
        margin-right: 0;
        width: 275px; }
      &__img_2 img {
        opacity: 0.3; } } } }
