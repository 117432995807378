// RobotoMono

@font-face {
  font-family: 'RobotoMono-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoMono-Bold'), url('../fonts/RobotoMono-Bold.woff') format('woff'); }

@font-face {
  font-family: 'RobotoMono-Light';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoMono-Light'), url('../fonts/RobotoMono-Light.woff') format('woff'); }

@font-face {
  font-family: 'RobotoMono-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoMono-Medium'), url('../fonts/RobotoMono-Medium.woff') format('woff'); }

@font-face {
  font-family: 'RobotoMono-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoMono-Regular'), url('../fonts/RobotoMono-Regular.woff') format('woff'); }

// MuseoSansCyrl

@font-face {
  font-family: 'MuseoSansCyrl-Light';
  font-style: normal;
  font-weight: normal;
  src: local('MuseoSansCyrl'), url('../fonts/MuseoSansCyrl.woff') format('woff'); }

@font-face {
  font-family: 'MuseoSansCyrl-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('MuseoSansCyrl_1'), url('../fonts/MuseoSansCyrl_1.woff') format('woff'); }

@font-face {
  font-family: 'MuseoSansCyrl-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('MuseoSansCyrl_3'), url('../fonts/MuseoSansCyrl_3.woff') format('woff'); }

//Geneva-Roman

@font-face {
  font-family: 'GenevaNormal';
  font-style: normal;
  font-weight: normal;
  src: local('GenevaNormal'), url('../fonts/GenevaNormal.woff') format('woff'); }

// Open sans

@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans-ExtraBold'), url('../fonts/OpenSans-ExtraBold.woff') format('woff'); }

@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans-Bold'), url('../fonts/OpenSans-Bold.woff') format('woff'); }

@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans-Regular'), url('../fonts/OpenSans-Regular.woff') format('woff'); }

@font-face {
  font-family: 'OpenSans-Light';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans-Light'), url('../fonts/OpenSans-Light.woff') format('woff'); }
