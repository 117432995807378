// services-2

.bonus__stat {
  padding-top: 40px;
  background-color: #fff; }

.stat__box .why-item {
  width: 320px;
  border: 0;
  min-height: 130px;
  padding: 0 0 10px 0; }

.stat__box .whywe-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.why-item:before {
  transition: 0.5s; }

.why-item:hover:before {
  transition: 0.5s;
  width: 50%; }

.stat__box .why-item {
  margin-bottom: auto; }


// conclusion

.conclusion {
  background-color: #ffffff; }

.conclusion-title {
  text-align: center; }

.conclusion-title .title {
  margin-bottom: 30px; }

.conclusion__about {
  margin: 0 12%; }

.conclusion__center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px; }

.conclusion__img img {
  width: 400px; }

.conclusion__text {
  font-family: 'OpenSans-Regular';
  font-size: 15px;
  text-align: left;
  color: #000000;
  width: 48%; }


// present

.present {
  padding-bottom: 50px;
  background-color: #ffffff; }

.present .container {
  position: relative; }

.present-title {
  text-align: center; }

.present__text {
  font-family: 'OpenSans-Regular';
  font-size: 15px;
  text-align: left;
  color: #000000;
  width: 310px;
  margin: 0 auto; }

.scene {
  position: absolute;
  width: 100%; }

.scene__left {
  float: left;
  position: absolute;
  top: 25px;
  left: 5%; }

.scene__right {
  float: left;
  position: absolute;
  top: 25px;
  right: 5%; }

.scene__block img {
  margin: 0 17px; }

.scene__left img:nth-child(4), .scene__right img:nth-child(1) {
  width: 106px; }

.scene__left img:nth-child(3), .scene__right img:nth-child(2) {
  width: 39px;
  position: relative;
  top: 10px; }

.scene__left img:nth-child(2), .scene__right img:nth-child(3) {
  width: 29px;
  position: relative;
  top: 0; }

.scene__left img:nth-child(1), .scene__right img:nth-child(4) {
  width: 20px;
  position: relative;
  top: -10px; }


// bonus-info

.bonus__form {
  padding-bottom: 75px;
  background-color: #ffffff; }

.bonus-info {
  background-color: #ffffff;
  padding-bottom: 50px; }

.bonus-info .consist-block {
  border: 0;
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0; }

.bonus-info .info-left {
  width: 100%; }

.bonus__form-box {
  border: 1px solid #3852d5;
  border-radius: 0;
  box-shadow: 0 22px 19px -13px rgba(0, 0, 0, 0.29);
  padding: 0 0 25px;
  -webkit-transition: 0s;
  transition: 0s;
  width: 758px;
  max-width: 100%;
  margin: 0 auto; }

body .bonus__form-box h3, body .bonus__form-box h4 {
  font-family: MuseoSansCyrl-Bold;
  font-size: 30px;
  color: #fff;
  background-color: #3852d5;
  margin: 0;
  text-align: center;
  padding: 15px 0; }

