// Contacts

.contacts-box {
  width: 100%;
  position: absolute;
  margin: -50px 0 0 0;
  z-index: 2; }

.contacts-info {
  position: absolute;
  padding: 15px 35px;
  width: 605px;
  max-width: 100%;
  min-height: 623px;
  background-color: #ffffff;
  border: solid 1px #3852d5; }

.info-block p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b;
  display: block;
  margin-bottom: 0; }

.info-block a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b;
  display: block;
  margin-bottom: 0; }

.info-block a:hover {
  text-decoration: none;
  color: #3d4dd4; }

.info-block {
  margin-bottom: 10px; }

.info-main {
  border-bottom: solid 1px #f1f1f1;
  padding-bottom: 5px; }

.info-phone {
  position: relative; }

.info-block i {
  display: inline-block;
  vertical-align: middle; }

.info-box {
  display: inline-block;
  padding-left: 20px;
  vertical-align: middle; }

.contacts-form {
  margin-top: 15px; }

.input_block input:hover, .input_block input:focus {
  border: 1px solid #3d4dd4; }

.input_block textarea:hover, .input_block textarea:focus {
  border: 1px solid #3d4dd4; }

.contacts-form__title {
  text-align: center; }

.contacts-form__title .title-black {
  font-size: 20px;
  color: #9b9b9b;
  margin: 0 0 15px 0; }

.contacts-form .input_submit {
  text-align: left; }

.contacts-form .input_submit input {
  border-radius: 7px!important; }

.contacts-form .input_submit input:hover {
  opacity: .8;
  text-decoration: none;
  -webkit-transition: all .5s;
  transition: all .5s; }


#map {
  min-height: 680px;
  transition: all 0s;
  z-index: 1; }

#map div {
  transition: all 0s; }

