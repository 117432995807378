$break-little: 200px;
$break-default: 607px;
$break-800: 800px;
$break-767: 767px;
$break-768: 768px;
$break-992: 992px;
$break-1024: 1024px;
$break-1200: 1200px;
$break-1279: 1279px;
$break-1280: 1280px;
$break-1366: 1366px;
$break-1440: 1440px;
$break-1600: 1600px;
$break-1680: 1680px;
$break-1920: 1920px;

body {

  @media only screen and  (max-height: 900px) {
    .modal_top {
      display: none; }
    .modal_title {
      padding-top: 57px; } }

  // Phone
  @media only screen and (min-width: $break-little) and (max-width: $break-768 - 1) {
    .price_titles {
      &__name {
        font-size: 8px; } }
    .price_column {
      &__title {}
      .title {
        font-size: 9px;
        padding-left: 10px; } }
    .service_padding {
      padding-left: 25px!important; }
    .service_price {
      padding:  15px 0;
      span {
        font-size: 16px; }
      p {
        min-height: 50px;
        font-size: 8px; } }
    .service_btn {
      width: 55px;
      height: 30px;
      span {
        font-size: 10px; } }

    .personal_order {
      &__title {
        font-size: 14px; } }

    .heateor_sss_horizontal_sharing {
      display: block; }
    .heateor_sss_vertical_sharing {
      display: none; }
    .guid_item__info,
    .my_check,
    .modal_item .wpcf7-submit {
      width: 270px;
      margin: 0 auto; }
    .modal_item {
      width: 270px;
      input {
        width: 100%; } }
    .guide_modal {
      width: 310px; }
    .modal_top {
      display: none; }
    .modal_title {
      padding-top: 57px; }
    .modal_item__title {
      font-size: 22px; }
    .blog-list {
      display: none!important; }
    .blog_p {
      min-height: initial!important;
      padding-bottom: 20px;
      &__title {
        margin: 20px 0 0 0!important;
        .title {
          margin: 0!important; } }
      .services-undertitle {
        margin: 0!important; }
      &__bread {
        .breadcrumbs {
          margin: 20px 0 0 0!important; } } }
    .guide-portfolio {
      height: auto;
      padding-bottom: 30px;
      .container {
        height: auto;
        align-items: flex-start;
        flex-direction: column; }
      &__banner {
        display: none; }
      &__btn {
        padding: 0!important;
        margin: auto!important;
        &:before {
          display: none; } } }

    .guide-portfolio {
      height: auto!important;
      .container {
        height: auto!important; } }

    .header-nav {
      .main_menu {
        display: none; } }
    .headhesive {
      height: 105px; }

    .headhesive--stick .header-nav {
      padding-top: 15px; }
    .mobile-nav-wrap {
      .menu {
        li {
          a {
            width: 100%; } } } }
    .mobile-nav-wrap {
      display: block; }
    .hamburger-menu {
      display: flex;
      top: 45px!important;
      position: absolute;
      right: 0; }

    .front-block {
      background-position: 0 0;
      background: #3e5bd7!important;
      background-image: none;
      height: 100%; }

    .header__sticky {
      padding: 0 0 20px 0; }

    .header-logo {
      text-align: center;
      position: relative;
      margin: 0 0 20px 0;
      left: 0; }

    .lang-block {
      right: 0; }

    .header-nav {
      padding-top: 35px; }

    .header-nav .menu-item a {
      width: 25%;
      font-size: 12px; }

    .header-nav .menu-item:nth-of-type(3) a {
      margin-left: 0; }

    .service-single {
      margin: 15px auto 30px; }

    .info-left {
      padding: 0 15px; }

    .footer-block {
      text-align: center; }

    .footer-nav {
      margin: 0 5px 15px;
      display: block; }

    .footer-center {
      margin-top: 15px; }

    .footer-right .logo {
      text-align: center; }

    .footer-about-text {
      margin-top: 15px; }

    .footer-about-text p {
      text-align: center; }

    .copyright p {
      text-align: center; }

    .offer__link {
      text-align: center; }

    .service-single:nth-last-child(-n+3) {
      margin-bottom: 30px; }

    .services-container {
      text-align: center; }

    .services {
      padding: 0 15px; }

    .consist-block {
      border-right: 0;
      padding-right: 0; }

    .service-info .no-padding {
      padding: 18px 0 57px; }

    .listing_prices {
      &__wrap {
        flex-direction: column;
        align-items: center; } }

    .info-right {
      padding-left: 0;
      width: 100%; }

    .whywe-block {
      text-align: center; }

    .why-item {
      display: inline-block;
      vertical-align: top;
      margin: 0 25px 15px;
      min-height: 220px; }

    .price-block {
      text-align: center; }

    .price-package {
      margin: 0 0 20px;
      width: 100%;
      text-align: left; }

    .package-sale .sale {
      font-size: 12px; }

    .contacts-info {
      margin: 0 auto;
      position: relative;
      padding: 20px 8px; }

    .contacts-box {
      width: 100%;
      position: relative;
      margin: -100px auto 25px auto; }

    .info-box {
      width: 85%; }

    .portfolio-tabs .nav-tabs li a {
      padding: 7px 2px; }

    .portfolio-pane>.active {
      padding: 20px 0; }

    .portfolio-block .container {
      padding: 0; }

    .portfolio-menu nav ul li {
      width: 100%;
      text-align: center; }

    .portfolio-menu nav ul li:first-child a {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

    .portfolio-menu nav ul li:last-child a {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

    .img-wrap {
      margin: 0 5px 15px;
      width: 130px!important;
      height: 130px!important; }

    .portfolio-items img {
      margin: 0 auto;
      width: 130px;
      height: 130px; }

    .portfolio-tabs .nav-tabs {
      width: 282px;
      max-width: 100%;
      margin: 0 auto; }

    .logo-block img {
      width: 300px;
      margin: 0 auto 20px;
      max-width: 100%; }

    .bag-block img {
      width: 300px;
      margin: 0 auto 20px;
      max-width: 100%; }

    .portfolio-pane>.active .slick-slide {
      text-align: center;
      justify-content: center; }

    .service-left-border {
      display: none; }

    .service-right-border {
      display: none; }

    .service-right-bottom-border {
      display: none; }

    .service-left-bottom-border {
      display: none; }

    .service-left-top-border {
      display: none; }

    .service-right-top-border {
      display: none; }

    .header-nav .menu-item a {
      padding: 0; }

    .header-nav .menu {
      margin-top: 10px; }

    .header-nav .current-menu-item a {
      background: none;
      color: #fff; }

    .header-nav .menu-item:hover>.sub-menu {
      display: none; }

    .useful-block {
      padding: 36px 50px; }

    .useful-container {
      top: 0; }

    .useful-checkbox:after {
      top: 12%; }

    .useful-input {
      width: 100%;
      margin-right: 0; }

    .service-left_form {
      width: 100%;
      margin: 0;
      padding: 0 10px; }

    .service-form_right {
      width: 100%;
      margin: 0;
      padding: 0 10px; }

    .footer-form .form-title .title {
      margin: 0 auto 10px auto; }

    #carousel {
      display: none; }

    .site__mobile img {
      margin: 0 auto 20px; }

    .footer-form .form-right {
      margin-left: 0; }

    .footer-form form {
      display: flex;
      flex-direction: column; }

    .footer-form .form-left {
      margin: 15px auto; }

    .stat__box .whywe-block {
      flex-direction: column; }

    .stat__box .why-item {
      min-height: 145px;
      height: 145px;
      margin: 0 auto 15px auto; }

    .conclusion__img {
      width: 100%;
      text-align: center; }

    .conclusion__about {
      margin: 0; }

    .conclusion__text {
      margin-top: 15px;
      width: 100%; }

    .scene__left img:nth-child(2), .scene__right img:nth-child(3) {
      display: none; }

    .scene__left img:nth-child(3), .scene__right img:nth-child(2) {
      display: none; }

    .scene__left img:nth-child(1), .scene__right img:nth-child(4) {
      display: none; }

    #scene {
      z-index: -1;
      opacity: 0.5; }

    .present-title {
      position: relative;
      z-index: 1; }

    .blog-item-content, .blog-item:first-child {
      padding: 0;
      border: none;
      width: 100%;
      margin-bottom: 30px; }

    .post-wrap__img {
      width: 100%; }

    .blog-categories ul {
      flex-direction: column; }

    .blog-categories ul li:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

    .blog-categories ul li {
      text-align: center;
      padding: 5px; }

    .blog-categories ul li:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

    .post-wrap__title .title {
      font-size: 20px; }

    .alm-reveal:nth-child(n+2) .post-wrap, .post-wrap:nth-child(n+2), .post:nth-child(n+2) .post-wrap {
      width: 100%; }

    .post-info {
      justify-content: flex-start; }

    div.heateor_sss_bottom_sharing .heateorSssSharing {
      width: 30px !important; }

    .heateor_sss_vertical_sharing {
      top: 200px!important;
      left: 0!important; }

    .single__content {
      margin: 0 0 20px 0; }

    .info-wrap {
      margin-left: 0; }

    .alignleft {
      float: none; }

    .footer-useful {
      position: initial; }

    .useful-block {
      margin-bottom: 100px; }

    .useful-input {
      margin-bottom: 15px;
      flex-direction: column; }

    .useful-block form .input_submit {
      width: 100%; }
    .call,
    .call_home,
    .call_blue {
      min-height: 240px;
      padding-bottom: 20px;
      form .btn-blue {
        margin: 10px 0 0 0; }
      .call__img {
        top: initial;
        bottom: 0;
        left: 0; } }
    .call_home {
      margin-top: 20px;
      flex-direction: column;
      text-align: right;
      padding-bottom: 20px;
      background: url('../img/call_bg.svg') center no-repeat;
      background-size: cover;
      .call_info {
        padding-left: 120px; } }
    .call_blue {
      margin-top: 20px;
      flex-direction: column;
      text-align: right;
      padding-bottom: 20px;
      background: #4867d6 !important;
      background-size: cover; }
    .call_info {
      padding-left: 85px; }
    .call__img img {
      width: 70px; }

    .rev_fulls,
    .order_fulls {
      width: 100%;
      left: 0;
      overflow: scroll;
      height: 400px; }
    .form_sent {
      width: 100%;
      left: 0; }
    .service-description li, .service-description p {
      text-align: left; }
    .swiper-button-next {
      display: none; }
    .swiper-button-prev {
      display: none; }
    .rev_fulls {
      iframe {
        width: 100%;
        max-width: 100%;
        overflow: scroll!important; } }
    .guide_form {
      max-width: 100%;
      width: 100%; }
    .guide {
      left: 0;
      width: 100%; }
    .banner_catalog {
      margin: 20px auto; }
    .guide_top img {
      display: none; }
    .guide_form__wrapper {
      padding: 20px 10px 0 10px; }
    .banner_catalog__img p {
      top: -35px;
      right: -20px; }
    .srvs_top_left {
      margin: 0 0 40px 0;
      &__list {
        flex-wrap: wrap; } }
    .srvs_top__title .title {
      font-size: 25px; }
    .srvs_top_item {
      margin-bottom: 20px; }
    .srvs_top__wrapper {
      flex-wrap: wrap; }
    .srvs_top_right {
      background-image: none!important;
      background-position: center;
      width: 100% !important;
      background-size: contain!important; }
    .srvs_top_right .swiper_monitor {
      margin: 0!important;
      width: 100% !important;
      .swiper-slide {
        width: 100%;
        height: 150px!important; } }
    .benefits_list {
      flex-wrap: wrap;
      justify-content: center;
      &__item {
        margin-bottom: 30px; } }
    .howtodo__wrapper {
      flex-wrap: wrap;
      &:before {
        display: none; } }
    .howtodo_item {
      width: 50% !important;
      min-width: 50%!important;
      &:nth-child(odd) {
        margin: 0!important; } }
    .howmuch_wrap {
      flex-wrap: wrap;
      &__item {
        margin: 0 auto 50px auto; } }
    .howmuch_last {
      margin: 0 auto;
      .howmuch_item {
        margin: 0 auto 50px auto; } }
    section {
      .title-black {
        font-size: 35px!important; } }
    .youget_wrap {
      flex-wrap: wrap;
      justify-content: center;
      &__item {
        width: 100%;
        margin: 0 0 30px 0; } }
    .benefits {
      padding-top: 55px; } }

  // 768-1024
  @media only screen and (min-width: $break-768) and (max-width: $break-1024 - 1) {
    .srvs_top_right .swiper_monitor .swiper-slide {
      height: 200px!important; }
    .srvs_top_wrap {
      flex-wrap: wrap;
      &__left {
        margin: 0 0 50px 0; }
      &__right {
        margin: 0 auto; } } }

  //768-1024
  @media only screen and (min-width: $break-768) and (max-width: $break-1024 - 1) {
    .howtodo_item {
      width: 120px!important;
      min-width: 120px!important;
      &:nth-child(odd) {
        margin-top: 80px!important; }
      &__text {
        padding: 0 10px!important;
        p {
          font-size: 12px!important; } }
      &__title .title {
        font-size: 20px!important; } }
    .howtodo__wrapper {
      width: 700px;
      max-width: 100%;
      margin: 0 auto;
      &:before {
        width: 600px!important;
        top: 200px!important;
        left: 58px!important; } }
    .howmuch__wrapper {
      flex-wrap: wrap; }
    .howmuch_last {
      width: 100%;
      flex-direction: row;
      margin-top: 50px; }


    .guide-portfolio {
      height: auto;
      padding-bottom: 30px;
      .container {
        height: auto;
        align-items: flex-start;
        flex-direction: column; }
      &__banner {
        display: none; }
      &__btn {
        padding: 0!important;
        margin: auto!important; } }

    .guide-portfolio__btn:before {
      top: 41px; }

    .guide-front {
      .container {
        height: auto;
        align-items: center!important;
        flex-direction: row!important; }
      &__text {
        h2 {
          font-size: 35px; }
        p {
          font-size: 18px; } } }
    .guide-portfolio__btn:before {
      top: 41px; }

    .banner_catalog {
      margin: 20px auto; }
    .guide {
      left: 23%; }
    .rev_fulls {
      iframe {
        width: 100%;
        max-width: 100%; } }
    .call {
      min-height: 200px;
      form .btn-blue {
        margin: 10px 0 0 0; } }
    .call_home,
    .call_blue {
      min-height: 131px;
      form .btn-blue {
        margin: 10px 0 0 0!important; } }
    .useful-input {
      margin-bottom: 15px;
      flex-direction: column; }

    .useful-block form .input_submit {
      width: 100%; }

    .front-block {
      background-position: 0 0; }

    .header-nav .menu-item:nth-of-type(3) a {
      margin-left: 33%; }

    .header-nav .menu-item a {
      width: 120px; }

    .service-single {
      margin: 15px 40px 30px; }

    .service-single:nth-last-child(-n+3) {
      margin-bottom: 30px; }

    .service-left-top-border {
      right: 267px; }

    .service-left-border {
      display: none; }

    .service-right-border {
      display: none; }

    .service-right-bottom-border {
      display: none; }

    .service-left-bottom-border {
      display: none; }

    .service-left-top-border {
      display: none; }

    .service-right-top-border {
      display: none; }

    .service-right-top-border {
      left: 267px; }

    .footer-form .form-left {
      width: 230px; }

    .footer-nav {
      margin: 0 0 0 17px; }

    .footer-left {
      display: inline-block; }

    .footer-center {
      display: inline-block;
      vertical-align: top; }

    .footer-about-text {
      margin-top: 10px; }

    .copyright {
      margin-top: 10px; }

    .services {
      padding: 0 15px; }

    .consist-block {
      border-right: 0;
      padding-right: 0; }

    .service-info .no-padding {
      padding: 18px 0 57px; }

    .info-right {
      padding-left: 0;
      width: 100%; }

    .whywe-block {
      text-align: center; }

    .why-item {
      display: inline-block;
      vertical-align: top;
      margin: 0 25px 15px;
      min-height: 220px; }

    .price-block {
      text-align: center; }

    .price-package {
      width: 70%;
      text-align: left; }

    .contacts-info {
      margin: 0 auto;
      position: relative; }

    .contacts-box {
      width: 100%;
      position: relative;
      margin: -100px auto 25px auto; }

    .img-wrap:nth-child(6n) {
      margin-right: 10px; }

    .img-wrap {
      margin: 0 10px 15px; }

    .portfolio-pane>.active .slick-slide {
      text-align: center; }

    .header-nav .menu-item:hover>.sub-menu {
      display: none; }

    .useful-block {
      padding: 36px 60px; }

    .stat__box .whywe-block {
      flex-direction: column; }

    .stat__box .why-item {
      min-height: 145px;
      height: 145px;
      margin: 0 auto 15px auto; }

    .conclusion__img {
      width: 100%;
      text-align: center; }

    .conclusion__text {
      width: 100%;
      margin-top: 15px; }

    .scene__left img:nth-child(2), .scene__right img:nth-child(3) {
      display: none; }

    .scene__left img:nth-child(3), .scene__right img:nth-child(2) {
      display: none; }

    .scene__left img:nth-child(1), .scene__right img:nth-child(4) {
      display: none; }

    .blog-item-content, .blog-item:first-child {
      padding: 0;
      border: none;
      width: 100%;
      margin-bottom: 30px; }

    .post-wrap__img {
      width: 100%; }

    .swiper-container {
      padding: 0 25px; }
    .swiper-button-next {
      right: 1%!important; }
    .swiper-button-prev {
      left: 1%!important; } }

  //992-1024
  @media only screen and (min-width: $break-992) and (max-width: $break-1024 - 1) {
    .header-nav .menu-item:nth-of-type(3) a {
      margin-left: 48%; } }

  //1024-1200
  @media only screen and (min-width: $break-1024) and (max-width: $break-1200 - 1) {
    .header-nav .menu-item:nth-of-type(3) a {
      margin-left: 23%; } }

  //1200-1280
  @media only screen and (min-width: $break-1200) and (max-width: $break-1280 - 1) {
    .header-nav .menu-item:nth-of-type(3) a {
      margin-left: 36%; } }

  //1024-1280
  @media only screen and (min-width: $break-1024) and (max-width: $break-1280 - 1) {
    .swiper-container {
      padding: 0 25px; }
    .swiper-button-next {
      right: 2%!important; }
    .swiper-button-prev {
      left: 2%!important; }
    .call {
      padding-bottom: 10px;
      form .btn-blue {
        margin: 10px 0 0 0; } }
    .front-block {
      background-position: 0 -200px; }

    .bg-small .front-block {
      background-position: 0 0; }

    .header-nav .menu-item a {
      width: 180px; }

    .service-left-top-border {
      right: 487px; }

    .service-right-top-border {
      left: 487px; }

    .service-single {
      margin: 15px 18px 30px; }

    .footer-form .form-left {
      width: 230px; }

    .footer-nav {
      margin: 0 15px; }

    .info-left {
      width: 550px; }

    .price-package {
      width: 290px;
      margin: 0 10px 20px; }

    .img-wrap:nth-child(6n) {
      margin-right: 38px; }

    .img-wrap {
      margin: 0 38px 15px; }

    .portfolio-pane>.active .slick-slide {
      text-align: center; }

    .useful-block {
      padding: 36px 60px; }

    .conclusion__text {
      text-align: left;
      width: 50%; }

    .conclusion__img img {
      width: 340px; }

    .blog-item-content, .blog-item:first-child {
      width: 580px; }
    .post-wrap__img {
      position: relative;
      width: 550px; }

    .footer-form .form-left input {
      margin-bottom: 10px; }

    .footer-form .form-right {
      margin-left: 0; }

    .call_home .call__info,
    .call_blue .call_info {
      width: 460px; }

    .guide-portfolio__text {
      width: 320px; }

    .howtodo__wrapper:before {
      left: 97px!important; } }


  //1280-1366
  @media only screen and (min-width: $break-1280) and (max-width: $break-1366) {
    .front-block {
      background-position: 0 -200px; }
    .bg-small .front-block {
      background-position: 0 0; }
    .swiper-button-next {
      right: 1%!important; }
    .swiper-button-prev {
      left: 1%!important; } }

  //1400
  @media only screen and (min-width: $break-1200) and (max-width: $break-1440) {
    .srvs_top_right .swiper_monitor .swiper-slide {
      height: 350px!important; } }

  @media only screen and (max-width: $break-1440) {
    .swiper_monitor {
      padding: 0; }
    .srvs_top_right {
      padding-bottom: 25%;
      width: 60%;
      max-width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      height: auto; }
    .srvs_top_right .swiper_monitor .swiper-slide {
      height: 275px;
      background-size: cover;
      background-repeat: no-repeat; }
    .youget_item {
      &:first-child .youget_item__bg {
        width: 100%;
        left: 0; }
      &:nth-child(2) .youget_item__bg {
        width: 100%;
        left: 0; }
      &:nth-child(3) .youget_item__bg {
        width: 100%;
        right: 0; } }
    .howtodo_item {
      width: 150px;
      min-width: 150px;
      &:nth-child(odd) {
        margin-top: 105px; } }
    .howtodo__wrapper:before {
      background-repeat: no-repeat;
      height: 110px;
      width: 752px;
      top: 201px;
      left: 197px; } }

  //1366-1440
  @media only screen and (min-width: $break-1366 +1) and (max-width: $break-1440) {
    .front-block {
      background-position: 0 -220px; }

    .bg-small .front-block {
      background-position: 0 0; }
    .swiper-button-next {
      right: 1%!important; }
    .swiper-button-prev {
      left: 1%!important; } }


  //1440-1600
  @media only screen and (min-width: $break-1440 +1) and (max-width: $break-1600) {
    .front-block {
      background-position: 0 -240px; }

    .bg-small .front-block {
      background-position: 0 0; }
    .swiper-button-next {
      right: 5%!important; }
    .swiper-button-prev {
      left: 5%!important; } }


  //1600-1680
  @media only screen and (min-width: $break-1600 +1) and (max-width: $break-1680) {
    .front-block {
      background-position: 0 -260px; }

    .bg-small .front-block {
      background-position: 0 0; }
    .swiper-button-next {
      right: 10%!important; }
    .swiper-button-prev {
      left: 10%!important; } }


  //1680-1920
  @media only screen and (min-width: $break-1680 +1) and (max-width: $break-1920) {
    .front-block {
      background-position: 0 -260px; }

    .bg-small .front-block {
      background-position: 0 0; } } }
