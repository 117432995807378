// Faq

.faq {
  padding-top: 40px; }



.current {
  display: block; }

.accordion_sub_question {
  &__text {
    display: none; } }

.accordion_cat {
  &__title {
    text-decoration: none!important;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer!important;
    i {
      transform: rotate(180deg);
      margin-right: 15px;
      width: 30px;
      height: 30px;
      background-color: #3852d5;
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight: bold;
      font-size: 25px;
      transition: 0.5s;
      padding: 0 0 4px 0;
      &:before {
        line-height: 0; } }
    .title {
      color: #000;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 30px;
      font-weight: 900; }
    &:hover {
      .title {
        color: #3852d5;
        transition: 0.5s; }
      i {
        color: #fff;
        background-color: #000; } } }
  .active {
    i {
      padding: 0 0 2px 2px;
      transform: rotate(0deg); }
    .title {
      color: #3852d5; } } }


.accordion_sub {
  padding-left: 21px; }

.accordion_sub_question {
  &__title {
    padding: 8px 20px 10px 20px;
    display: flex;
    align-items: center;
    color: #000;
    font-family: 'OpenSans-Regular';
    font-size: 20px;
    text-decoration: none!important;
    transition: 0.5s;
    cursor: pointer!important;
    transition: 0.5s;
    i {
      transform: rotate(180deg);
      margin-right: 15px;
      width: 30px;
      height: 30px;
      color: #000;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight: bold;
      font-size: 25px;
      transition: 0.5s;
      padding: 0 0 4px 0;
      &:before {
        line-height: 0; } }
    &:hover {
      color: #3852d5;
      transition: 0.5s;
      i {
        color: #fff;
        background-color: #000; } } }
  .active {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background-color: #3852d5;
    color: #fff;
    transition: 0.5s;
    i {
      padding: 0 0 2px 2px;
      background-color: #fff;
      color: #3852d5;
      transform: rotate(0deg); } }
  &__text {
    border-radius: 9px;
    background-color: #fbfbfb;
    padding: 40px 24px 18px 66px;
    p {
      font-family: 'OpenSans-Regular';
      font-size: 14px;
      margin-bottom: 20px;
      color: #000; } } }

// Banner

.faq_banner_wrap {
  height: 570px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.faq_banner_item {
  padding: 27px;
  &__title {
    margin-bottom: 10px;
    .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 25px;
      font-weight: 900;
      text-shadow: 0 2px 17px #fafafc;
      color: #7bc039; } }
  &__text {
    margin-bottom: 10px;
    p {
      font-family: 'MuseoSansCyrl-Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      color: rgba(0, 0, 0, 0.68); } }
  &__logo {
    margin-bottom: 272px; }
  &__form {
    form {
      text-align: center;
      input {
        width: 200px;
        max-width: 100%;
        height: 38px;
        line-height: 36px;
        background-color: #ffffff;
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        padding: 0 0 0 10px;
        box-sizing: border-box;
        color: #d8d8d8;
        outline: 0;
        transition: 0.5s;
        border: 2px solid #fff;
        margin-bottom: 15px;
        &::placeholder {
          font-family: 'OpenSans-Regular';
          font-size: 14px;
          color: #d8d8d8;
          transition: 0.5s; }
        &:focus {
          border-color: #7abf39;
          transition: 0.5s; } }
      label {
        font-family: 'OpenSans-Regular';
        font-size: 11px;
        color: #000000;
        margin-left: 9px; }
      .btn-green {
        cursor: pointer;
        margin: 0 auto;
        font-family: 'MuseoSansCyrl-Medium'!important;
        transition: 0.5s;
        display: inline-block;
        width: 200px;
        height: 40px;
        background-image: linear-gradient(-288deg, #b4ec51, #429321);
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        &:hover {
          opacity: .8;
          transition: 0.5s;
          text-decoration: none; } } } } }

.sub_accordion {
  background-color: initial!important;
  cursor: pointer; }
