// Front page

.front-page {
  position: relative;
  padding-bottom: 15px;
  z-index: 0;
  background-color: #ffffff; }

.services-header {
  padding-bottom: 0; }

.portfolio-top {
  padding: 40px 0; }

.front-block {
  position: absolute;
  background: url(../img/main-bg.jpg) 0 -300px #3852d5  no-repeat;
  background-size: 100%;
  height: 344px;
  width: 100%;
  transition: 0.5s;
  z-index: -1; }

.bg-small .front-block {
  position: absolute;
  background: url(../img/main-bg.jpg) 0 -300px #3852d5;
  background-size: 100%;
  width: 100%;
  height: 100%;
  transition: 0.5s; }

.services-bg {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 344px; }

.serice-image img {
  height: 344px; }

.header-nav {
  padding-top: 65px;
  margin: 0;
  width: 100%; }

.menu {
  clear: both; }

.portfolio-items {
  margin-top: 45px; }

.header-nav .menu {
  padding: 0;
  margin: 0; }

.header-nav .menu-item {
  list-style: none;
  transition: all 0.5s; }

.header-nav .menu-item a {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  float: left;
  width: 236px;
  max-width: 100%;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 7px 0;
  position: relative; }

.header-nav .menu-item a:before {
  position: absolute;
  content: '';
  background: #fff;
  height: 3px;
  width: 100%;
  bottom: -2px;
  left: 0;
  -webkit-transition: all .5s;
  transition: all .5s; }

.header-nav .menu-item:nth-of-type(3) a {
  margin-left: 17%; }

.header-logo {
  position: absolute;
  left: 50%;
  margin: -8px 0 0 -72px; }

.header-nav .current-menu-item a {
  background: #ffffff;
  color: #3852d5; }

.header-nav .current-menu-item a:before {
  background: #3852d5; }

.header-nav .sub-menu {
  display: none;
  clear: both;
  width: 242px;
  max-width: 100%;
  padding: 0;
  position: absolute;
  z-index: 3;
  margin-top: 38px;
  transition: all 0.5s; }

.header-nav .sub-menu li a {
  background-color: #ffffff;
  border-bottom: solid 1px #3852d5;
  color: #3852d5;
  font-size: 16px;
  text-transform: none; }

.header-nav .sub-menu li a:before {
  display: none; }

.header-nav .sub-menu li:nth-of-type(3) a {
  margin-left: 0; }

.header-nav .sub-menu .current-menu-item a {
  background: #3852d5;
  color: #ffffff; }

.headhesive--stick .mid {
  padding: 0 15px; }

.lang-block {
  position: absolute;
  top: 0;
  right: 0; }

.lang-block ul {
  border: solid 2px #ffffff; }

.lang-block li {
  height: 23px;
  transition: 0.5s; }

.lang-block li:hover {
  background: rgba(255, 255, 255, 0.30);
  transition: 0.5s; }

.lang-block li a {
  font-family: 'OpenSans-Regular';
  font-size: 11px;
  font-weight: bold;
  color: white;
  text-transform: uppercase; }


.lang-block li.wpml-ls-current-language {
  background: white; }


.lang-block li.wpml-ls-current-language a {
  color: #3852d5; }


.wpml-ls-display {
  display: none; }


.lang-block .wpml-ls-legacy-list-horizontal {
  padding: 0;


 }  // services

.services-blocks {
  padding-bottom: 50px; }

.services-title {
  margin-top: 30px; }

.small-title {
  margin-top: 30px; }

.services-title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase; }

.small-title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase; }

.services-undertitle {
  margin: 0 auto;
  width: 840px;
  max-width: 100%; }

.services-undertitle .undertitle {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  text-align: center; }

.undertitle-margin {
  margin-top: 25px; }

// tabs

.main-tabs {
  position: relative; }

.services-tabs {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  z-index: 1;
  position: relative;
  border: 0!important; }

.services-tabs .left-border {
  left: 4px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }

.services-tabs .right-border {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px; }

.services-list {
  width: 142px;
  max-width: 100%;
  min-height: 40px;
  background-color: #3852d5;
  border: solid 1px #ffffff;
  float: none!important;
  display: inline-block!important; }

.services-tabs .active {
  background-image: linear-gradient(331deg, #f5f7ff, #ffffff); }

.services-link {
  background-color: transparent!important;
  border: 0!important;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff; }

.services-tabs .active .services-link {
  color: #3852d5; }


// single service

.services-container {
  width: 970px;
  max-width: 100%;
  margin: 15px auto 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.service-single {
  width: 274px;
  max-width: 100%;
  min-height: 320px;
  position: relative;
  display: inline-block;
  padding: 0;
  -webkit-transition: all .5s;
  transition: all .5s;
  margin: 15px 0 30px 0; }

.service-box {
  width: 274px;
  min-height: 320px;
  position: absolute;
  border: solid 2px rgba(0, 0, 0, 0.06);
  top: 0;
  -webkit-transition: all .5s;
  box-shadow: 0 5px 20px 6px rgba(0, 0, 0, 0.06);
  transition: all .5s;
  cursor: default; }

.service-single:hover .service-box {
  border: solid 2px #3852d5;
  -webkit-transition: all .5s;
  transition: all .5s; }

.service-single:nth-last-child(-n+3) {
  margin-bottom: 0; }

.service-top {
  position: relative; }

.service-top .service-title .title {
  position: absolute;
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000;
  top: 15px;
  left: 27px; }

.service-img {
  position: relative; }

.service-img img {
  min-height: 98px; }

.service-img:before {
  position: absolute;
  content: '';
  background-color: #000000;
  height: 2px;
  left: 50%;
  bottom: -1px;
  width: 0;
  transition: all 0.5s; }

.service-description {
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.49);
  min-height: 159px; }


.service-description ul {
  padding: 0 0 0 20px; }

.service-description p,
.service-description li {
  font-family: 'OpenSans-Regular';
  font-size: 13px;
  color: #9b9b9b; }

.service-bottom {
  background-color: #ffffff;
  padding: 10px 20px 10px 27px;
  text-align: right; }

.srv-btm-scnd {
  padding: 10px 20px 20px 27px; }

.service-time {
  display: none;
  width: 45%;
  position: relative;
  margin-top: 10px; }

.service-time .time {
  padding-left: 30px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  display: none; }

.service-time:before {
  position: absolute;
  content: '';
  background: url("../img/icon-time.svg") no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  -webkit-transition: all .5s;
  transition: all .5s;
  display: none; }

.service-btn {
  display: inline-block;
  width: 55%;
  vertical-align: top; }

.btn-more {
  padding: 10.5px 20px;
  border-radius: 7px;
  background-image: linear-gradient(343deg, #3d4dd4, #4b6cd6);
  box-shadow: 0 2px 4px 0 rgba(61, 76, 212, 0.44);
  font-family: 'RobotoMono-Regular';
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  border: 1px solid transparent;
  -webkit-transition: all .5s;
  transition: all .5s; }

#complex .service-single .service-img:before {
  left: 0;
  width: 80%;
  margin: 0 27px; }

.service-included {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  margin-top: 10px;
  position: relative; }

.service-included .included {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  text-transform: uppercase;
  padding-left: 30px; }

.service-included:before {
  position: absolute;
  content: '';
  background: url("../img/icon-yes.svg") no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  -webkit-transition: all .5s;
  transition: all .5s; }

.service-full {
  width: 100%;
  text-align: center;
  position: relative;
  padding: 10.5px 0;
  top: -18px; }

.service-full .btn-full {
  border-radius: 7px;
  background-image: linear-gradient(331deg, #61e365, #86e989);
  border: 1px solid #61e365;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  padding: 10.5px 25px;
  height: 40px;
  transition: 0.5s; }


// hover
.service-full .btn-full:hover {
  transition: 0.5s;
  text-decoration: none;
  background-image: linear-gradient(331deg,#61e365,rgb(162, 247, 164)); }

.header-nav .menu-item:hover > .sub-menu {
  display: block;
  transition: all 0.5s; }

.header-nav .menu-item {
  &:last-child {
    margin-bottom: 0; } }

.header-nav .menu-item a:hover {
  background: rgb(187, 198, 255);
  text-decoration: none;
  color: #3852d5; }

.header-nav .menu-item a:hover:before {
  background: #3852d5;
  -webkit-transition: all .5s;
  transition: all .5s; }

.services-link:hover {
  color: #ffffff; }

.service-single:hover {
  -webkit-box-shadow: 0px 22px 19px -13px rgba(56,82,213,1);
  -moz-box-shadow: 0px 22px 19px -13px rgba(56,82,213,1);
  box-shadow: 0px 22px 19px -13px rgba(56,82,213,1);
  cursor: pointer; }

.service-single:hover .service-box {
  border: solid 2px #3852d5;
  top: -5px;
  min-height: 325px;
  -webkit-transition: all .5s;
  transition: all .5s; }

.service-single:hover .service-img:before {
  left: 0;
  width: 80%;
  -webkit-transition: all .5s;
  transition: all .5s;
  margin: 0 27px; }

.service-single:hover .service-time:before {
  background: url("../img/icon-time_black.svg") no-repeat;
  -webkit-transition: all .5s;
  transition: all .5s; }

.btn-more:hover {
  color: #ffffff;
  opacity: 0.8;
  text-decoration: none;
  -webkit-transition: all .5s;
  transition: all .5s; }

.btn-more:focus {
  color: #ffffff;
  opacity: 0.8;
  text-decoration: none;
  -webkit-transition: all .5s;
  transition: all .5s; }

#complex .service-single:hover .service-img:before {
  left: 0;
  width: 100%;
  -webkit-transition: all .5s;
  transition: all .5s;
  margin: 0; }

.btn-full:hover {
  text-decoration: none; }


// borders

.main-tabs {
  position: relative; }

.service-left-top-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  top: -35px;
  right: 690px;
  transform-origin: 0 50%;
  animation: bracket-line-top 0.7s ease-out 0.7s;
  animation-fill-mode: both; }

.service-left-border {
  position: absolute;
  content: '';
  width: 3px;
  height: 0;
  background: #ffffff;
  margin-top: 0;
  top: -35px;
  left: 0;
  transform-origin: 0 50%;
  animation: bracket-line 1.7s ease-out 1.7s;
  animation-fill-mode: both; }

.service-left-bottom-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  bottom: 35px;
  right: 0;
  transform-origin: 0 50%;
  animation: bracket-line-bottom 2s ease-out 2s;
  animation-fill-mode: both; }

.service-right-border {
  position: absolute;
  height: 100%;
  width: 3px;
  background: #000;
  right: 0;
  top: -35px; }

.service-right-top-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  top: -35px;
  left: 690px;
  transform-origin: 0 50%;
  animation: bracket-line-top 0.7s ease-out 0.7s;
  animation-fill-mode: both; }

.service-right-border {
  position: absolute;
  content: '';
  width: 3px;
  height: 0;
  background: #ffffff;
  top: -35px;
  right: 0;
  transform-origin: 0 50%;
  animation: bracket-line 1.7s ease-out 1.7s;
  animation-fill-mode: both; }

.service-right-bottom-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  bottom: 35px;
  left: 0;
  transform-origin: 0 50%;
  animation: bracket-line-bottom 2s ease-out 2s;
  animation-fill-mode: both; }


// fade service

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }


.show {
  animation-name: show;
  -webkit-animation-name: show;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/* hang burger */

.mobile-nav-wrap {
  display: none;
  .menu {
    background-color: #fff;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    li {
      width: 100%;
      a {
        color: #3852d5;
        display: block;
        padding: 15px 0;
        text-align: center;
        text-decoration: none;
        transition: all 0.3s ease-in-out; } } } }

// styling of hamburger menu

.hamburger-menu {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: -30px;
  padding: 0 25px;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer; }
  .menu-item {
    background: black;
    display: block;
    height: 3px;
    margin: 0 0 10px;
    transition: all 0.3s ease-in-out;
    width: 40px; }
  &.open {
    .menu-item {
      margin: 0 0 5px;
      &:first-child {
        transform: rotate(45deg);
        transform-origin: 10px; }
      &:nth-child(2) {
        opacity: 0; }
      &:nth-child(3) {
        transform: rotate(-45deg);
        transform-origin: 8px; } } } }

// Guide

.guide-front {
  height: 85px!important;
  .container {
    height: 85px!important; }
  &__img {
    img {
      width: 54px!important; } }
  &__text {
    margin: 0!important; }
  &__btn {
    margin: auto!important;
    padding-right: 138px!important;
    &:before {
      position: absolute;
      content: '';
      background: url('../img/img_arrow_quide_small.svg') no-repeat!important;
      background-size: cover;
      width: 74px!important;
      height: 27px!important;
      top: 46px!important;
      left: -20px!important;
      z-index: 1; } }
  &__banner {
    img {
      width: 153px!important;
      z-index: 1;
      position: relative; } } }
