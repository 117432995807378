.blic-header {
  margin-bottom: 30px; }

.blic-wrap {
  margin-bottom: 50px; }

.blic-form .useful-input {
  width: 500px;
  max-width: 100%;
  justify-content: space-between;
  margin-bottom: 15px; }

.blic-form .useful-input label {
  text-align: left; }

.blic-form .useful-input input {
  width: 340px;
  max-width: 100%; }

.blic-form .input_submit input {
  width: 200px; }

.page-id-1544 .headhesive--stick {
  display: none!important; }
