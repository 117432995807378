// Useful sets

.useful-container {
  position: relative;
  top: -100px; }

.useful-block {
  width: 100%;
  min-height: 464px;
  background-color: #ffffff;
  border: solid 1px #3852d5;
  padding: 36px 200px; }

.useful-block form {
  text-align: center; }

.useful-name {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 16px;
  color: #000000; }

.useful-text {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

.useful-checkbox {
  position: relative;
  margin-bottom: 30px;
  padding-left: 20px; }

.useful-checkbox:before {
  position: absolute;
  left: -40px;
  top: 20%;
  content: '';
  width: 27px;
  height: 27px;
  border: solid 3px #3852d5;
  border-radius: 50%; }

.useful-checkbox:after {
  position: absolute;
  left: -35px;
  top: 4%;
  content: '✔';
  font-family: 'MuseoSansCyrl-Bold';
  color: #1d1c1b;
  font-size: 30px; }

.useful-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  vertical-align: top; }

.useful-input input {
  box-sizing: border-box;
  font-size: 14px;
  font-family: "OpenSans-Regular"; }


.useful-block input:focus {
  padding-left: 10px; }


.useful-input label {
  margin-right: 10px;
  margin-bottom: 0; }

.useful-btn input {
  background-image: linear-gradient(336deg, #466bd4, #4b6cd6);
  transition: 0.5s;
  padding: 0 10px;
  box-sizing: border-box; }

.useful-input  input::placeholder {
  font-size: 14px;
  font-family: "OpenSans-Regular"; }

.useful-btn input:hover {
  background-image: linear-gradient(336deg, #466bd4, #4b6cd6);
  opacity: 0.8;
  transition: 0.5s; }

.useful-btn input:focus {
  background-image: linear-gradient(336deg, #466bd4, #4b6cd6);
  opacity: 0.8;
  transition: 0.5s; }

.useful-ps {
  text-align: center;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  margin-top: 40px; }


.screen-reader-response {
  display: none; }


.useful-block form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }


.useful-block form .input_submit {
  margin-top: 0; }


.footer-useful {
  position: absolute;
  width: 100%;
  bottom: 0; }

