// footer

.footer {
  background-color: #000000;
  padding: 35px 0 40px; }

.footer-block {
  padding: 0;
  &:first-child {
    padding-left: 0; }
  &:last-child {
    padding-right: 0; } }


.footer-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap; }

.footer-form .form-title .title {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  width: 317px;
  max-width: 100%;
  margin: 0 0 10px 0; }

.footer-form .form-left {
  display: inline-block;
  margin-top: 6px;
  max-width: 100%; }

.footer-form .form-left input {
  width: 180px;
  max-width: 100%;
  min-height: 27px;
  background-color: #d8d8d8;
  font-family: 'OpenSans-Regular';
  border: 0;
  transition: all 0.5s;
  padding-left: 10px; }

.footer-form .form-right {
  display: inline-block;
  vertical-align: top;
  margin-left: 14px; }

.footer-form .form-right input {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  width: 137px!important;
  height: 40px;
  border-radius: 7px;
  background-image: linear-gradient(340deg, #3d4dd4, #4b6cd6);
  border: 0; }

.footer-form .form-right input:hover {
  opacity: 0.8; }

.footer-form .form-right input:focus {
  opacity: 0.8;
  outline: 0; }

.footer-contacts {
  margin-top: 15px; }

.footer-contacts p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #4a4a4a;
  margin-bottom: 0; }

.footer-contacts a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #4a4a4a; }

.footer-right .logo {
  text-align: right; }

.footer-right .logo img {
  width: 89px; }

.footer-about-text {
  margin-top: 20px; }

.footer-about-text p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  text-align: right;
  color: #4a4a4a; }

.copyright {
  margin-top: 15px; }

.copyright p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  text-align: right;
  color: #4a4a4a; }

.footer-nav {
  display: inline-block;
  vertical-align: top; }

.footer-nav .big-nav {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0; }

.footer-nav .small-nav {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b;
  display: block; }

.footer-years {
  width: 100%;
  text-align: center; }


.footer-years p {
  font-family: OpenSans-Regular;
  font-size: 12px; }


// Form subscribe



.footer-form .nf-form-fields-required {
  display: none; }

.footer-form .nf-error .nf-field-element input {
  border: 1px solid red; }

.footer-form .nf-error-wrap {
  display: none; }

.footer-form .nf-form-errors {
  display: none; }

.footer-form .nf-response-msg {
  font-family: 'OpenSans-Regular';
  color: #fff;
  font-size: 16px; }

.footer-form .nf-form-title {
  display: none; }

.footer-form .submit-container {
  margin-bottom: 0; }

.footer-form .email-container {
  margin-bottom: 0;


 }  // focus

.ajax-loader.is-active {
  visibility: visible; }

.ajax-loader {
  visibility: hidden;
  display: inline-block;
  background-image: url('../img/ajax-loader.gif');
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  margin: 0 0 0 4px;
  vertical-align: middle; }

.footer-form .form-left input:focus {
  outline: 0;
  padding: 0 10px;
  transition: all 0.5s; }

.footer-form .form-left .your-email {
  position: relative; }

.footer-form .form-left .wpcf7-not-valid-tip {
  position: absolute;
  left: 0;
  bottom: -20px; }

.form-right button:hover {
  opacity: 0.7; }

.footer-contacts a:hover {
  color: #455fd5;
  text-decoration: none; }

.footer-nav a:hover {
  color: #455fd5;
  text-decoration: none; }


