@charset "UTF-8";
html {
  background-color: #000000; }

body {
  min-height: 100vh !important;
  background-color: #000000 !important; }

img {
  max-width: 100%; }

a {
  outline: 0; }

a:focus {
  outline: 0 !important; }

.fail-block {
  text-align: center; }

.fail__text {
  font-family: 'RobotoMono-Bold';
  font-size: 50px;
  color: #ffffff; }

.fail__404 {
  font-family: 'RobotoMono-Bold';
  font-size: 100px;
  color: #ffffff; }

.title-black {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000; }

.align-center {
  text-align: center; }

.align-left {
  text-align: left; }

.little-title {
  text-transform: none; }

.no-padding {
  padding: 0; }

.bg-orange {
  background-color: #f6a623; }

.bg-blue {
  background-color: #3852d5; }

.bg-green {
  background-color: green; }

body .btn-green {
  background-image: linear-gradient(343deg, #61e365, #86e989); }

body .btn-blue {
  background-image: linear-gradient(340deg, #3d4dd4, #4b6cd6); }

.bg-gray {
  background-color: #eeeeee; }

.color_gray {
  color: rgba(155, 155, 155, 0.5) !important; }

body input[type=button], body input[type=email], body input[type=tel], body input[type=submit], body input[type=text], body select, body textarea {
  -webkit-appearance: none;
  border-radius: 0; }

body input:focus {
  padding-left: 5px; }

body textarea:focus {
  padding-left: 5px; }

body .wpcf7-validation-errors {
  display: none !important; }

body .wpcf7-not-valid {
  border: 1px solid #d0011b !important; }

body .wpcf7-not-valid-tip {
  font-family: 'OpenSans-Regular';
  font-size: 8px !important;
  color: #d0011b; }

body .wpcf7-mail-sent-ok {
  display: block;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  margin: 0 !important;
  font-weight: normal;
  color: #80e883;
  border: 0 !important;
  text-align: center; }

.page-id-41 #carousel {
  width: 800px;
  margin: 40px auto 0;
  height: 600px;
  position: relative; }

.rev-box-avatar img {
  border-radius: 50%; }

.spu-content {
  font-family: 'OpenSans-Regular';
  font-size: 14px; }

body .spu-content p {
  padding: 5px 25px;
  margin: 0; }

body .spu-close {
  color: #fff !important;
  opacity: 1;
  font-family: 'OpenSans-Regular';
  transition: all 0.1s;
  text-shadow: none; }

body .spu-close:hover, .spu-close:focus {
  color: #000;
  opacity: 1;
  transition: all 0.1s; }

body .spu-box {
  transition: 0s; }

body .spu-box {
  border: solid 1px #3852d5;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.06);
  padding: 0 0 25px 0 !important;
  transition: 0s; }

body .spu-content h3 {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  background-color: #3852d5;
  padding: 15px 0 !important;
  text-align: center;
  margin: 0; }

body .spu-content h4 {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  background-color: #3852d5;
  padding: 55px 0 !important;
  text-align: center;
  margin: 0; }

/**/
body .rev-box-info {
  vertical-align: top;
  margin-left: 10px; }

.page-template {
  background: #000 !important; }

.page__default {
  padding: 25px 40px !important;
  top: -100px;
  border: 1px solid #3852d5;
  background: #ffffff;
  position: relative;
  font-family: 'OpenSans-Regular'; }

.page__default p,
.page__default li,
.page__default a {
  font-family: 'OpenSans-Regular'; }

.alignleft {
  float: left;
  margin-right: 10px; }

.middle__slider_item {
  display: none; }

.middle__slider_item:first-child {
  display: block; }

.slick-initialized .middle__slider_item {
  display: block; }

.headhesive {
  position: fixed;
  width: 100%;
  background: #3852d5;
  background-size: cover;
  transform: translateY(-120%);
  z-index: 9;
  height: 89px; }

.headhesive--stick {
  transform: translateY(0); }

.headhesive--stick .header-nav {
  padding-top: 30px; }

.header__sticky {
  position: fixed;
  width: 100%;
  background-color: #4664d5;
  padding: 20px 0;
  top: 0;
  transition: 0.5s;
  z-index: 4; }
  .header__sticky .header-nav {
    padding-top: 15px; }

.error404 .front-page {
  height: 100vh;
  position: relative; }

.error404 .footer {
  position: absolute;
  width: 100%; }

.error404 .front-block {
  background-position: 0 0;
  height: 100%; }

.error {
  text-align: center;
  color: #000;
  padding: 50px 3%;
  position: relative;
  height: 73vh;
  background-color: #fff; }

.error404 p {
  font-size: 20px;
  font-family: 'OpenSans-Regular'; }

.error404 h2, .error404 h3 {
  font-size: 26px;
  font-weight: bold;
  font-family: 'OpenSans-Regular'; }

.offer {
  color: #9b9b9b;
  border-color: #9b9b9b;
  font-family: 'OpenSans-Regular';
  font-size: 10px; }

.offer thead {
  background-color: #eee; }

.offer td {
  padding: 5px; }

.offer__bg {
  min-height: 250px !important; }

.page__offer {
  padding: 25px 0; }

.page__offer h3 {
  font-family: 'OpenSans-Regular';
  color: #9b9b9b;
  font-size: 14px; }

.page__offer p {
  font-family: 'OpenSans-Regular';
  color: #9b9b9b;
  font-size: 10px;
  margin-bottom: 2px; }

.offer__link {
  display: block;
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #4a4a4a;
  text-align: right; }

.offer__link:hover {
  text-decoration: none;
  color: #455fd5; }

.tabs-hidden {
  display: none; }

.overflow {
  overflow: hidden;
  width: 100%; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 2;
  display: none; }

.overlay.active {
  display: block; }

#sent_catalog .sent_wrap__icon {
  text-align: center;
  margin-bottom: 20px; }

.page-id-1581 .price-block {
  display: none; }

@media only screen and (max-height: 900px) {
  body .modal_top {
    display: none; }
  body .modal_title {
    padding-top: 57px; } }

@media only screen and (min-width: 200px) and (max-width: 767px) {
  body .price_titles__name {
    font-size: 8px; }
  body .price_column .title {
    font-size: 9px;
    padding-left: 10px; }
  body .service_padding {
    padding-left: 25px !important; }
  body .service_price {
    padding: 15px 0; }
    body .service_price span {
      font-size: 16px; }
    body .service_price p {
      min-height: 50px;
      font-size: 8px; }
  body .service_btn {
    width: 55px;
    height: 30px; }
    body .service_btn span {
      font-size: 10px; }
  body .personal_order__title {
    font-size: 14px; }
  body .heateor_sss_horizontal_sharing {
    display: block; }
  body .heateor_sss_vertical_sharing {
    display: none; }
  body .guid_item__info,
  body .my_check,
  body .modal_item .wpcf7-submit {
    width: 270px;
    margin: 0 auto; }
  body .modal_item {
    width: 270px; }
    body .modal_item input {
      width: 100%; }
  body .guide_modal {
    width: 310px; }
  body .modal_top {
    display: none; }
  body .modal_title {
    padding-top: 57px; }
  body .modal_item__title {
    font-size: 22px; }
  body .blog-list {
    display: none !important; }
  body .blog_p {
    min-height: initial !important;
    padding-bottom: 20px; }
    body .blog_p__title {
      margin: 20px 0 0 0 !important; }
      body .blog_p__title .title {
        margin: 0 !important; }
    body .blog_p .services-undertitle {
      margin: 0 !important; }
    body .blog_p__bread .breadcrumbs {
      margin: 20px 0 0 0 !important; }
  body .guide-portfolio {
    height: auto;
    padding-bottom: 30px; }
    body .guide-portfolio .container {
      height: auto;
      align-items: flex-start;
      flex-direction: column; }
    body .guide-portfolio__banner {
      display: none; }
    body .guide-portfolio__btn {
      padding: 0 !important;
      margin: auto !important; }
      body .guide-portfolio__btn:before {
        display: none; }
  body .guide-portfolio {
    height: auto !important; }
    body .guide-portfolio .container {
      height: auto !important; }
  body .header-nav .main_menu {
    display: none; }
  body .headhesive {
    height: 105px; }
  body .headhesive--stick .header-nav {
    padding-top: 15px; }
  body .mobile-nav-wrap .menu li a {
    width: 100%; }
  body .mobile-nav-wrap {
    display: block; }
  body .hamburger-menu {
    display: flex;
    top: 45px !important;
    position: absolute;
    right: 0; }
  body .front-block {
    background-position: 0 0;
    background: #3e5bd7 !important;
    background-image: none;
    height: 100%; }
  body .header__sticky {
    padding: 0 0 20px 0; }
  body .header-logo {
    text-align: center;
    position: relative;
    margin: 0 0 20px 0;
    left: 0; }
  body .lang-block {
    right: 0; }
  body .header-nav {
    padding-top: 35px; }
  body .header-nav .menu-item a {
    width: 25%;
    font-size: 12px; }
  body .header-nav .menu-item:nth-of-type(3) a {
    margin-left: 0; }
  body .service-single {
    margin: 15px auto 30px; }
  body .info-left {
    padding: 0 15px; }
  body .footer-block {
    text-align: center; }
  body .footer-nav {
    margin: 0 5px 15px;
    display: block; }
  body .footer-center {
    margin-top: 15px; }
  body .footer-right .logo {
    text-align: center; }
  body .footer-about-text {
    margin-top: 15px; }
  body .footer-about-text p {
    text-align: center; }
  body .copyright p {
    text-align: center; }
  body .offer__link {
    text-align: center; }
  body .service-single:nth-last-child(-n+3) {
    margin-bottom: 30px; }
  body .services-container {
    text-align: center; }
  body .services {
    padding: 0 15px; }
  body .consist-block {
    border-right: 0;
    padding-right: 0; }
  body .service-info .no-padding {
    padding: 18px 0 57px; }
  body .listing_prices__wrap {
    flex-direction: column;
    align-items: center; }
  body .info-right {
    padding-left: 0;
    width: 100%; }
  body .whywe-block {
    text-align: center; }
  body .why-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 25px 15px;
    min-height: 220px; }
  body .price-block {
    text-align: center; }
  body .price-package {
    margin: 0 0 20px;
    width: 100%;
    text-align: left; }
  body .package-sale .sale {
    font-size: 12px; }
  body .contacts-info {
    margin: 0 auto;
    position: relative;
    padding: 20px 8px; }
  body .contacts-box {
    width: 100%;
    position: relative;
    margin: -100px auto 25px auto; }
  body .info-box {
    width: 85%; }
  body .portfolio-tabs .nav-tabs li a {
    padding: 7px 2px; }
  body .portfolio-pane > .active {
    padding: 20px 0; }
  body .portfolio-block .container {
    padding: 0; }
  body .portfolio-menu nav ul li {
    width: 100%;
    text-align: center; }
  body .portfolio-menu nav ul li:first-child a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  body .portfolio-menu nav ul li:last-child a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  body .img-wrap {
    margin: 0 5px 15px;
    width: 130px !important;
    height: 130px !important; }
  body .portfolio-items img {
    margin: 0 auto;
    width: 130px;
    height: 130px; }
  body .portfolio-tabs .nav-tabs {
    width: 282px;
    max-width: 100%;
    margin: 0 auto; }
  body .logo-block img {
    width: 300px;
    margin: 0 auto 20px;
    max-width: 100%; }
  body .bag-block img {
    width: 300px;
    margin: 0 auto 20px;
    max-width: 100%; }
  body .portfolio-pane > .active .slick-slide {
    text-align: center;
    justify-content: center; }
  body .service-left-border {
    display: none; }
  body .service-right-border {
    display: none; }
  body .service-right-bottom-border {
    display: none; }
  body .service-left-bottom-border {
    display: none; }
  body .service-left-top-border {
    display: none; }
  body .service-right-top-border {
    display: none; }
  body .header-nav .menu-item a {
    padding: 0; }
  body .header-nav .menu {
    margin-top: 10px; }
  body .header-nav .current-menu-item a {
    background: none;
    color: #fff; }
  body .header-nav .menu-item:hover > .sub-menu {
    display: none; }
  body .useful-block {
    padding: 36px 50px; }
  body .useful-container {
    top: 0; }
  body .useful-checkbox:after {
    top: 12%; }
  body .useful-input {
    width: 100%;
    margin-right: 0; }
  body .service-left_form {
    width: 100%;
    margin: 0;
    padding: 0 10px; }
  body .service-form_right {
    width: 100%;
    margin: 0;
    padding: 0 10px; }
  body .footer-form .form-title .title {
    margin: 0 auto 10px auto; }
  body #carousel {
    display: none; }
  body .site__mobile img {
    margin: 0 auto 20px; }
  body .footer-form .form-right {
    margin-left: 0; }
  body .footer-form form {
    display: flex;
    flex-direction: column; }
  body .footer-form .form-left {
    margin: 15px auto; }
  body .stat__box .whywe-block {
    flex-direction: column; }
  body .stat__box .why-item {
    min-height: 145px;
    height: 145px;
    margin: 0 auto 15px auto; }
  body .conclusion__img {
    width: 100%;
    text-align: center; }
  body .conclusion__about {
    margin: 0; }
  body .conclusion__text {
    margin-top: 15px;
    width: 100%; }
  body .scene__left img:nth-child(2), body .scene__right img:nth-child(3) {
    display: none; }
  body .scene__left img:nth-child(3), body .scene__right img:nth-child(2) {
    display: none; }
  body .scene__left img:nth-child(1), body .scene__right img:nth-child(4) {
    display: none; }
  body #scene {
    z-index: -1;
    opacity: 0.5; }
  body .present-title {
    position: relative;
    z-index: 1; }
  body .blog-item-content, body .blog-item:first-child {
    padding: 0;
    border: none;
    width: 100%;
    margin-bottom: 30px; }
  body .post-wrap__img {
    width: 100%; }
  body .blog-categories ul {
    flex-direction: column; }
  body .blog-categories ul li:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  body .blog-categories ul li {
    text-align: center;
    padding: 5px; }
  body .blog-categories ul li:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  body .post-wrap__title .title {
    font-size: 20px; }
  body .alm-reveal:nth-child(n+2) .post-wrap, body .post-wrap:nth-child(n+2), body .post:nth-child(n+2) .post-wrap {
    width: 100%; }
  body .post-info {
    justify-content: flex-start; }
  body div.heateor_sss_bottom_sharing .heateorSssSharing {
    width: 30px !important; }
  body .heateor_sss_vertical_sharing {
    top: 200px !important;
    left: 0 !important; }
  body .single__content {
    margin: 0 0 20px 0; }
  body .info-wrap {
    margin-left: 0; }
  body .alignleft {
    float: none; }
  body .footer-useful {
    position: initial; }
  body .useful-block {
    margin-bottom: 100px; }
  body .useful-input {
    margin-bottom: 15px;
    flex-direction: column; }
  body .useful-block form .input_submit {
    width: 100%; }
  body .call,
  body .call_home,
  body .call_blue {
    min-height: 240px;
    padding-bottom: 20px; }
    body .call form .btn-blue,
    body .call_home form .btn-blue,
    body .call_blue form .btn-blue {
      margin: 10px 0 0 0; }
    body .call .call__img,
    body .call_home .call__img,
    body .call_blue .call__img {
      top: initial;
      bottom: 0;
      left: 0; }
  body .call_home {
    margin-top: 20px;
    flex-direction: column;
    text-align: right;
    padding-bottom: 20px;
    background: url("../img/call_bg.svg") center no-repeat;
    background-size: cover; }
    body .call_home .call_info {
      padding-left: 120px; }
  body .call_blue {
    margin-top: 20px;
    flex-direction: column;
    text-align: right;
    padding-bottom: 20px;
    background: #4867d6 !important;
    background-size: cover; }
  body .call_info {
    padding-left: 85px; }
  body .call__img img {
    width: 70px; }
  body .rev_fulls,
  body .order_fulls {
    width: 100%;
    left: 0;
    overflow: scroll;
    height: 400px; }
  body .form_sent {
    width: 100%;
    left: 0; }
  body .service-description li, body .service-description p {
    text-align: left; }
  body .swiper-button-next {
    display: none; }
  body .swiper-button-prev {
    display: none; }
  body .rev_fulls iframe {
    width: 100%;
    max-width: 100%;
    overflow: scroll !important; }
  body .guide_form {
    max-width: 100%;
    width: 100%; }
  body .guide {
    left: 0;
    width: 100%; }
  body .banner_catalog {
    margin: 20px auto; }
  body .guide_top img {
    display: none; }
  body .guide_form__wrapper {
    padding: 20px 10px 0 10px; }
  body .banner_catalog__img p {
    top: -35px;
    right: -20px; }
  body .srvs_top_left {
    margin: 0 0 40px 0; }
    body .srvs_top_left__list {
      flex-wrap: wrap; }
  body .srvs_top__title .title {
    font-size: 25px; }
  body .srvs_top_item {
    margin-bottom: 20px; }
  body .srvs_top__wrapper {
    flex-wrap: wrap; }
  body .srvs_top_right {
    background-image: none !important;
    background-position: center;
    width: 100% !important;
    background-size: contain !important; }
  body .srvs_top_right .swiper_monitor {
    margin: 0 !important;
    width: 100% !important; }
    body .srvs_top_right .swiper_monitor .swiper-slide {
      width: 100%;
      height: 150px !important; }
  body .benefits_list {
    flex-wrap: wrap;
    justify-content: center; }
    body .benefits_list__item {
      margin-bottom: 30px; }
  body .howtodo__wrapper {
    flex-wrap: wrap; }
    body .howtodo__wrapper:before {
      display: none; }
  body .howtodo_item {
    width: 50% !important;
    min-width: 50% !important; }
    body .howtodo_item:nth-child(odd) {
      margin: 0 !important; }
  body .howmuch_wrap {
    flex-wrap: wrap; }
    body .howmuch_wrap__item {
      margin: 0 auto 50px auto; }
  body .howmuch_last {
    margin: 0 auto; }
    body .howmuch_last .howmuch_item {
      margin: 0 auto 50px auto; }
  body section .title-black {
    font-size: 35px !important; }
  body .youget_wrap {
    flex-wrap: wrap;
    justify-content: center; }
    body .youget_wrap__item {
      width: 100%;
      margin: 0 0 30px 0; }
  body .benefits {
    padding-top: 55px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  body .srvs_top_right .swiper_monitor .swiper-slide {
    height: 200px !important; }
  body .srvs_top_wrap {
    flex-wrap: wrap; }
    body .srvs_top_wrap__left {
      margin: 0 0 50px 0; }
    body .srvs_top_wrap__right {
      margin: 0 auto; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  body .howtodo_item {
    width: 120px !important;
    min-width: 120px !important; }
    body .howtodo_item:nth-child(odd) {
      margin-top: 80px !important; }
    body .howtodo_item__text {
      padding: 0 10px !important; }
      body .howtodo_item__text p {
        font-size: 12px !important; }
    body .howtodo_item__title .title {
      font-size: 20px !important; }
  body .howtodo__wrapper {
    width: 700px;
    max-width: 100%;
    margin: 0 auto; }
    body .howtodo__wrapper:before {
      width: 600px !important;
      top: 200px !important;
      left: 58px !important; }
  body .howmuch__wrapper {
    flex-wrap: wrap; }
  body .howmuch_last {
    width: 100%;
    flex-direction: row;
    margin-top: 50px; }
  body .guide-portfolio {
    height: auto;
    padding-bottom: 30px; }
    body .guide-portfolio .container {
      height: auto;
      align-items: flex-start;
      flex-direction: column; }
    body .guide-portfolio__banner {
      display: none; }
    body .guide-portfolio__btn {
      padding: 0 !important;
      margin: auto !important; }
  body .guide-portfolio__btn:before {
    top: 41px; }
  body .guide-front .container {
    height: auto;
    align-items: center !important;
    flex-direction: row !important; }
  body .guide-front__text h2 {
    font-size: 35px; }
  body .guide-front__text p {
    font-size: 18px; }
  body .guide-portfolio__btn:before {
    top: 41px; }
  body .banner_catalog {
    margin: 20px auto; }
  body .guide {
    left: 23%; }
  body .rev_fulls iframe {
    width: 100%;
    max-width: 100%; }
  body .call {
    min-height: 200px; }
    body .call form .btn-blue {
      margin: 10px 0 0 0; }
  body .call_home,
  body .call_blue {
    min-height: 131px; }
    body .call_home form .btn-blue,
    body .call_blue form .btn-blue {
      margin: 10px 0 0 0 !important; }
  body .useful-input {
    margin-bottom: 15px;
    flex-direction: column; }
  body .useful-block form .input_submit {
    width: 100%; }
  body .front-block {
    background-position: 0 0; }
  body .header-nav .menu-item:nth-of-type(3) a {
    margin-left: 33%; }
  body .header-nav .menu-item a {
    width: 120px; }
  body .service-single {
    margin: 15px 40px 30px; }
  body .service-single:nth-last-child(-n+3) {
    margin-bottom: 30px; }
  body .service-left-top-border {
    right: 267px; }
  body .service-left-border {
    display: none; }
  body .service-right-border {
    display: none; }
  body .service-right-bottom-border {
    display: none; }
  body .service-left-bottom-border {
    display: none; }
  body .service-left-top-border {
    display: none; }
  body .service-right-top-border {
    display: none; }
  body .service-right-top-border {
    left: 267px; }
  body .footer-form .form-left {
    width: 230px; }
  body .footer-nav {
    margin: 0 0 0 17px; }
  body .footer-left {
    display: inline-block; }
  body .footer-center {
    display: inline-block;
    vertical-align: top; }
  body .footer-about-text {
    margin-top: 10px; }
  body .copyright {
    margin-top: 10px; }
  body .services {
    padding: 0 15px; }
  body .consist-block {
    border-right: 0;
    padding-right: 0; }
  body .service-info .no-padding {
    padding: 18px 0 57px; }
  body .info-right {
    padding-left: 0;
    width: 100%; }
  body .whywe-block {
    text-align: center; }
  body .why-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 25px 15px;
    min-height: 220px; }
  body .price-block {
    text-align: center; }
  body .price-package {
    width: 70%;
    text-align: left; }
  body .contacts-info {
    margin: 0 auto;
    position: relative; }
  body .contacts-box {
    width: 100%;
    position: relative;
    margin: -100px auto 25px auto; }
  body .img-wrap:nth-child(6n) {
    margin-right: 10px; }
  body .img-wrap {
    margin: 0 10px 15px; }
  body .portfolio-pane > .active .slick-slide {
    text-align: center; }
  body .header-nav .menu-item:hover > .sub-menu {
    display: none; }
  body .useful-block {
    padding: 36px 60px; }
  body .stat__box .whywe-block {
    flex-direction: column; }
  body .stat__box .why-item {
    min-height: 145px;
    height: 145px;
    margin: 0 auto 15px auto; }
  body .conclusion__img {
    width: 100%;
    text-align: center; }
  body .conclusion__text {
    width: 100%;
    margin-top: 15px; }
  body .scene__left img:nth-child(2), body .scene__right img:nth-child(3) {
    display: none; }
  body .scene__left img:nth-child(3), body .scene__right img:nth-child(2) {
    display: none; }
  body .scene__left img:nth-child(1), body .scene__right img:nth-child(4) {
    display: none; }
  body .blog-item-content, body .blog-item:first-child {
    padding: 0;
    border: none;
    width: 100%;
    margin-bottom: 30px; }
  body .post-wrap__img {
    width: 100%; }
  body .swiper-container {
    padding: 0 25px; }
  body .swiper-button-next {
    right: 1% !important; }
  body .swiper-button-prev {
    left: 1% !important; } }

@media only screen and (min-width: 992px) and (max-width: 1023px) {
  body .header-nav .menu-item:nth-of-type(3) a {
    margin-left: 48%; } }

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  body .header-nav .menu-item:nth-of-type(3) a {
    margin-left: 23%; } }

@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  body .header-nav .menu-item:nth-of-type(3) a {
    margin-left: 36%; } }

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  body .swiper-container {
    padding: 0 25px; }
  body .swiper-button-next {
    right: 2% !important; }
  body .swiper-button-prev {
    left: 2% !important; }
  body .call {
    padding-bottom: 10px; }
    body .call form .btn-blue {
      margin: 10px 0 0 0; }
  body .front-block {
    background-position: 0 -200px; }
  body .bg-small .front-block {
    background-position: 0 0; }
  body .header-nav .menu-item a {
    width: 180px; }
  body .service-left-top-border {
    right: 487px; }
  body .service-right-top-border {
    left: 487px; }
  body .service-single {
    margin: 15px 18px 30px; }
  body .footer-form .form-left {
    width: 230px; }
  body .footer-nav {
    margin: 0 15px; }
  body .info-left {
    width: 550px; }
  body .price-package {
    width: 290px;
    margin: 0 10px 20px; }
  body .img-wrap:nth-child(6n) {
    margin-right: 38px; }
  body .img-wrap {
    margin: 0 38px 15px; }
  body .portfolio-pane > .active .slick-slide {
    text-align: center; }
  body .useful-block {
    padding: 36px 60px; }
  body .conclusion__text {
    text-align: left;
    width: 50%; }
  body .conclusion__img img {
    width: 340px; }
  body .blog-item-content, body .blog-item:first-child {
    width: 580px; }
  body .post-wrap__img {
    position: relative;
    width: 550px; }
  body .footer-form .form-left input {
    margin-bottom: 10px; }
  body .footer-form .form-right {
    margin-left: 0; }
  body .call_home .call__info,
  body .call_blue .call_info {
    width: 460px; }
  body .guide-portfolio__text {
    width: 320px; }
  body .howtodo__wrapper:before {
    left: 97px !important; } }

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  body .front-block {
    background-position: 0 -200px; }
  body .bg-small .front-block {
    background-position: 0 0; }
  body .swiper-button-next {
    right: 1% !important; }
  body .swiper-button-prev {
    left: 1% !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  body .srvs_top_right .swiper_monitor .swiper-slide {
    height: 350px !important; } }

@media only screen and (max-width: 1440px) {
  body .swiper_monitor {
    padding: 0; }
  body .srvs_top_right {
    padding-bottom: 25%;
    width: 60%;
    max-width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    height: auto; }
  body .srvs_top_right .swiper_monitor .swiper-slide {
    height: 275px;
    background-size: cover;
    background-repeat: no-repeat; }
  body .youget_item:first-child .youget_item__bg {
    width: 100%;
    left: 0; }
  body .youget_item:nth-child(2) .youget_item__bg {
    width: 100%;
    left: 0; }
  body .youget_item:nth-child(3) .youget_item__bg {
    width: 100%;
    right: 0; }
  body .howtodo_item {
    width: 150px;
    min-width: 150px; }
    body .howtodo_item:nth-child(odd) {
      margin-top: 105px; }
  body .howtodo__wrapper:before {
    background-repeat: no-repeat;
    height: 110px;
    width: 752px;
    top: 201px;
    left: 197px; } }

@media only screen and (min-width: 1367px) and (max-width: 1440px) {
  body .front-block {
    background-position: 0 -220px; }
  body .bg-small .front-block {
    background-position: 0 0; }
  body .swiper-button-next {
    right: 1% !important; }
  body .swiper-button-prev {
    left: 1% !important; } }

@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  body .front-block {
    background-position: 0 -240px; }
  body .bg-small .front-block {
    background-position: 0 0; }
  body .swiper-button-next {
    right: 5% !important; }
  body .swiper-button-prev {
    left: 5% !important; } }

@media only screen and (min-width: 1601px) and (max-width: 1680px) {
  body .front-block {
    background-position: 0 -260px; }
  body .bg-small .front-block {
    background-position: 0 0; }
  body .swiper-button-next {
    right: 10% !important; }
  body .swiper-button-prev {
    left: 10% !important; } }

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
  body .front-block {
    background-position: 0 -260px; }
  body .bg-small .front-block {
    background-position: 0 0; } }

@font-face {
  font-family: 'RobotoMono-Bold';
  font-style: normal;
  font-weight: normal;
  src: local("RobotoMono-Bold"), url("../fonts/RobotoMono-Bold.woff") format("woff"); }

@font-face {
  font-family: 'RobotoMono-Light';
  font-style: normal;
  font-weight: normal;
  src: local("RobotoMono-Light"), url("../fonts/RobotoMono-Light.woff") format("woff"); }

@font-face {
  font-family: 'RobotoMono-Medium';
  font-style: normal;
  font-weight: normal;
  src: local("RobotoMono-Medium"), url("../fonts/RobotoMono-Medium.woff") format("woff"); }

@font-face {
  font-family: 'RobotoMono-Regular';
  font-style: normal;
  font-weight: normal;
  src: local("RobotoMono-Regular"), url("../fonts/RobotoMono-Regular.woff") format("woff"); }

@font-face {
  font-family: 'MuseoSansCyrl-Light';
  font-style: normal;
  font-weight: normal;
  src: local("MuseoSansCyrl"), url("../fonts/MuseoSansCyrl.woff") format("woff"); }

@font-face {
  font-family: 'MuseoSansCyrl-Medium';
  font-style: normal;
  font-weight: normal;
  src: local("MuseoSansCyrl_1"), url("../fonts/MuseoSansCyrl_1.woff") format("woff"); }

@font-face {
  font-family: 'MuseoSansCyrl-Bold';
  font-style: normal;
  font-weight: normal;
  src: local("MuseoSansCyrl_3"), url("../fonts/MuseoSansCyrl_3.woff") format("woff"); }

@font-face {
  font-family: 'GenevaNormal';
  font-style: normal;
  font-weight: normal;
  src: local("GenevaNormal"), url("../fonts/GenevaNormal.woff") format("woff"); }

@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local("OpenSans-ExtraBold"), url("../fonts/OpenSans-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: normal;
  src: local("OpenSans-Bold"), url("../fonts/OpenSans-Bold.woff") format("woff"); }

@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: local("OpenSans-Regular"), url("../fonts/OpenSans-Regular.woff") format("woff"); }

@font-face {
  font-family: 'OpenSans-Light';
  font-style: normal;
  font-weight: normal;
  src: local("OpenSans-Light"), url("../fonts/OpenSans-Light.woff") format("woff"); }

.front-page {
  position: relative;
  padding-bottom: 15px;
  z-index: 0;
  background-color: #ffffff; }

.services-header {
  padding-bottom: 0; }

.portfolio-top {
  padding: 40px 0; }

.front-block {
  position: absolute;
  background: url(../img/main-bg.jpg) 0 -300px #3852d5 no-repeat;
  background-size: 100%;
  height: 344px;
  width: 100%;
  transition: 0.5s;
  z-index: -1; }

.bg-small .front-block {
  position: absolute;
  background: url(../img/main-bg.jpg) 0 -300px #3852d5;
  background-size: 100%;
  width: 100%;
  height: 100%;
  transition: 0.5s; }

.services-bg {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 344px; }

.serice-image img {
  height: 344px; }

.header-nav {
  padding-top: 65px;
  margin: 0;
  width: 100%; }

.menu {
  clear: both; }

.portfolio-items {
  margin-top: 45px; }

.header-nav .menu {
  padding: 0;
  margin: 0; }

.header-nav .menu-item {
  list-style: none;
  transition: all 0.5s; }

.header-nav .menu-item a {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  float: left;
  width: 236px;
  max-width: 100%;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 7px 0;
  position: relative; }

.header-nav .menu-item a:before {
  position: absolute;
  content: '';
  background: #fff;
  height: 3px;
  width: 100%;
  bottom: -2px;
  left: 0;
  -webkit-transition: all .5s;
  transition: all .5s; }

.header-nav .menu-item:nth-of-type(3) a {
  margin-left: 17%; }

.header-logo {
  position: absolute;
  left: 50%;
  margin: -8px 0 0 -72px; }

.header-nav .current-menu-item a {
  background: #ffffff;
  color: #3852d5; }

.header-nav .current-menu-item a:before {
  background: #3852d5; }

.header-nav .sub-menu {
  display: none;
  clear: both;
  width: 242px;
  max-width: 100%;
  padding: 0;
  position: absolute;
  z-index: 3;
  margin-top: 38px;
  transition: all 0.5s; }

.header-nav .sub-menu li a {
  background-color: #ffffff;
  border-bottom: solid 1px #3852d5;
  color: #3852d5;
  font-size: 16px;
  text-transform: none; }

.header-nav .sub-menu li a:before {
  display: none; }

.header-nav .sub-menu li:nth-of-type(3) a {
  margin-left: 0; }

.header-nav .sub-menu .current-menu-item a {
  background: #3852d5;
  color: #ffffff; }

.headhesive--stick .mid {
  padding: 0 15px; }

.lang-block {
  position: absolute;
  top: 0;
  right: 0; }

.lang-block ul {
  border: solid 2px #ffffff; }

.lang-block li {
  height: 23px;
  transition: 0.5s; }

.lang-block li:hover {
  background: rgba(255, 255, 255, 0.3);
  transition: 0.5s; }

.lang-block li a {
  font-family: 'OpenSans-Regular';
  font-size: 11px;
  font-weight: bold;
  color: white;
  text-transform: uppercase; }

.lang-block li.wpml-ls-current-language {
  background: white; }

.lang-block li.wpml-ls-current-language a {
  color: #3852d5; }

.wpml-ls-display {
  display: none; }

.lang-block .wpml-ls-legacy-list-horizontal {
  padding: 0; }

.services-blocks {
  padding-bottom: 50px; }

.services-title {
  margin-top: 30px; }

.small-title {
  margin-top: 30px; }

.services-title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase; }

.small-title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase; }

.services-undertitle {
  margin: 0 auto;
  width: 840px;
  max-width: 100%; }

.services-undertitle .undertitle {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  text-align: center; }

.undertitle-margin {
  margin-top: 25px; }

.main-tabs {
  position: relative; }

.services-tabs {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  z-index: 1;
  position: relative;
  border: 0 !important; }

.services-tabs .left-border {
  left: 4px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }

.services-tabs .right-border {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px; }

.services-list {
  width: 142px;
  max-width: 100%;
  min-height: 40px;
  background-color: #3852d5;
  border: solid 1px #ffffff;
  float: none !important;
  display: inline-block !important; }

.services-tabs .active {
  background-image: linear-gradient(331deg, #f5f7ff, #ffffff); }

.services-link {
  background-color: transparent !important;
  border: 0 !important;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff; }

.services-tabs .active .services-link {
  color: #3852d5; }

.services-container {
  width: 970px;
  max-width: 100%;
  margin: 15px auto 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.service-single {
  width: 274px;
  max-width: 100%;
  min-height: 320px;
  position: relative;
  display: inline-block;
  padding: 0;
  -webkit-transition: all .5s;
  transition: all .5s;
  margin: 15px 0 30px 0; }

.service-box {
  width: 274px;
  min-height: 320px;
  position: absolute;
  border: solid 2px rgba(0, 0, 0, 0.06);
  top: 0;
  -webkit-transition: all .5s;
  box-shadow: 0 5px 20px 6px rgba(0, 0, 0, 0.06);
  transition: all .5s;
  cursor: default; }

.service-single:hover .service-box {
  border: solid 2px #3852d5;
  -webkit-transition: all .5s;
  transition: all .5s; }

.service-single:nth-last-child(-n+3) {
  margin-bottom: 0; }

.service-top {
  position: relative; }

.service-top .service-title .title {
  position: absolute;
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000;
  top: 15px;
  left: 27px; }

.service-img {
  position: relative; }

.service-img img {
  min-height: 98px; }

.service-img:before {
  position: absolute;
  content: '';
  background-color: #000000;
  height: 2px;
  left: 50%;
  bottom: -1px;
  width: 0;
  transition: all 0.5s; }

.service-description {
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.49);
  min-height: 159px; }

.service-description ul {
  padding: 0 0 0 20px; }

.service-description p,
.service-description li {
  font-family: 'OpenSans-Regular';
  font-size: 13px;
  color: #9b9b9b; }

.service-bottom {
  background-color: #ffffff;
  padding: 10px 20px 10px 27px;
  text-align: right; }

.srv-btm-scnd {
  padding: 10px 20px 20px 27px; }

.service-time {
  display: none;
  width: 45%;
  position: relative;
  margin-top: 10px; }

.service-time .time {
  padding-left: 30px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  display: none; }

.service-time:before {
  position: absolute;
  content: '';
  background: url("../img/icon-time.svg") no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  -webkit-transition: all .5s;
  transition: all .5s;
  display: none; }

.service-btn {
  display: inline-block;
  width: 55%;
  vertical-align: top; }

.btn-more {
  padding: 10.5px 20px;
  border-radius: 7px;
  background-image: linear-gradient(343deg, #3d4dd4, #4b6cd6);
  box-shadow: 0 2px 4px 0 rgba(61, 76, 212, 0.44);
  font-family: 'RobotoMono-Regular';
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  border: 1px solid transparent;
  -webkit-transition: all .5s;
  transition: all .5s; }

#complex .service-single .service-img:before {
  left: 0;
  width: 80%;
  margin: 0 27px; }

.service-included {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  margin-top: 10px;
  position: relative; }

.service-included .included {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  text-transform: uppercase;
  padding-left: 30px; }

.service-included:before {
  position: absolute;
  content: '';
  background: url("../img/icon-yes.svg") no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  -webkit-transition: all .5s;
  transition: all .5s; }

.service-full {
  width: 100%;
  text-align: center;
  position: relative;
  padding: 10.5px 0;
  top: -18px; }

.service-full .btn-full {
  border-radius: 7px;
  background-image: linear-gradient(331deg, #61e365, #86e989);
  border: 1px solid #61e365;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  padding: 10.5px 25px;
  height: 40px;
  transition: 0.5s; }

.service-full .btn-full:hover {
  transition: 0.5s;
  text-decoration: none;
  background-image: linear-gradient(331deg, #61e365, #a2f7a4); }

.header-nav .menu-item:hover > .sub-menu {
  display: block;
  transition: all 0.5s; }

.header-nav .menu-item:last-child {
  margin-bottom: 0; }

.header-nav .menu-item a:hover {
  background: #bbc6ff;
  text-decoration: none;
  color: #3852d5; }

.header-nav .menu-item a:hover:before {
  background: #3852d5;
  -webkit-transition: all .5s;
  transition: all .5s; }

.services-link:hover {
  color: #ffffff; }

.service-single:hover {
  -webkit-box-shadow: 0px 22px 19px -13px #3852d5;
  -moz-box-shadow: 0px 22px 19px -13px #3852d5;
  box-shadow: 0px 22px 19px -13px #3852d5;
  cursor: pointer; }

.service-single:hover .service-box {
  border: solid 2px #3852d5;
  top: -5px;
  min-height: 325px;
  -webkit-transition: all .5s;
  transition: all .5s; }

.service-single:hover .service-img:before {
  left: 0;
  width: 80%;
  -webkit-transition: all .5s;
  transition: all .5s;
  margin: 0 27px; }

.service-single:hover .service-time:before {
  background: url("../img/icon-time_black.svg") no-repeat;
  -webkit-transition: all .5s;
  transition: all .5s; }

.btn-more:hover {
  color: #ffffff;
  opacity: 0.8;
  text-decoration: none;
  -webkit-transition: all .5s;
  transition: all .5s; }

.btn-more:focus {
  color: #ffffff;
  opacity: 0.8;
  text-decoration: none;
  -webkit-transition: all .5s;
  transition: all .5s; }

#complex .service-single:hover .service-img:before {
  left: 0;
  width: 100%;
  -webkit-transition: all .5s;
  transition: all .5s;
  margin: 0; }

.btn-full:hover {
  text-decoration: none; }

.main-tabs {
  position: relative; }

.service-left-top-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  top: -35px;
  right: 690px;
  transform-origin: 0 50%;
  animation: bracket-line-top 0.7s ease-out 0.7s;
  animation-fill-mode: both; }

.service-left-border {
  position: absolute;
  content: '';
  width: 3px;
  height: 0;
  background: #ffffff;
  margin-top: 0;
  top: -35px;
  left: 0;
  transform-origin: 0 50%;
  animation: bracket-line 1.7s ease-out 1.7s;
  animation-fill-mode: both; }

.service-left-bottom-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  bottom: 35px;
  right: 0;
  transform-origin: 0 50%;
  animation: bracket-line-bottom 2s ease-out 2s;
  animation-fill-mode: both; }

.service-right-border {
  position: absolute;
  height: 100%;
  width: 3px;
  background: #000;
  right: 0;
  top: -35px; }

.service-right-top-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  top: -35px;
  left: 690px;
  transform-origin: 0 50%;
  animation: bracket-line-top 0.7s ease-out 0.7s;
  animation-fill-mode: both; }

.service-right-border {
  position: absolute;
  content: '';
  width: 3px;
  height: 0;
  background: #ffffff;
  top: -35px;
  right: 0;
  transform-origin: 0 50%;
  animation: bracket-line 1.7s ease-out 1.7s;
  animation-fill-mode: both; }

.service-right-bottom-border {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #ffffff;
  bottom: 35px;
  left: 0;
  transform-origin: 0 50%;
  animation: bracket-line-bottom 2s ease-out 2s;
  animation-fill-mode: both; }

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

.show {
  animation-name: show;
  -webkit-animation-name: show;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/* hang burger */
.mobile-nav-wrap {
  display: none; }
  .mobile-nav-wrap .menu {
    background-color: #fff;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0; }
    .mobile-nav-wrap .menu li {
      width: 100%; }
      .mobile-nav-wrap .menu li a {
        color: #3852d5;
        display: block;
        padding: 15px 0;
        text-align: center;
        text-decoration: none;
        transition: all 0.3s ease-in-out; }

.hamburger-menu {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: -30px;
  padding: 0 25px;
  transition: all 0.3s ease-in-out; }
  .hamburger-menu:hover {
    cursor: pointer; }
  .hamburger-menu .menu-item {
    background: black;
    display: block;
    height: 3px;
    margin: 0 0 10px;
    transition: all 0.3s ease-in-out;
    width: 40px; }
  .hamburger-menu.open .menu-item {
    margin: 0 0 5px; }
    .hamburger-menu.open .menu-item:first-child {
      transform: rotate(45deg);
      transform-origin: 10px; }
    .hamburger-menu.open .menu-item:nth-child(2) {
      opacity: 0; }
    .hamburger-menu.open .menu-item:nth-child(3) {
      transform: rotate(-45deg);
      transform-origin: 8px; }

.guide-front {
  height: 85px !important; }
  .guide-front .container {
    height: 85px !important; }
  .guide-front__img img {
    width: 54px !important; }
  .guide-front__text {
    margin: 0 !important; }
  .guide-front__btn {
    margin: auto !important;
    padding-right: 138px !important; }
    .guide-front__btn:before {
      position: absolute;
      content: '';
      background: url("../img/img_arrow_quide_small.svg") no-repeat !important;
      background-size: cover;
      width: 74px !important;
      height: 27px !important;
      top: 46px !important;
      left: -20px !important;
      z-index: 1; }
  .guide-front__banner img {
    width: 153px !important;
    z-index: 1;
    position: relative; }

.portfolio-title .title {
  background: #eee;
  margin: 0;
  padding: 20px 0 0 0; }

.tab-pane {
  position: relative; }

.portfolio-tabs {
  text-align: center;
  width: 565px;
  max-width: 100%;
  margin: 25px auto 0 auto; }

.portfolio-tabs .nav-tabs {
  padding-left: 0;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.portfolio-tabs .nav-tabs li {
  list-style: none;
  float: left;
  border: solid 1px #ffffff; }

.portfolio-tabs .nav-tabs li:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }

.portfolio-tabs .nav-tabs li:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px; }

.portfolio-tabs .nav-tabs li a {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  border: 0 !important;
  background: 0;
  padding: 7px 26px; }

.portfolio-tabs .nav-tabs li a:hover {
  background-color: inherit; }

.portfolio-tabs .nav-tabs .active {
  background-image: linear-gradient(349deg, #f5f7ff, #ffffff); }

.portfolio-tabs .nav-tabs .active a {
  color: #3852d5; }

.portfolio-tabs .nav-tabs .active a:hover {
  border: 0;
  color: #3852d5; }

.portfolio-tabs .nav-tabs .active a:focus {
  border: 0;
  color: #3852d5;
  outline: 0; }

.portfolio-block .portfolio-items {
  margin-top: 0; }

.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.tabs label {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  display: block;
  padding: 1rem 2rem;
  margin-right: 0.2rem;
  cursor: pointer;
  background: #90CAF9;
  font-weight: bold;
  -webkit-transition: background ease 0.2s;
  transition: background ease 0.2s; }

.tabs .tab {
  -webkit-box-ordinal-group: 100;
  -ms-flex-order: 99;
  order: 99;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem;
  background: #fff; }

.tabs input[type="radio"] {
  display: none; }

.tabs input[type="radio"]:checked + label {
  background: #fff; }

.tabs input[type="radio"]:checked + label + .tab {
  display: block; }

@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    -webkit-box-ordinal-group: NaN;
    -ms-flex-order: initial;
    order: initial; }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem; } }

.portfolio-pane {
  background: #eee; }
  .portfolio-pane .call {
    margin-top: 30px; }

.portfolio-pane > .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.1s; }

.portfolio-pane > .tab-pane .slick-slide {
  height: 0; }

.portfolio-pane > .tab-pane .slick-dots {
  display: none; }

.portfolio-pane > .tab-pane #carousel {
  height: 0; }

.portfolio-pane .portfolio-photo {
  margin-top: 0; }

.portfolio-pane .portfolio-block {
  padding: 0; }

.portfolio-pane > .active {
  height: auto;
  padding: 100px 0 15px 0; }

.portfolio-pane > .active .slick-slide {
  height: auto; }

.portfolio-pane > .active .slick-dots {
  display: block; }

.portfolio-pane > .active #carousel {
  height: 600px; }

.responsive-logo .slick-track {
  min-width: 100%; }

.logo-page {
  padding: 18px 0 50px 0; }

.logo-block {
  outline: none;
  cursor: pointer;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; }

.logo-block img {
  width: 300px;
  display: inline-block;
  margin: 0 38px 20px;
  cursor: pointer; }

.logo-page .slick-dots {
  padding: 0;
  text-align: center;
  margin-top: 30px; }

.logo-page .slick-dots button {
  display: none; }

.logo-page .slick-dots li {
  display: inline-block;
  list-style: none;
  width: 11.3px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px; }

.logo-page .slick-dots .slick-active {
  background-color: #3852d5; }

.responsive-bag .slick-track {
  min-width: 100%; }

.bag-page {
  padding: 18px 0 50px 0; }

.bag-block {
  outline: none;
  cursor: pointer;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important; }

.bag-block img {
  width: 300px;
  display: inline-block;
  margin: 0 38px 20px;
  cursor: pointer; }

.bag-page .slick-dots {
  padding: 0;
  text-align: center;
  margin-top: 30px; }

.bag-page .slick-dots button {
  display: none; }

.bag-page .slick-dots li {
  display: inline-block;
  list-style: none;
  width: 11.3px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px; }

.bag-page .slick-dots .slick-active {
  background-color: #3852d5; }

.site-page {
  position: relative;
  padding: 10px 0 50px 0; }

#carousel {
  width: 800px;
  margin: 0 auto;
  height: 900px;
  positio: relative; }

#carousel img {
  display: hidden;
  cursor: pointer;
  width: 325px;
  height: auto !important; }

.swiper-site {
  margin-top: 0 !important;
  padding-bottom: 20px; }
  .swiper-site img {
    transition: 0.5s; }
    .swiper-site img:hover {
      transition: 0.5s;
      box-shadow: 0 7px 16px 0 #d2d2d2; }

.responsive-site .slick-track {
  min-width: 100%; }

.responsive-site {
  text-align: center; }

.site__slide {
  outline: 0; }

.site__mobile {
  outline: none;
  cursor: pointer; }

.site__mobile img {
  width: 300px;
  display: inline-block;
  margin: 0 38px 20px; }

.site__mobile .slick-dots {
  padding: 0;
  text-align: center;
  margin-top: 30px; }

.site__mobile .slick-dots button {
  display: none; }

.site__mobile .slick-dots li {
  display: inline-block;
  list-style: none;
  width: 11.3px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px; }

.site__mobile .slick-dots .slick-active {
  background-color: #3852d5; }

.swiper-slide img {
  cursor: pointer; }

.swiper-button-next {
  right: 13% !important;
  z-index: 1 !important; }

.swiper-button-prev {
  left: 13% !important;
  z-index: 1 !important; }

.guide-portfolio {
  height: 115px;
  background-image: linear-gradient(to bottom, #8ee015, #7cd00f); }
  .guide-portfolio .container {
    height: 115px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative; }
  .guide-portfolio__img {
    position: relative;
    top: -5px;
    margin-right: 23px; }
    .guide-portfolio__img img {
      width: 75px; }
  .guide-portfolio__text {
    margin-right: 47px; }
    .guide-portfolio__text h2 {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 40px;
      color: #fbe800;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
      text-transform: uppercase;
      margin: 0 0 5px 0; }
    .guide-portfolio__text p {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 20px;
      color: #ffffff;
      line-height: 1; }
  .guide-portfolio__btn {
    position: relative; }
    .guide-portfolio__btn a {
      display: block;
      width: 210.9px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      border-radius: 7px;
      background-image: linear-gradient(331deg, #3d4dd4, #4b6cd6);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      transition: 0.5s;
      text-align: center; }
      .guide-portfolio__btn a:hover {
        transition: 0.5s;
        opacity: 0.8;
        color: #fff;
        text-decoration: none; }
    .guide-portfolio__btn:before {
      position: absolute;
      content: '';
      background: url("../img/img_arrow_quide.svg") no-repeat;
      background-size: cover;
      width: 137px;
      height: 49px;
      top: 48px;
      left: -50px;
      z-index: 1; }
  .guide-portfolio__banner {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0; }
    .guide-portfolio__banner img {
      width: 253px; }

.breadcrumbs {
  display: flex;
  justify-content: center;
  margin: 25px 0 0 0;
  text-align: center; }

.breadcrumbs a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  position: relative; }

.breadcrumbs span {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  margin: 0 2px; }

.breadcrumbs a:before {
  position: absolute;
  content: '一>';
  right: -12px;
  top: -2px; }

.breadcrumbs a:last-child:before {
  display: none; }

.service-info {
  background-color: #fff; }

.service-info .no-padding {
  padding: 18px 40px 57px 40px;
  border-bottom: 2px solid #f3f3f3; }

.info-title {
  text-align: center;
  margin-bottom: 31px; }

.info-left {
  width: 720px;
  max-width: 100%;
  display: inline-block; }

.consist-block {
  border-right: 3px solid #3852d5;
  padding-right: 50px; }

.consist-item {
  padding-bottom: 28px;
  position: relative; }

.consist-item:after {
  position: absolute;
  content: '';
  background: url("../img/checkmark-green.svg") #ffffff no-repeat;
  background-size: cover;
  left: 0;
  top: 0;
  color: #ffffff;
  width: 20px;
  height: 20px; }

.consist-item:before {
  position: absolute;
  content: '';
  left: 8px;
  top: 20px;
  background-color: #86e989;
  width: 3px;
  height: 100%; }

.consist-item:last-child:before {
  display: none; }

.consist {
  padding-left: 40px; }

.consist .title {
  font-family: 'OpenSans-Regular';
  font-size: 20px;
  color: #000000;
  margin: 0 0 10px 0; }

.consist p {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 0; }

.consist-who {
  padding-left: 40px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

.consist-who b,
.consist-who strong {
  font-family: 'OpenSans-Regular';
  font-size: 20px;
  color: #000000;
  margin: 0 0 10px 0;
  font-weight: 500; }

.consist li {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 0; }

.info-right {
  display: inline-block;
  vertical-align: top;
  padding-left: 50px;
  width: 330px;
  max-width: 100%; }

.banner_catalog {
  width: 270px;
  max-width: 100%;
  background-image: linear-gradient(163deg, #b4ed50, #79bf38 29%, #b1ea4f 67%, #90d041);
  padding-bottom: 39px;
  margin-top: 20px;
  position: relative; }
  .banner_catalog:before {
    position: absolute;
    content: '';
    background: url("../img/icon_catalog_arrow.svg") no-repeat;
    background-size: cover;
    bottom: 91px;
    right: -17px;
    width: 45px;
    height: 109.8px;
    z-index: 1; }
  .banner_catalog__text {
    margin-bottom: 30px; }
    .banner_catalog__text p {
      font-family: 'MuseoSansCyrl-Medium';
      font-size: 15px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 0;
      line-height: 1; }
      .banner_catalog__text p b {
        font-family: 'MuseoSansCyrl-Bold'; }
  .banner_catalog__btn {
    width: 212px; }
    .banner_catalog__btn a {
      width: 100%;
      margin: 0 auto;
      display: block;
      border-radius: 7px;
      background-image: linear-gradient(169deg, #f5515f, #9f031b);
      box-shadow: 0 2px 4px 0 rgba(56, 82, 213, 0.42), inset 0 -1px 0 0 rgba(255, 255, 255, 0.18);
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: white;
      transition: 0.5s; }
      .banner_catalog__btn a:hover {
        color: white;
        opacity: 0.8;
        transition: 0.5s;
        text-decoration: none; }
  .banner_catalog__img p {
    position: relative;
    top: -50px;
    right: -55px;
    transform: rotate(-16deg);
    opacity: 0.25;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 22px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase; }

.catalog_top {
  display: flex;
  margin-bottom: 27px; }
  .catalog_top__line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 9px;
    width: 198.8px;
    height: 33px;
    background-image: linear-gradient(176deg, #ee342e, #9f031b);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    position: relative;
    left: -15px;
    top: 46px; }
    .catalog_top__line:before {
      position: absolute;
      content: '';
      right: 0;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid #8cce3e;
      border-bottom: 15px solid transparent; }
  .catalog_top__img {
    width: 64px; }

.catalog_title {
  margin-bottom: 5px; }
  .catalog_title .title {
    height: 36px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    text-align: center;
    color: #f8e81c;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    margin: 0 0 3px 0;
    text-transform: uppercase;
    line-height: 1; }
  .catalog_title p {
    font-family: 'MuseoSansCyrl-Medium';
    font-size: 19px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
    margin-bottom: 0;
    line-height: 1; }
    .catalog_title p b {
      font-family: 'MuseoSansCyrl-Bold'; }

.guide {
  top: 50px;
  position: fixed;
  display: none;
  z-index: 3; }
  .guide_over {
    width: 627px;
    max-width: 100%;
    margin: 0 auto; }
    .guide_over .active {
      display: block; }

.guide_form {
  width: 427.6px;
  background-image: linear-gradient(157deg, #b4ed50, #79bf38 31%, #b1ea4f 73%, #90d041);
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.07);
  padding-bottom: 25px;
  margin: 0 auto; }
  .guide_form__wrapper {
    padding: 20px 40px 0 40px; }
    .guide_form__wrapper label,
    .guide_form__wrapper p {
      font-family: 'OpenSans-Light';
      font-size: 10px;
      text-align: left;
      color: #ffffff;
      margin-bottom: 8px; }
    .guide_form__wrapper input {
      height: 22px;
      border: 0;
      outline: 0;
      font-family: 'OpenSans-Light';
      font-size: 12px;
      color: rgba(155, 155, 155, 0.71);
      padding-left: 10px;
      margin-bottom: 4px;
      box-sizing: border-box; }
      .guide_form__wrapper input:focus {
        padding-left: 10px; }
      .guide_form__wrapper input::placeholder {
        font-family: 'OpenSans-Light';
        font-size: 12px;
        color: rgba(155, 155, 155, 0.71); }
    .guide_form__wrapper span {
      width: 20px; }

.guide_item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start; }
  .guide_item img {
    margin-right: 11px; }
  .guide_item__third {
    padding-left: 30px;
    margin-top: 22px; }
    .guide_item__third span {
      margin-right: 5px; }
  .guide_item .wpcf7-form-control-wrap {
    position: relative; }
  .guide_item .wpcf7-submit {
    display: block;
    width: 212px;
    height: 40px;
    line-height: 38px;
    border-radius: 7px;
    background-image: linear-gradient(169deg, #f5515f, #9f031b);
    box-shadow: 0 2px 4px 0 rgba(56, 82, 213, 0.33), inset 0 -1px 0 0 rgba(0, 0, 0, 0.18);
    font-family: 'OpenSans-Bold';
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    margin: 22px auto 0 auto;
    transition: 0.5s;
    cursor: pointer; }
    .guide_item .wpcf7-submit:hover {
      transition: 0.5s;
      opacity: 0.8; }
  .guide_item input[disabled="disabled"] {
    opacity: 0.4; }
    .guide_item input[disabled="disabled"]:hover {
      opacity: 0.4; }
  .guide_item span.wpcf7-not-valid-tip {
    position: absolute;
    width: 100%;
    left: 0;
    top: 19px; }
  .guide_item .ajax-loader {
    position: relative;
    right: 50px;
    top: -10px; }

.guide_top {
  background-image: linear-gradient(333deg, #3d4dd4, #4b6cd6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding-left: 22px; }
  .guide_top__text p {
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 16px;
    color: #ffffff;
    width: 262px;
    max-width: 100%;
    margin: 0; }
  .guide_top img {
    width: 140px;
    position: absolute;
    top: -40px;
    right: 15px; }

.why-item {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000;
  width: 270px;
  max-width: 100%;
  border: solid 1px #979797;
  position: relative;
  margin-bottom: 34px;
  padding: 20px 20px 25px;
  line-height: 34px;
  word-wrap: break-word; }

.why-item:last-child {
  margin-bottom: 0; }

.why-item strong {
  color: #3852d5; }

.why-item:before {
  position: absolute;
  content: '';
  background-color: #3852d5;
  height: 14px;
  width: 100%;
  bottom: 0;
  left: 0; }

.complex-arrow {
  margin-bottom: 20px; }

.package-title {
  text-align: center;
  padding: 20px 10px; }

.package-title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0; }

.price-box p {
  padding: 0 15px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

.price-block {
  padding: 18px 0 20px 0;
  background-color: #ffffff; }

.price-title {
  text-align: center;
  margin-bottom: 30px; }

.price-package {
  width: 317px;
  max-width: 100%;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.06);
  border: 2px solid rgba(0, 0, 0, 0.06);
  position: relative;
  top: 0;
  display: inline-block;
  margin: 0 30px 20px 30px;
  vertical-align: top;
  transition: 0.5s; }

.price-box {
  position: relative;
  -webkit-box-shadow: 0 22px 19px -13px rgba(0, 0, 0, 0.1);
  box-shadow: 0 22px 19px -13px rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  min-height: 475px; }

.price-box ul li {
  font-family: 'OpenSans-Regular';
  font-size: 13px;
  color: #9b9b9b; }

.package-check {
  margin-top: 24px;
  padding-left: 25px;
  width: 100%;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

.package-check input {
  margin: 0 10px 0 0; }

.package-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px; }

.package-price {
  margin-top: 48px;
  text-align: center; }

.package-price .price {
  margin: 0;
  font-family: 'OpenSans-Regular';
  font-size: 43px;
  color: #d8d8d8; }

.package-price .price span {
  font-family: 'OpenSans-Regular';
  font-size: 19px;
  color: #d8d8d8;
  vertical-align: top;
  position: relative;
  top: 10px;
  left: -5px; }

.package-btn {
  text-align: center;
  margin-top: 10px;
  line-height: 40px; }

.package-btn .order-btn {
  border-radius: 7px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  padding: 0 30px 0 30px !important;
  line-height: 40px;
  border: 0;
  transition: 0.5s; }

.service-modal_additional {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  padding: 25px 15px;
  text-align: center; }

.service-left_form {
  display: inline-block;
  width: 286px;
  max-width: 100%;
  vertical-align: top;
  margin: 0 20px 0 50px; }

.service-form_right {
  display: inline-block;
  width: 286px;
  max-width: 100%;
  vertical-align: top;
  margin: 0 0 0 20px; }

.service-form_right .no_margin {
  margin-bottom: 0; }

.input_block {
  margin-bottom: 19px; }

.choosen_service {
  font-family: 'OpenSans-Bold';
  font-size: 14px;
  color: #000000; }

.service-form_right .input_block label {
  margin-bottom: 8px; }

.input_block label {
  display: block;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  font-weight: normal;
  margin-bottom: 4px; }

.service-form_right .choosen_service {
  margin-bottom: 20px; }

.input_block input {
  background-color: #ffffff;
  border: solid 1px #f1f1f1;
  display: block;
  width: 100%;
  outline: 0;
  height: 27px;
  padding-left: 10px; }

.input_block textarea {
  width: 100%;
  height: 95px;
  background-color: #ffffff;
  border: solid 1px #f1f1f1;
  display: block;
  outline: 0;
  padding-left: 10px; }

.input_submit {
  text-align: center;
  margin-top: 20px; }

.input_submit input {
  border-radius: 7px !important;
  background-image: linear-gradient(343deg, #61e365, #86e989);
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  border: 0;
  min-width: 113px;
  height: 40px;
  outline: 0; }

.input_submit input:hover {
  background-image: linear-gradient(343deg, #7ae87e, #9be89e); }

.input_submit input:focus {
  background-image: linear-gradient(343deg, #7ae87e, #9be89e); }

.sale-text {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  text-align: center; }

.package-sale .sale {
  width: 280px;
  margin: 20px auto 0 auto;
  max-width: 100%;
  background-color: #f6a623;
  font-family: 'RobotoMono-Light';
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 4px;
  line-height: 40px;
  text-align: center; }

.and-block {
  width: 100%;
  text-align: center;
  margin: 5px 0;
  font-family: 'RobotoMono-Medium';
  font-size: 14px;
  color: #9b9b9b;
  display: block; }

.portfolio-block {
  padding: 18px 0 50px 0;
  background-color: #eeeeee; }

.portfolio-title {
  text-align: center; }

.img-wrap img {
  width: 100%;
  opacity: 1;
  transition: opacity .6s; }

.img-wrap {
  float: left;
  position: relative;
  line-height: 0;
  margin: 0 15px 15px;
  cursor: pointer;
  width: 158px !important;
  height: 158px !important;
  outline: 0; }

.img-box {
  width: 158px;
  height: 158px;
  background-size: cover; }

.portfolio-items a:focus {
  outline: 0; }

.portfolio-items img {
  background: url("https://s3-eu-west-1.amazonaws.com/media.lpgenerator.ru/uploads/2016/03/09/4.gif") 50% no-repeat; }

.img-wrap:before {
  content: '';
  position: absolute;
  top: .3em;
  left: .3em;
  right: .3em;
  bottom: .3em;
  transition: opacity 2s; }

.img-wrap:hover:before {
  opacity: 0; }

.portfolio-photo {
  margin: 31px 0 0 0; }

.portfolio-navigate {
  line-height: 40px;
  margin: 0 auto;
  padding: 0;
  width: 695px;
  max-width: 100%;
  display: none; }

.portfolio-items {
  outline: 0; }

.portfolio-items {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; }

.portfolio-items img {
  width: 158px;
  height: 158px; }

.portfolio-menu nav ul li {
  float: left;
  list-style: none; }

.portfolio-menu nav ul li a {
  padding: 11px 21px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #3852d5;
  transition: color .6s, background-color .2s;
  border-radius: 0;
  background-image: linear-gradient(352deg, #ffffff, #ffffff);
  border: solid 1px #f1f1f1; }

.portfolio-menu nav ul li:first-child a {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }

.portfolio-menu nav ul li:last-child a {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px; }

.portfolio-menu nav ul li a:hover {
  text-decoration: none;
  color: #3852d5; }

.portfolio-menu .active a {
  border-radius: 0;
  background-image: linear-gradient(299deg, #3d4dd4, #4b6cd6);
  border: solid 1px #f1f1f1;
  color: #ffffff;
  text-decoration: none; }

.portfolio-menu .active a:hover {
  color: #ffffff; }

.portfolio-slider {
  outline: none; }

.portfolio-slider .slick-dots {
  padding: 0;
  text-align: center;
  margin-top: 30px; }

.portfolio-slider .slick-dots button {
  display: none; }

.portfolio-slider .slick-dots li {
  display: inline-block;
  list-style: none;
  width: 11.3px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px; }

.portfolio-slider .slick-dots .slick-active {
  background-color: #3852d5; }

.over_blue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(56, 82, 213, 0.95); }

.over_blue_active {
  opacity: 1; }

.reviews-block {
  padding: 18px 0 50px 0;
  background-color: #ffffff;
  position: relative; }

.review-title {
  text-align: center; }

.review-box {
  max-width: 100%;
  min-height: 330px;
  cursor: pointer;
  transition: 0.5s; }
  .review-box:hover {
    transition: 0.5s; }
    .review-box:hover .rev-wrapper {
      background-image: linear-gradient(352deg, #3d4dd4, #4b6cd6);
      transition: 0.5s; }
      .review-box:hover .rev-wrapper:before {
        background: #fff;
        transition: 0.5s; }
    .review-box:hover .rev-text p {
      color: #fff;
      transition: 0.5s; }
    .review-box:hover .more-fb a {
      color: #fff;
      transition: 0.5s; }
      .review-box:hover .more-fb a i {
        color: #fff;
        transition: 0.5s; }

.rev-box-top {
  padding: 20px 30px 15px;
  background-color: #fff !important; }

.rev-box-avatar {
  display: inline-block; }

.rev-box-avatar img {
  width: 75px;
  height: 75px; }

.rev-box-info {
  display: inline-block;
  vertical-align: top;
  margin-left: 23px; }

.rev-box-name {
  font-family: 'OpenSans-Bold';
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #3852d5; }

.rev-box-stars p {
  display: flex;
  color: #ffd052;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 6px; }

.rev-box-date {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b; }

.rev-wrapper {
  transition: 0.5s;
  background-color: #f8f8f8;
  position: relative;
  min-height: 244px; }
  .rev-wrapper:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 11px;
    background: rgba(56, 82, 213, 0.95);
    bottom: 0;
    transition: 0.5s;
    left: 0; }

.rev-text {
  padding: 19px 12px 0 30px; }
  .rev-text p {
    min-height: 150px; }

.rev-text p {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  text-align: left;
  color: #000000; }

.rev-more {
  padding: 15px 10px 15px 30px;
  text-align: right; }

.rev-more .more-btn {
  display: inline-block;
  float: right;
  line-height: 40px; }

.more-btn a {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  border-radius: 7px;
  border: 0;
  color: #ffffff;
  padding: 13px 25px; }
  .more-btn a i {
    margin-left: 10px; }

.rev-more .more-fb {
  display: inline-block;
  vertical-align: top;
  float: right; }

.more-fb a {
  font-family: 'OpenSans-Bold';
  font-size: 12px;
  font-weight: 300;
  color: #3852d5;
  text-decoration: underline; }
  .more-fb a i {
    color: #3852d5;
    font-size: 35px;
    padding-top: 0;
    position: relative;
    top: 7px; }

.more-fb a:focus {
  outline: 0; }

.more-fb a i:focus {
  outline: 0; }

.responsive-rev {
  margin-top: 35px; }

.responsive-rev .slick-dots {
  padding: 0;
  text-align: center;
  margin-top: 30px; }

.responsive-rev .slick-dots button {
  display: none; }

.responsive-rev .slick-dots li {
  display: inline-block;
  list-style: none;
  width: 11.3px;
  height: 11px;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px; }

.responsive-rev .slick-dots .slick-active {
  background-color: #3852d5; }

.order_fulls {
  width: 758px;
  z-index: 2;
  padding-bottom: 20px;
  left: calc(50% - 400px); }

.rev_fulls {
  width: 555px;
  z-index: 1;
  left: calc(50% - 300px); }
  .rev_fulls__close {
    color: #fff;
    opacity: 1;
    font-family: 'OpenSans-Bold';
    transition: all 0.1s;
    text-shadow: none;
    position: absolute;
    right: 15px;
    top: -4px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold; }

.order_fulls__close {
  color: #fff;
  opacity: 1;
  font-family: 'OpenSans-Bold';
  transition: all 0.1s;
  text-shadow: none;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold; }

.rev_fulls,
.order_fulls {
  display: none;
  position: fixed;
  top: 134px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.06);
  max-width: 100%;
  background-color: white;
  color: #333;
  margin: 0;
  border: solid 1px #3852d5;
  z-index: 3; }
  .rev_fulls .title,
  .rev_fulls h3,
  .order_fulls .title,
  .order_fulls h3 {
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    color: #ffffff;
    background-color: #3852d5;
    padding: 15px 0;
    text-align: center;
    margin: 0; }
  .rev_fulls p,
  .order_fulls p {
    padding: 5px 25px; }

.form_sent {
  z-index: 100;
  display: none;
  position: fixed;
  top: 134px;
  left: 0;
  width: 100%; }
  .form_sent__block {
    position: relative;
    width: 640px;
    margin: 0 auto;
    max-width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.07);
    z-index: 3;
    border: 2px solid #3852d5;
    padding: 68px 0 53px 0;
    box-sizing: border-box; }
  .form_sent .title {
    margin-bottom: 39px;
    font-family: 'MuseoSansCyrl-Bold';
    text-align: center;
    font-size: 30px;
    color: #000000; }
  .form_sent__wrapper {
    margin-bottom: 43px; }
  .form_sent__close {
    display: block;
    margin: 0 auto;
    width: 187px;
    border-radius: 7px;
    background-image: linear-gradient(338deg, #3d4dd4, #4c6cd5);
    box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.58);
    height: 48px;
    line-height: 48px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    transition: 0.5s;
    cursor: pointer; }
    .form_sent__close:hover {
      transition: 0.5s;
      color: white;
      opacity: 0.8; }

.sent_wrap__text {
  width: 400px;
  max-width: 100%;
  margin: 0 auto 50px auto; }
  .sent_wrap__text p {
    font-family: 'OpenSans-Regular';
    font-size: 20px;
    font-weight: 600;
    color: #000000; }

.guide_modal__close {
  top: -40px;
  right: 0;
  position: absolute;
  cursor: pointer; }

.breadcrumbs a:hover {
  color: #ffffff;
  text-decoration: none; }

.package-btn .order-btn:hover {
  text-decoration: none;
  background-image: linear-gradient(343deg, #80f183, #a3f5a6);
  transition: 0.5s; }

.package-btn .order-btn:focus {
  text-decoration: none;
  background-image: linear-gradient(343deg, #80f183, #a3f5a6);
  outline: 0;
  transition: 0.5s; }

.price-package:hover {
  border: 2px solid #3852d5;
  top: -5px;
  cursor: pointer; }

.price-package:hover .price-box {
  -webkit-box-shadow: 0 22px 19px -13px #3852d5;
  box-shadow: 0 22px 19px -13px #3852d5;
  transition: 0.5s; }

.price-box ul {
  overflow: auto;
  max-height: 250px;
  padding: 0 20px 0 40px; }

.more-btn a:hover {
  opacity: .8;
  text-decoration: none;
  color: #fff; }

.more-btn a:focus {
  opacity: .8;
  text-decoration: none;
  color: #fff;
  outline: 0; }

.more-fb a:hover i {
  color: #3852d5; }

body #bingc-passive {
  display: none !important; }

body #bingc-phone-button {
  left: -200% !important; }

body .bingc-blur-whole-page {
  filter: none !important; }

body .bingc-active {
  display: none !important; }

.call {
  background: url("../img/call_bg.svg") center no-repeat;
  background-size: cover;
  width: 750px;
  max-width: 100%;
  min-height: 131px;
  position: relative;
  padding-top: 10px;
  box-sizing: border-box;
  margin-top: 80px; }

.call_blue {
  background: url("../img/call_bg_blue.svg") center no-repeat !important; }
  .call_blue .call__info .title,
  .call_blue .call__info p {
    color: #fff; }
  .call_blue form .btn-blue {
    background-image: linear-gradient(155deg, #fbda61, #ffb521) !important; }

.call_home {
  background: url("../img/call_bg_home.svg") center no-repeat; }

.call_blue {
  background: url("../img/call_bg_blue.svg") center no-repeat; }

.call_home,
.call_blue {
  padding: 10px 40px 10px 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .call_home .call__img,
  .call_blue .call__img {
    left: 31px;
    top: -23px; }
    .call_home .call__img img,
    .call_blue .call__img img {
      width: 88px; }
  .call_home .call__info,
  .call_blue .call__info {
    padding-top: 10px;
    width: 660px;
    max-width: 100%; }
    .call_home .call__info .title,
    .call_blue .call__info .title {
      margin-bottom: 0; }
  .call_home form .btn-blue,
  .call_blue form .btn-blue {
    margin-left: 50px !important; }

.call__info {
  padding-left: 140px; }

.call_info .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 19px;
  font-weight: 900;
  color: #000000;
  margin: 0 0 10px 0; }

.call_info p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #000000; }

.call__img {
  position: absolute;
  left: 6px;
  top: -47px; }

.call__img img {
  width: 119px; }

.call form input {
  width: 200px;
  max-width: 100%;
  height: 27px;
  line-height: 22px;
  background-color: #ffffff;
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  color: #d8d8d8;
  outline: 0;
  transition: 0.5s;
  border: 1px solid #fff; }
  .call form input::placeholder {
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    color: #d8d8d8;
    transition: 0.5s; }
  .call form input:focus {
    border-color: red;
    transition: 0.5s; }

.call form label {
  font-family: 'OpenSans-Regular';
  font-size: 11px;
  color: #000000;
  margin-left: 9px; }

.call form .btn-blue {
  font-family: "OpenSans-Regular" !important;
  transition: 0.5s;
  display: inline-block;
  width: 164px;
  height: 40px;
  margin-left: 100px;
  border-radius: 7px;
  background-image: linear-gradient(337deg, #3d4dd4, #4b6cd6);
  box-shadow: 0 2px 4px 0 rgba(61, 77, 212, 0.41);
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #ffffff; }
  .call form .btn-blue:hover {
    opacity: .8;
    transition: 0.5s;
    text-decoration: none; }

.service-form form {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  border: 1px solid #3852d5;
  padding-bottom: 25px; }

.service-form form h3 {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #fff;
  background-color: #3852d5;
  margin: 0;
  text-align: center;
  padding: 20px 10px; }

.service-form form .input_submit {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.service-form form h5 {
  font-family: 'OpenSans-Regular';
  color: #d8d8d8;
  font-size: 43px;
  margin: -3px 15px 0 0; }

.listing_prices {
  background-color: #fff;
  padding: 20px 0; }
  .listing_prices__wrap {
    display: flex;
    justify-content: center; }

.prices_item {
  margin: 0 30px 30px 30px;
  width: 310px; }
  .prices_item__shadow {
    box-shadow: 0 13px 21px 0 rgba(0, 0, 0, 0.08);
    transition: 0.5s; }
    .prices_item__shadow:hover {
      transition: 0.5s;
      box-shadow: 0 36px 15px -23px #405cd8, 0 2px 21px 0 rgba(0, 0, 0, 0.09);
      background-color: #ffffff; }
      .prices_item__shadow:hover .prices_list:last-child .prices_services {
        border-color: #435dd8; }
      .prices_item__shadow:hover .prices_services:before, .prices_item__shadow:hover .prices_services:after,
      .prices_item__shadow:hover .prices_order:before,
      .prices_item__shadow:hover .prices_order:after {
        background-color: #435dd8; }
      .prices_item__shadow:hover .prices_order {
        border-color: #435dd8; }
  .prices_item__title .title {
    color: #435dd8;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 22px 0; }

.prices_accordion {
  padding: 0;
  border: 0; }

.prices_list:last-child .prices_services {
  border-bottom: solid 3px #fff; }

.prices_list__title {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #435dd8;
  padding: 0 48px 0 29px;
  width: 100%;
  cursor: pointer; }
  .prices_list__title:hover {
    text-decoration: none; }
  .prices_list__title .title {
    color: #fff;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 16px;
    font-weight: 900;
    margin: 0; }
  .prices_list__title i {
    font-size: 18px;
    font-weight: bold;
    transition: 0.5s; }

.prices_list .active i {
  transform: rotate(180deg);
  transition: 0.5s; }

.prices_title {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .prices_title__quantity {
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 25px;
    color: #fff; }

.prices_services {
  position: relative;
  padding: 20px 30px; }
  .prices_services:before, .prices_services:after {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 3px;
    height: 100%;
    top: 0;
    transition: 0.5s; }
  .prices_services:before {
    left: 0; }
  .prices_services:after {
    right: 0; }

.prices_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px; }
  .prices_block__title .title {
    color: #9b9b9b;
    margin: 0;
    font-family: 'OpenSans-Regular';
    font-size: 12px; }
  .prices_block__price {
    min-width: 50px;
    text-align: center;
    color: #9b9b9b;
    margin: 0;
    font-family: 'OpenSans-Regular';
    font-size: 12px; }
    .prices_block__price select {
      cursor: pointer;
      outline: 0;
      padding: 0 18px 0 4px;
      color: #9b9b9b;
      font-family: 'OpenSans-Regular';
      font-size: 12px;
      min-width: 38px;
      height: 17px;
      border: solid 1px #e8e8e8; }
    .prices_block__price label {
      position: relative; }
      .prices_block__price label:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: #000 transparent transparent transparent;
        right: 4px;
        top: 7px; }

.prices_order {
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 3px solid #fff;
  transition: 0.5s; }
  .prices_order:before, .prices_order:after {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 3px;
    height: 100%;
    top: 0;
    transition: 0.5s; }
  .prices_order:before {
    left: 0; }
  .prices_order:after {
    right: 0; }
  .prices_order__title {
    color: #9b9b9b;
    font-family: 'OpenSans-Regular';
    font-size: 15px; }
  .prices_order__total {
    color: #73e676;
    font-family: 'OpenSans-Regular';
    font-size: 36px;
    margin-bottom: 25px; }
  .prices_order__btn {
    margin-bottom: 20px; }
    .prices_order__btn a {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      width: 151px;
      height: 40px;
      border-radius: 7px;
      background-image: linear-gradient(338deg, #3d4dd4, #4c6cd5);
      box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67);
      transition: 0.5s; }
      .prices_order__btn a:hover {
        transition: 0.5s;
        opacity: 0.8; }

.price_table {
  padding: 50px 0 200px 0;
  background-color: #fff; }
  .price_table__wrapper {
    display: flex;
    flex-direction: column;
    width: 950px;
    max-width: 100%;
    margin: 0 auto; }

.price_top__titles {
  display: flex;
  justify-content: space-between; }

.price_titles__name {
  min-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  color: #435dd8;
  text-transform: uppercase;
  width: 20%; }
  .price_titles__name:first-child {
    width: 40%; }

.price_item .price_item__row:last-child .price_row__title {
  border-bottom: 0; }

.price_item .price_item__row:last-child .price_row__include span {
  border-bottom: 0; }

.price_column {
  display: flex; }
  .price_column__block {
    position: relative;
    width: 100%;
    border-top: solid 1px #e8e8e8;
    height: 35px; }
    .price_column__block:hover {
      transition: 0.5s; }
      .price_column__block:hover .title {
        font-family: 'OpenSans-Bold';
        font-weight: bold; }
      .price_column__block:hover .price_column__text {
        display: block; }
  .price_column span {
    position: relative;
    display: inline-flex; }
  .price_column__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%; }
    .price_column__title .title {
      display: inline-flex;
      align-items: center;
      width: 100%;
      color: #0f0f0f;
      font-size: 16px;
      font-family: 'OpenSans-Regular';
      padding-left: 34px;
      margin: 0;
      height: 35px;
      transition: 0.5s; }
  .price_column__text {
    transition: 0.5s;
    display: none;
    position: absolute;
    bottom: 25px;
    right: -50px; }
    .price_column__text p {
      width: 235px;
      min-height: 88px;
      background-color: #ffde62;
      box-shadow: 0 26px 15px -25px rgba(255, 222, 98, 0.59);
      border-radius: 7px;
      padding: 11px 15px;
      font-family: 'OpenSans-Light';
      font-size: 12px;
      color: #000;
      position: relative;
      z-index: 1; }
      .price_column__text p:before {
        position: absolute;
        content: '';
        bottom: -10px;
        right: 30px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #ffde62; }
  .price_column__include {
    position: relative;
    width: 20%;
    color: #0f0f0f;
    font-size: 20px;
    font-family: 'OpenSans-Regular';
    transition: 0.5s; }
    .price_column__include:hover {
      transition: 0.5s;
      border-color: #3852d5;
      border-width: 3px; }
    .price_column__include span {
      min-height: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid 1px #e8e8e8;
      border-right: 0;
      border-bottom: 0; }
    .price_column__include:last-child span {
      border-right: solid 1px #e8e8e8; }

.bg_blue {
  display: flex;
  align-items: center;
  border: 0 !important;
  background-color: #435dd8; }
  .bg_blue .title {
    font-family: 'MuseoSansCyrl-Bold' !important;
    font-size: 16px;
    color: #fff !important;
    line-height: 2.5; }

.service_padding {
  padding-left: 30px !important; }

.no_border {
  border: 0 !important; }

.no_border_top {
  border-top: 0 !important; }

.no_border_bottom {
  border-bottom: 0 !important; }

.service_price {
  display: flex;
  flex-direction: column;
  padding: 18px 20px 15px 20px;
  border-bottom: 1px solid #e8e8e8 !important; }
  .service_price p {
    text-align: center;
    font-size: 12px;
    font-family: 'OpenSans-Light';
    margin: 0; }
  .service_price span {
    margin-top: 23px;
    font-family: 'OpenSans-Regular';
    font-size: 36px;
    color: #73e676;
    border: 0 !important; }

.price_hover {
  transition: 0.5s; }
  .price_hover a {
    color: #0f0f0f;
    text-decoration: none; }
  .price_hover span {
    transition: 0.5s; }
  .price_hover:hover {
    transition: 0.5s; }
    .price_hover:hover a {
      text-decoration: none; }
    .price_hover:hover span {
      border-right: 3px solid #435dd8;
      border-left: 3px solid #435dd8; }
      .price_hover:hover span:first-child {
        border-top: 3px solid #435dd8; }
    .price_hover:hover .service_btn {
      border: 0 !important;
      background-image: linear-gradient(338deg, #3d4dd4, #4c6cd5);
      box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67); }
      .price_hover:hover .service_btn span {
        color: #fff;
        border: 0 !important;
        text-decoration: none; }
    .price_hover:hover .service_price {
      border-bottom: 3px solid #435dd8 !important;
      box-shadow: 0 45px 27px -34px #3852d5; }

.service_btn {
  position: absolute;
  left: 0;
  bottom: -76px;
  border: 0 !important;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 40px;
  border-radius: 7px;
  margin: 0 auto;
  font-size: 14px;
  font-family: 'OpenSans-Regular';
  color: #fff;
  background-image: linear-gradient(338deg, rgba(61, 77, 212, 0.55), rgba(76, 108, 213, 0.56)); }
  .service_btn span {
    border: 0 !important; }

.personal {
  padding: 0 15px;
  background-color: #fff; }
  .personal .container {
    border: solid 3px #3852d5;
    padding: 34px 0 0 0; }
  .personal__list {
    padding: 0 15px;
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 20px; }
    .personal__list li {
      font-size: 16px;
      font-family: 'OpenSans-Regular';
      font-weight: bold;
      color: #000;
      list-style: disc; }
  .personal__title {
    padding: 0 15px;
    text-align: center;
    margin-bottom: 17px; }
    .personal__title .title {
      font-size: 20px;
      font-family: 'OpenSans-Bold';
      color: #3852d5;
      text-transform: uppercase; }
  .personal__undertitle {
    padding: 0 15px;
    text-align: center;
    margin-bottom: 18px; }
    .personal__undertitle p {
      font-size: 12px;
      font-family: 'OpenSans-Light'; }
  .personal__order {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75px;
    background-image: linear-gradient(305deg, #3d4dd4, #4c6cd5);
    padding: 0 15px; }

.personal_order__title {
  font-family: 'OpenSans-Bold';
  font-size: 20px;
  color: #fff;
  margin-right: 30px; }

.personal_order__btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 40px;
  border-radius: 7px;
  font-size: 14px;
  font-family: 'OpenSans-Regular';
  color: #fff;
  background-image: linear-gradient(338deg, #61e365, #86e989);
  transition: 0.5s; }
  .personal_order__btn a:hover {
    color: #fff;
    transition: 0.5s;
    opacity: 0.7;
    text-decoration: none; }

.popup-widget__form table {
  display: flex;
  flex-direction: column; }
  .popup-widget__form table thead tr td {
    width: 33.3%; }

.srvs_top {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat; }
  .srvs_top .breadcrumbs {
    margin: 5px 0 40px 0; }
  .srvs_top__wrapper {
    display: flex;
    justify-content: space-between; }
  .srvs_top__title {
    margin-bottom: 12px; }
    .srvs_top__title .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff; }
  .srvs_top__text {
    margin-bottom: 28px; }
    .srvs_top__text p {
      font-family: 'OpenSans-Light';
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff; }
  .srvs_top__btn a {
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 238px;
    max-width: 100%;
    height: 40px;
    border-radius: 7px;
    background-image: linear-gradient(328deg, #3d4dd4, #4c6cd5);
    box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67);
    transition: 0.5s; }
    .srvs_top__btn a:hover, .srvs_top__btn a:focus {
      background-image: linear-gradient(336deg, #2734a0, #465eac);
      box-shadow: 0 15px 10px -10px rgba(8, 15, 52, 0.67);
      transition: 0.5s;
      text-decoration: none;
      color: #fff; }

.srvs_top_item {
  display: flex;
  align-items: center;
  margin-right: 20px; }
  .srvs_top_item:last-child {
    margin-right: 0; }
  .srvs_top_item__img {
    width: 55px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px; }
    .srvs_top_item__img img {
      z-index: 1; }
    .srvs_top_item__img:before {
      position: absolute;
      content: '';
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-image: linear-gradient(153deg, #fad961, #f76b1c);
      box-shadow: 0 15px 10px -10px #3e50d4; }
  .srvs_top_item__title .title {
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff; }

.srvs_top_left {
  width: 445px;
  max-width: 100%;
  margin: 137px 50px 0 0; }
  .srvs_top_left__list {
    display: flex;
    margin-bottom: 25px; }

.srvs_top_right {
  width: 863px;
  max-width: 100%;
  background-size: cover;
  height: 734px; }
  .srvs_top_right .swiper_monitor {
    width: 92%;
    margin: 36px auto 0 auto; }
    .srvs_top_right .swiper_monitor .swiper-slide {
      height: 450px;
      background-size: cover; }

.srvs_slider {
  width: 760px;
  position: relative; }
  .srvs_slider:before {
    position: absolute;
    content: '';
    width: 727px;
    height: 724px;
    background: url("../img/br_slider_pseudo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  .srvs_slider .swiper-slide {
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center; }

.benefits {
  padding-bottom: 50px;
  background: #fff; }
  .benefits__title {
    margin-bottom: 74px;
    text-align: center; }
    .benefits__title .title {
      font-size: 45px;
      margin: 0; }
  .benefits_list {
    display: flex;
    justify-content: space-between;
    width: 900px;
    max-width: 100%;
    margin: 0 auto; }

.benefits_item__icon {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #4b6bd5, #3d4dd4);
  box-shadow: 0 15px 10px -10px #3e50d4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px auto; }
  .benefits_item__icon img {
    z-index: 1; }

.benefits_item__title {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 186px;
  max-width: 100%;
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 25px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center; }

.benefits_item__text {
  width: 225px;
  max-width: 100%; }
  .benefits_item__text p {
    font-family: 'OpenSans-Light';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8e8e8e; }

.howtodo {
  background: #fff;
  padding: 55px 0;
  background: #fff; }
  .howtodo__title {
    margin-bottom: 65px;
    text-align: center; }
    .howtodo__title .title {
      font-size: 45px;
      margin: 0; }
  .howtodo__wrapper {
    display: flex;
    position: relative;
    justify-content: center; }
    .howtodo__wrapper:before {
      position: absolute;
      content: '';
      background: url("../img/how_line.svg");
      background-size: contain;
      height: 134px;
      width: 945px;
      top: 200px;
      left: 111px; }

.page-id-3192 .howtodo__wrapper {
  width: 780px;
  max-width: 100%;
  margin: 0 auto; }
  .page-id-3192 .howtodo__wrapper:before {
    width: 515px;
    left: 89px;
    background-repeat: no-repeat;
    background: url("../img/how_line_4.svg"); }

.page-id-3192 .howtodo_item {
  width: 174px;
  min-width: 174px; }
  .page-id-3192 .howtodo_item:nth-child(odd) {
    margin-top: 120px; }

.howtodo_item {
  width: 184px;
  min-width: 184px;
  max-width: 100%; }
  .howtodo_item:nth-child(odd) {
    margin-top: 130px; }
  .howtodo_item__title {
    min-height: 62px;
    margin-bottom: 14px; }
    .howtodo_item__title .title {
      color: #3852d5;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 25px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
  .howtodo_item__number {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 60px auto;
    width: 89px;
    height: 89px;
    border-radius: 50%;
    background-image: linear-gradient(350deg, #f4f4f4, #dedede);
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 45px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    position: relative; }
    .howtodo_item__number:before {
      position: absolute;
      content: '';
      bottom: -17px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 18px solid #f1f1f1; }
  .howtodo_item__text {
    max-width: 100%;
    padding: 0 30px; }
    .howtodo_item__text p {
      font-family: 'OpenSans-Light';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e; }

.howmuch {
  background: #fff;
  padding: 55px 0 110px 0; }
  .howmuch__title {
    margin-bottom: 65px;
    text-align: center; }
    .howmuch__title .title {
      font-size: 45px;
      margin: 0; }
  .howmuch__wrapper {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto; }

.howmuch_item {
  width: 300px;
  max-width: 100%;
  min-height: 450px;
  background-color: #ffffff;
  box-shadow: 0 14px 85px -31px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: 0.5s; }
  .howmuch_item:hover, .howmuch_item:focus {
    transition: 0.5s;
    color: #333;
    text-decoration: none;
    transform: scale(1.02); }
  .howmuch_item__title {
    height: 40px;
    background-color: #435dd8;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    .howmuch_item__title .title {
      margin: 0;
      text-align: center;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff; }
    .howmuch_item__title .number {
      margin: 0;
      position: absolute;
      top: -15px;
      right: 20px;
      width: 49px;
      height: 49px;
      background-color: #435dd8;
      border: solid 3px #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 26px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: normal;
      text-align: left;
      color: #fff; }
      .howmuch_item__title .number span {
        font-size: 9px; }
  .howmuch_item__text {
    min-height: 86px;
    padding: 20px 30px 0 30px;
    margin-bottom: 14px; }
    .howmuch_item__text ul {
      padding: 0;
      margin: 0; }
    .howmuch_item__text li {
      list-style: none;
      font-family: 'OpenSans-Regular';
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left; }
  .howmuch_item__preview {
    margin-bottom: 5px;
    text-align: center; }
    .howmuch_item__preview p {
      font-family: 'OpenSans-Bold';
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      color: #bcbcbc; }
  .howmuch_item__slider {
    margin-bottom: 40px;
    position: relative;
    min-height: 200px; }
    .howmuch_item__slider .swiper-slide {
      padding: 0 50px; }
    .howmuch_item__slider .swiper-button-prev {
      background-image: none !important;
      left: 5% !important; }
      .howmuch_item__slider .swiper-button-prev i {
        font-size: 18px;
        font-weight: bold; }
    .howmuch_item__slider .swiper-button-next {
      right: 5% !important;
      background-image: none !important;
      text-align: right; }
      .howmuch_item__slider .swiper-button-next i {
        font-size: 18px;
        font-weight: bold; }
  .howmuch_item__price {
    padding: 0 30px;
    height: 39px;
    background-color: #ffde62;
    font-family: 'OpenSans-Regular';
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    transition: 0.5s;
    text-decoration: none; }
    .howmuch_item__price:hover {
      transition: 0.5s;
      color: #435dd9;
      text-decoration: none; }

.howmuch_last {
  width: 300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .howmuch_last .howmuch_item {
    min-height: 175px; }

.howmuch_full {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 300px;
  max-width: 100%;
  min-height: 210px;
  background-color: #7ed321;
  box-shadow: 0 14px 85px -31px #7ed321, 0 24px 23px -17px #7ed321;
  padding: 20px 30px 8px 30px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: 0.5s; }
  .howmuch_full:hover, .howmuch_full:focus {
    transition: 0.5s;
    color: #333;
    text-decoration: none;
    transform: scale(1.02); }
  .howmuch_full__title {
    position: relative;
    z-index: 1; }
    .howmuch_full__title .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 22px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000; }
  .howmuch_full__percent {
    z-index: 0;
    position: absolute;
    right: 20px;
    top: 50px;
    width: 101px;
    height: 101px;
    background-color: #7ed321;
    border: solid 5px #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 41px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    transform: rotate(-12deg);
    color: #fff; }
  .howmuch_full__price {
    position: relative;
    z-index: 1;
    font-family: 'OpenSans-Regular';
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
    text-decoration: none; }
    .howmuch_full__price:hover {
      transition: 0.5s;
      color: #435dd9;
      text-decoration: none; }

.howmuch_price__old {
  width: 100%;
  text-align: right;
  font-size: 15px;
  text-decoration: line-through; }

.consultation {
  background-color: #fff;
  padding: 110px 0 80px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }
  .consultation__title {
    margin-bottom: 21px;
    text-align: center; }
    .consultation__title .title {
      font-size: 45px;
      margin: 0; }
  .consultation form {
    display: flex;
    flex-direction: column; }
    .consultation form input[type="tel"] {
      margin: 0 auto 28px auto;
      width: 333px;
      max-width: 100%;
      height: 40px;
      background-color: #ffffff;
      border: solid 1px #dfdfdf;
      font-family: 'OpenSans-Regular';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #b3b3b3;
      transition: 0.5s;
      outline: 0;
      padding-left: 15px; }
      .consultation form input[type="tel"]::placeholder {
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #d8d8d8;
        outline: 0;
        transition: 0.5s;
        border: 1px solid #fff;
        transition: 0.5s; }
      .consultation form input[type="tel"]:focus {
        border-color: red;
        transition: 0.5s;
        padding-left: 15px; }
  .consultation .btn-blue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 167px;
    max-width: 100%;
    margin: 0 auto;
    height: 40px;
    border-radius: 7px;
    background-image: linear-gradient(336deg, #3d4dd4, #4c6cd5);
    box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67);
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    transition: 0.5s; }
    .consultation .btn-blue:hover {
      background-image: linear-gradient(336deg, #2734a0, #465eac);
      box-shadow: 0 15px 10px -10px rgba(8, 15, 52, 0.67);
      transition: 0.5s;
      text-decoration: none; }

.youget {
  background: #fff;
  padding: 55px 0 110px 0; }
  .youget__title {
    margin-bottom: 65px;
    text-align: center; }
    .youget__title .title {
      font-size: 45px;
      margin: 0; }
  .youget__wrapper {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center; }

.youget_item {
  margin: 0 35px;
  width: 260px;
  max-width: 100%;
  position: relative; }
  .youget_item:first-child .youget_item__bg {
    position: absolute;
    width: 140%;
    height: 100%;
    left: -70%;
    top: -13%;
    z-index: 0; }
  .youget_item:nth-child(2) .youget_item__bg {
    position: absolute;
    width: 140%;
    height: 100%;
    left: -20%;
    top: 4%;
    z-index: 0; }
  .youget_item:nth-child(3) .youget_item__bg {
    position: absolute;
    width: 140%;
    height: 100%;
    right: -70%;
    top: -13%;
    z-index: 0; }
  .youget_item__icon {
    position: relative;
    z-index: 1;
    margin: 0 auto 26px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    background-image: linear-gradient(158deg, #fad961, #f76b1c);
    box-shadow: 0 15px 10px -10px #f5a623;
    border-radius: 50%; }
  .youget_item__title {
    position: relative;
    z-index: 1;
    margin-bottom: 14px; }
    .youget_item__title .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 25px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
  .youget_item__text {
    position: relative;
    z-index: 1; }
    .youget_item__text p {
      font-family: 'OpenSans-Light';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e; }

.portfolio {
  background: url("../img/bg_portfolio.jpg");
  padding: 85px 0 35px 0; }
  .portfolio .tab-pane {
    background-color: inherit; }
  .portfolio__title {
    margin-bottom: 65px;
    text-align: center; }
    .portfolio__title .title {
      font-size: 45px;
      margin: 0; }

.reviews_new {
  background: #fff;
  padding: 55px 0; }
  .reviews_new .reviews-block .title {
    font-size: 45px;
    margin-bottom: 65px; }

.white {
  height: 100px;
  background: #fff; }

.bonus__stat {
  padding-top: 40px;
  background-color: #fff; }

.stat__box .why-item {
  width: 320px;
  border: 0;
  min-height: 130px;
  padding: 0 0 10px 0; }

.stat__box .whywe-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.why-item:before {
  transition: 0.5s; }

.why-item:hover:before {
  transition: 0.5s;
  width: 50%; }

.stat__box .why-item {
  margin-bottom: auto; }

.conclusion {
  background-color: #ffffff; }

.conclusion-title {
  text-align: center; }

.conclusion-title .title {
  margin-bottom: 30px; }

.conclusion__about {
  margin: 0 12%; }

.conclusion__center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px; }

.conclusion__img img {
  width: 400px; }

.conclusion__text {
  font-family: 'OpenSans-Regular';
  font-size: 15px;
  text-align: left;
  color: #000000;
  width: 48%; }

.present {
  padding-bottom: 50px;
  background-color: #ffffff; }

.present .container {
  position: relative; }

.present-title {
  text-align: center; }

.present__text {
  font-family: 'OpenSans-Regular';
  font-size: 15px;
  text-align: left;
  color: #000000;
  width: 310px;
  margin: 0 auto; }

.scene {
  position: absolute;
  width: 100%; }

.scene__left {
  float: left;
  position: absolute;
  top: 25px;
  left: 5%; }

.scene__right {
  float: left;
  position: absolute;
  top: 25px;
  right: 5%; }

.scene__block img {
  margin: 0 17px; }

.scene__left img:nth-child(4), .scene__right img:nth-child(1) {
  width: 106px; }

.scene__left img:nth-child(3), .scene__right img:nth-child(2) {
  width: 39px;
  position: relative;
  top: 10px; }

.scene__left img:nth-child(2), .scene__right img:nth-child(3) {
  width: 29px;
  position: relative;
  top: 0; }

.scene__left img:nth-child(1), .scene__right img:nth-child(4) {
  width: 20px;
  position: relative;
  top: -10px; }

.bonus__form {
  padding-bottom: 75px;
  background-color: #ffffff; }

.bonus-info {
  background-color: #ffffff;
  padding-bottom: 50px; }

.bonus-info .consist-block {
  border: 0;
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0; }

.bonus-info .info-left {
  width: 100%; }

.bonus__form-box {
  border: 1px solid #3852d5;
  border-radius: 0;
  box-shadow: 0 22px 19px -13px rgba(0, 0, 0, 0.29);
  padding: 0 0 25px;
  -webkit-transition: 0s;
  transition: 0s;
  width: 758px;
  max-width: 100%;
  margin: 0 auto; }

body .bonus__form-box h3, body .bonus__form-box h4 {
  font-family: MuseoSansCyrl-Bold;
  font-size: 30px;
  color: #fff;
  background-color: #3852d5;
  margin: 0;
  text-align: center;
  padding: 15px 0; }

.contacts-box {
  width: 100%;
  position: absolute;
  margin: -50px 0 0 0;
  z-index: 2; }

.contacts-info {
  position: absolute;
  padding: 15px 35px;
  width: 605px;
  max-width: 100%;
  min-height: 623px;
  background-color: #ffffff;
  border: solid 1px #3852d5; }

.info-block p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b;
  display: block;
  margin-bottom: 0; }

.info-block a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b;
  display: block;
  margin-bottom: 0; }

.info-block a:hover {
  text-decoration: none;
  color: #3d4dd4; }

.info-block {
  margin-bottom: 10px; }

.info-main {
  border-bottom: solid 1px #f1f1f1;
  padding-bottom: 5px; }

.info-phone {
  position: relative; }

.info-block i {
  display: inline-block;
  vertical-align: middle; }

.info-box {
  display: inline-block;
  padding-left: 20px;
  vertical-align: middle; }

.contacts-form {
  margin-top: 15px; }

.input_block input:hover, .input_block input:focus {
  border: 1px solid #3d4dd4; }

.input_block textarea:hover, .input_block textarea:focus {
  border: 1px solid #3d4dd4; }

.contacts-form__title {
  text-align: center; }

.contacts-form__title .title-black {
  font-size: 20px;
  color: #9b9b9b;
  margin: 0 0 15px 0; }

.contacts-form .input_submit {
  text-align: left; }

.contacts-form .input_submit input {
  border-radius: 7px !important; }

.contacts-form .input_submit input:hover {
  opacity: .8;
  text-decoration: none;
  -webkit-transition: all .5s;
  transition: all .5s; }

#map {
  min-height: 680px;
  transition: all 0s;
  z-index: 1; }

#map div {
  transition: all 0s; }

.useful-container {
  position: relative;
  top: -100px; }

.useful-block {
  width: 100%;
  min-height: 464px;
  background-color: #ffffff;
  border: solid 1px #3852d5;
  padding: 36px 200px; }

.useful-block form {
  text-align: center; }

.useful-name {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 16px;
  color: #000000; }

.useful-text {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

.useful-checkbox {
  position: relative;
  margin-bottom: 30px;
  padding-left: 20px; }

.useful-checkbox:before {
  position: absolute;
  left: -40px;
  top: 20%;
  content: '';
  width: 27px;
  height: 27px;
  border: solid 3px #3852d5;
  border-radius: 50%; }

.useful-checkbox:after {
  position: absolute;
  left: -35px;
  top: 4%;
  content: '✔';
  font-family: 'MuseoSansCyrl-Bold';
  color: #1d1c1b;
  font-size: 30px; }

.useful-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  vertical-align: top; }

.useful-input input {
  box-sizing: border-box;
  font-size: 14px;
  font-family: "OpenSans-Regular"; }

.useful-block input:focus {
  padding-left: 10px; }

.useful-input label {
  margin-right: 10px;
  margin-bottom: 0; }

.useful-btn input {
  background-image: linear-gradient(336deg, #466bd4, #4b6cd6);
  transition: 0.5s;
  padding: 0 10px;
  box-sizing: border-box; }

.useful-input input::placeholder {
  font-size: 14px;
  font-family: "OpenSans-Regular"; }

.useful-btn input:hover {
  background-image: linear-gradient(336deg, #466bd4, #4b6cd6);
  opacity: 0.8;
  transition: 0.5s; }

.useful-btn input:focus {
  background-image: linear-gradient(336deg, #466bd4, #4b6cd6);
  opacity: 0.8;
  transition: 0.5s; }

.useful-ps {
  text-align: center;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  margin-top: 40px; }

.screen-reader-response {
  display: none; }

.useful-block form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

.useful-block form .input_submit {
  margin-top: 0; }

.footer-useful {
  position: absolute;
  width: 100%;
  bottom: 0; }

.footer {
  background-color: #000000;
  padding: 35px 0 40px; }

.footer-block {
  padding: 0; }
  .footer-block:first-child {
    padding-left: 0; }
  .footer-block:last-child {
    padding-right: 0; }

.footer-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap; }

.footer-form .form-title .title {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  width: 317px;
  max-width: 100%;
  margin: 0 0 10px 0; }

.footer-form .form-left {
  display: inline-block;
  margin-top: 6px;
  max-width: 100%; }

.footer-form .form-left input {
  width: 180px;
  max-width: 100%;
  min-height: 27px;
  background-color: #d8d8d8;
  font-family: 'OpenSans-Regular';
  border: 0;
  transition: all 0.5s;
  padding-left: 10px; }

.footer-form .form-right {
  display: inline-block;
  vertical-align: top;
  margin-left: 14px; }

.footer-form .form-right input {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  width: 137px !important;
  height: 40px;
  border-radius: 7px;
  background-image: linear-gradient(340deg, #3d4dd4, #4b6cd6);
  border: 0; }

.footer-form .form-right input:hover {
  opacity: 0.8; }

.footer-form .form-right input:focus {
  opacity: 0.8;
  outline: 0; }

.footer-contacts {
  margin-top: 15px; }

.footer-contacts p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #4a4a4a;
  margin-bottom: 0; }

.footer-contacts a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #4a4a4a; }

.footer-right .logo {
  text-align: right; }

.footer-right .logo img {
  width: 89px; }

.footer-about-text {
  margin-top: 20px; }

.footer-about-text p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  text-align: right;
  color: #4a4a4a; }

.copyright {
  margin-top: 15px; }

.copyright p {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  text-align: right;
  color: #4a4a4a; }

.footer-nav {
  display: inline-block;
  vertical-align: top; }

.footer-nav .big-nav {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0; }

.footer-nav .small-nav {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b;
  display: block; }

.footer-years {
  width: 100%;
  text-align: center; }

.footer-years p {
  font-family: OpenSans-Regular;
  font-size: 12px; }

.footer-form .nf-form-fields-required {
  display: none; }

.footer-form .nf-error .nf-field-element input {
  border: 1px solid red; }

.footer-form .nf-error-wrap {
  display: none; }

.footer-form .nf-form-errors {
  display: none; }

.footer-form .nf-response-msg {
  font-family: 'OpenSans-Regular';
  color: #fff;
  font-size: 16px; }

.footer-form .nf-form-title {
  display: none; }

.footer-form .submit-container {
  margin-bottom: 0; }

.footer-form .email-container {
  margin-bottom: 0; }

.ajax-loader.is-active {
  visibility: visible; }

.ajax-loader {
  visibility: hidden;
  display: inline-block;
  background-image: url("../img/ajax-loader.gif");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  margin: 0 0 0 4px;
  vertical-align: middle; }

.footer-form .form-left input:focus {
  outline: 0;
  padding: 0 10px;
  transition: all 0.5s; }

.footer-form .form-left .your-email {
  position: relative; }

.footer-form .form-left .wpcf7-not-valid-tip {
  position: absolute;
  left: 0;
  bottom: -20px; }

.form-right button:hover {
  opacity: 0.7; }

.footer-contacts a:hover {
  color: #455fd5;
  text-decoration: none; }

.footer-nav a:hover {
  color: #455fd5;
  text-decoration: none; }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0; }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes show {
  0% {
    opacity: 0.0; }
  100% {
    opacity: 1; } }

@keyframes bracket-line-top {
  0% {
    background-color: #ffffff;
    width: 0;
    height: 3px; }
  100% {
    background-color: #ffffff;
    width: 450px;
    height: 3px; } }

@keyframes bracket-line-bottom {
  0% {
    background-color: #ffffff;
    width: 0;
    height: 3px; }
  100% {
    background-color: #ffffff;
    width: 550px;
    height: 3px; } }

@keyframes bracket-line {
  0% {
    background-color: #ffffff;
    width: 3px;
    height: 0; }
  100% {
    background-color: #ffffff;
    width: 3px;
    height: 100%; } }

.tab-content-full .title-full {
  position: absolute;
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  color: #000;
  top: 0;
  left: 0;
  padding: 0 20px; }

.tab-content-full .service-description {
  padding: 15px 5px 20px 30px;
  min-height: 216px; }

.full-list {
  padding: 0;
  margin: 0; }

.tab-content-full .service-img img {
  width: 300px; }

.full-list li {
  list-style: disc;
  font-family: 'OpenSans-Regular';
  color: #9b9b9b;
  font-size: 14px; }

.tab-content-full .service-box, .serv-full {
  width: 300px;
  min-height: 380px; }

.tab-content-full .serv-full:hover .service-box {
  min-height: 385px; }

.tab-content-full .service-bottom {
  text-align: center; }

.blog-categories ul {
  width: 830px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.blog-categories ul li {
  list-style: none;
  border: 1px solid #fff; }

.blog-categories ul li:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }

.blog-categories ul li:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px; }

.blog-categories ul .current-cat {
  background-image: linear-gradient(349deg, #f5f7ff, #fff); }

.blog-categories ul .current-cat a {
  color: #3852d5; }

.blog-categories ul li a {
  font-size: 14px;
  color: #fff;
  font-family: 'OpenSans-Regular';
  background: 0 0;
  padding: 0 26px;
  line-height: 40px; }

.blog {
  background-color: #ffffff;
  padding: 40px 0; }
  .blog .post li {
    padding-bottom: 0.75em; }

.blog-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.blog-item-content,
.blog-item:first-child {
  width: 750px;
  max-width: 100%;
  border-right: 3px solid #3852d5;
  padding-right: 50px; }

.post-wrap {
  margin-bottom: 30px; }

.post-wrap:nth-child(n+2),
.alm-reveal:nth-child(n+2) .post-wrap,
.post:nth-child(n+2) .post-wrap {
  width: 48%; }

.post-wrap__img {
  position: relative;
  width: 700px;
  max-width: 100%;
  transition: 0.3s;
  margin-bottom: 5px; }

.post-wrap__img:hover .post-wrap__title {
  opacity: 1;
  transition: 0.3s;
  background-image: linear-gradient(to top, #3d4dd4, rgba(76, 108, 213, 0));
  background-color: transparent; }

.post-wrap__img:hover .post-wrap__title .title,
.post-wrap__img:hover .post-wrap__title .undertitle {
  color: #ffffff;
  transition: 0.3s; }

.post-wrap__img img {
  height: auto; }

.post-wrap__cat .post-categories {
  position: absolute;
  right: 0;
  top: 0;
  min-height: 40px;
  line-height: 40px;
  background-color: #f6a623;
  padding: 0 20px; }

.post-wrap:nth-child(n+2) .post-wrap__cat .post-categories,
.alm-reveal:nth-child(n+2) .post-wrap__cat .post-categories,
.post:nth-child(n+2) .post-wrap__cat .post-categories {
  line-height: 31px;
  min-height: 31px; }

.post-wrap:nth-child(n+2) .post-wrap__cat .post-categories,
.alm-reveal:nth-child(n+2) .post-wrap__cat .post-categories,
.post:nth-child(n+2) .post-wrap__cat .post-categories {
  font-size: 12px; }

.post-wrap__cat .post-categories li {
  list-style: none;
  padding: 0;
  margin: 0; }

.post-wrap__cat .post-categories li a {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 18px;
  color: #ffffff; }

.post-wrap__title {
  width: 100%;
  padding: 25px;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  transition: 0.3s; }

.post-wrap__title a {
  text-decoration: none !important; }

.post-wrap__title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000;
  margin: 0; }

.post-wrap:nth-child(n+2) .post-wrap__title,
.alm-reveal:nth-child(n+2) .post-wrap__title,
.post:nth-child(n+2) .post-wrap__title {
  padding: 9px 14px; }

.post-wrap:nth-child(n+2) .post-wrap__title .title,
.alm-reveal:nth-child(n+2) .post-wrap__title .title,
.post:nth-child(n+2) .post-wrap__title .title {
  font-size: 18px;
  margin-bottom: 0; }

.post-wrap:nth-child(n+2) .post-wrap__title .undertitle,
.alm-reveal:nth-child(n+2) .post-wrap__title .undertitle,
.post:nth-child(n+2) .post-wrap__title .undertitle {
  display: none; }

.post-wrap__title .undertitle {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #000000;
  margin: 0; }

.post-wrap:nth-child(n+2) .post-info,
.alm-reveal:nth-child(n+2) .post-info,
.post:nth-child(n+2) .post-info {
  justify-content: flex-start; }

.post-info {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: rgba(185, 185, 185, 0.71);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.info-wrap {
  margin: 0 8px;
  display: flex;
  align-items: center; }

.info-wrap i {
  display: flex;
  align-items: center;
  margin-right: 5px; }

.pageviews-placeholder {
  margin-right: 5px; }

.alm-reveal,
.post {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.alm-load-more-btn,
.malinky-load-more a {
  background-image: linear-gradient(340deg, #3d4dd4, #4b6cd6) !important;
  font-size: 14px !important;
  font-family: 'OpenSans-Regular';
  font-weight: 100 !important;
  color: #ffffff !important;
  border-radius: 7px !important;
  padding: 10px 20px; }

.posts-selector-load-more-button {
  margin-bottom: 30px; }

.search-wrap {
  margin-bottom: 25px; }

.sidebar-item {
  width: 336px; }

.search-wrap .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  text-align: center;
  color: #000000;
  margin: 0 0 13px 0; }

.search__form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.search__form label {
  width: 70%; }

.search__field {
  width: 100%;
  max-width: 100%;
  height: 27px;
  background-color: #ffffff;
  border: solid 1px #f1f1f1;
  padding-left: 5px; }

.search__field:focus {
  outline: 0;
  border-color: #3d4dd4; }

.search__submit {
  width: 30%;
  max-width: 100%;
  height: 27px;
  line-height: 26px;
  background-image: linear-gradient(341deg, #3d4dd4, #4b6cd6);
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  border: 0; }

.sidebar-subscribe {
  width: 100%;
  max-width: 100%;
  min-height: 217px;
  border-radius: 7px;
  background-image: linear-gradient(154deg, #fbda61, #f76b1c);
  padding: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.sidebar-subscribe:before {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  background-image: url("../img/icon_blog_subscribe.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60%;
  height: 100%;
  z-index: -1; }

.sidebar-subscribe .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  text-align: center;
  color: #000000;
  margin: 0 0 40px 0; }

.sidebar-subscribe p {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff; }

.sidebar-subscribe .your-email input {
  width: 100%;
  height: 27px;
  background-color: #ffffff;
  border: solid 1px #f1f1f1;
  padding-left: 10px;
  color: #9b9b9b; }

.sidebar-subscribe .your-email input:focus {
  outline: 0;
  border-color: #3d4dd4; }

.sidebar-subscribe input[type="submit"] {
  display: block;
  width: 113px;
  max-width: 100%;
  margin: 20px auto 0 auto;
  height: 40px;
  line-height: 38px;
  border-radius: 7px;
  background-image: linear-gradient(343deg, #61e365, #86e989);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  outline: 0; }

.sidebar-popular .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  text-align: center;
  color: #000000;
  margin: 0 0 13px 0; }

.sidebar-popular ul {
  padding: 0; }

.popular-posts {
  list-style: none;
  background-image: linear-gradient(to bottom, #698aed, #3852d5);
  padding: 12px 14px;
  margin-bottom: 25px; }

.popular-posts__title .title {
  font-family: 'MuseoSansCyrl-Medium';
  font-size: 18px;
  font-weight: 900;
  color: #ffffff;
  text-align: left;
  margin: 0 0 7px 0; }

.popular-posts__info {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.popular-info__cat li {
  list-style: none; }

.popular-info__cat li a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #ffffff; }

.popular-posts a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #ffffff; }

.popular-posts a:hover {
  color: #ffffff;
  text-decoration: underline; }

.popular-info__views,
.popular-info__time {
  display: flex;
  align-items: center; }

.popular-info i {
  display: flex;
  align-items: center;
  margin-right: 5px; }

.sidebar-item__banner {
  width: 100%;
  height: auto;
  margin-bottom: 25px; }

.sidebar-item__fb {
  width: 100%;
  height: auto;
  background-color: #f0f0f0;
  margin-bottom: 25px; }

.single_author {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 21px; }
  .single_author__img {
    margin-right: 16px; }

.author_info {
  display: flex;
  flex-direction: column;
  width: 470px;
  max-width: 100%; }
  .author_info__name {
    display: flex;
    align-items: center;
    color: #000000;
    font-family: 'OpenSans-Bold';
    font-size: 16px;
    margin-bottom: 4px; }
  .author_info__job {
    margin-left: 7px;
    color: #fff;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    background-color: #3852d5;
    padding: 2px 10px; }
  .author_info__description {
    color: #9b9b9b;
    font-family: 'OpenSans-Regular';
    font-size: 12px; }

.single__content img {
  height: auto; }

.single__title {
  margin: 0 0 14px 25px;
  width: 100%; }

.single__title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000;
  margin: 0 0 10px 0; }

.single__title .undertitle {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #000000;
  margin: 0; }

.single__date {
  font-family: 'OpenSans-Regular';
  font-size: 10px;
  color: #424242;
  margin: 0 0 17px 25px; }

.heateor_sss_horizontal_sharing {
  display: none; }

.single__img {
  margin-bottom: 23px;
  position: relative; }
  .single__img .title {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 25px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    color: #fff;
    background-image: linear-gradient(to top, #3d4dd4, rgba(76, 108, 213, 0));
    margin: 0; }

.single__img img {
  height: auto; }

.single__content {
  margin: 0 25px 20px 25px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  width: 700px;
  max-width: 100%;
  color: #424242; }

.wp-caption {
  max-width: 100%; }

.single__banner {
  width: 100%;
  height: auto;
  background-color: #f0f0f0;
  margin-bottom: 20px; }

.single__featured .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  color: #000000;
  margin: 0 0 20px 30px;
  width: 100%; }

.featured {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; }

.featured .post-wrap__title .title {
  margin: 0; }

.result-title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000;
  margin: 0 0 20px 0; }

body ul.heateor_sss_sharing_ul li {
  margin-bottom: 5px !important; }

body .heateor_sss_vertical_sharing .heateorSssSharing {
  border-radius: 50%;
  background-color: #3d4dd4;
  width: 30px !important;
  height: 30px !important; }

.search-in-place {
  font-family: 'OpenSans-Light';
  font-size: 14px;
  color: #424242; }

.heateor_sss_sharing_container {
  margin: 10px 0; }

.category-case .single__content h3 {
  font-family: 'OpenSans-ExtraBold';
  padding-left: 20px;
  font-size: 25px;
  color: #3852d5;
  margin: 40px 0 20px 0; }

.category-case .single__content p,
.category-case .single__content li {
  font-size: 18px;
  line-height: 1.2;
  font-family: 'OpenSans-Regular'; }

.category-case .single__content blockquote {
  background-image: linear-gradient(335deg, #f1f1f1, #dfdfdf);
  padding: 26px 37px 23px 29px;
  border: 0;
  margin: 0 20px; }
  .category-case .single__content blockquote p {
    font-family: 'OpenSans-Bold'; }

.category-case .single__content em {
  font-size: 12px; }

.category-case .single__content img {
  margin: 20px 0 0 0; }

.cases_title {
  font-family: 'OpenSans-ExtraBold';
  padding-left: 20px;
  font-size: 25px;
  color: #3852d5;
  margin: 40px 0 20px 20px !important; }

.cases_result {
  border: 7px solid #3852d5;
  margin: 0 20px 50px 40px;
  padding: 60px 20px 20px 20px; }
  .cases_result img {
    height: auto; }
  .cases_result p {
    padding-bottom: 40px; }
    .cases_result p b,
    .cases_result p strong {
      color: #3852d5;
      font-family: 'OpenSans-Bold'; }
  .cases_result__img {
    text-align: right; }
    .cases_result__img img {
      width: 100px; }

.navigation {
  display: flex; }
  .navigation a {
    margin: 0 2px; }

.blic-header {
  margin-bottom: 30px; }

.blic-wrap {
  margin-bottom: 50px; }

.blic-form .useful-input {
  width: 500px;
  max-width: 100%;
  justify-content: space-between;
  margin-bottom: 15px; }

.blic-form .useful-input label {
  text-align: left; }

.blic-form .useful-input input {
  width: 340px;
  max-width: 100%; }

.blic-form .input_submit input {
  width: 200px; }

.page-id-1544 .headhesive--stick {
  display: none !important; }

.banner_click {
  cursor: pointer; }

.banner_1 {
  background-color: #000000;
  height: 109px;
  width: 100%; }
  .banner_1__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 109px; }

.banner_1_wrap {
  position: relative; }
  .banner_1_wrap__img_1 {
    position: absolute;
    left: -180px; }
    .banner_1_wrap__img_1 img {
      width: 372px;
      max-width: 100%; }
  .banner_1_wrap__title {
    margin-left: 140px;
    z-index: 1;
    width: 320px;
    max-width: 100%;
    display: flex; }
    .banner_1_wrap__title .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #ffffff;
      text-transform: uppercase;
      margin: 0; }
      .banner_1_wrap__title .title span {
        margin: 13px 0 18px 0;
        color: #3e5af5; }
  .banner_1_wrap__text {
    width: 205px; }
    .banner_1_wrap__text p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 0;
      line-height: 1.2; }
  .banner_1_wrap__img_2 {
    position: relative;
    left: 30px; }
    .banner_1_wrap__img_2 img {
      width: 169px;
      max-width: 100%; }
  .banner_1_wrap__free {
    position: relative;
    top: -38px;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  .banner_1_wrap__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 32px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_1_wrap__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_1_wrap__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 182px; }
  .banner_2__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 182px; }

.banner_2_wrap {
  position: relative; }
  .banner_2_wrap__img_1 {
    margin-right: 100px; }
    .banner_2_wrap__img_1 img {
      height: 152px; }
  .banner_2_wrap__img_2 img {
    width: 251px; }
  .banner_2_wrap__amazon {
    position: relative; }
    .banner_2_wrap__amazon p {
      position: absolute;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.96); }
      .banner_2_wrap__amazon p:first-child {
        top: -20px;
        left: -20px; }
      .banner_2_wrap__amazon p:last-child {
        right: -25px;
        bottom: -10px; }
    .banner_2_wrap__amazon img {
      width: 158px; }
  .banner_2_wrap__free {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  .banner_2_wrap__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 32px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_2_wrap__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_2_wrap__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_3 {
  background-color: #fff;
  height: 129px;
  width: 100%; }
  .banner_3__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 129px; }

.banner_3_wrap {
  position: relative; }
  .banner_3_wrap__img_1 {
    position: absolute;
    left: -270px; }
    .banner_3_wrap__img_1 img {
      height: 129px;
      max-width: 100%; }
  .banner_3_wrap__title {
    margin-left: 165px;
    z-index: 1;
    width: 280px;
    max-width: 100%;
    display: flex; }
    .banner_3_wrap__title .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      margin: 0; }
      .banner_3_wrap__title .title span {
        margin: 13px 0 18px 0;
        color: #3e5af5; }
  .banner_3_wrap__text {
    width: 205px; }
    .banner_3_wrap__text p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000;
      margin-bottom: 0;
      line-height: 1.2; }
      .banner_3_wrap__text p span {
        font-family: 'MuseoSansCyrl-Bold';
        color: #ffffff;
        background-color: #e70626;
        padding: 0 7px; }
  .banner_3_wrap__img_2 {
    margin-right: 80px; }
    .banner_3_wrap__img_2 img {
      width: 235px;
      max-width: 100%; }
  .banner_3_wrap__free {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  .banner_3_wrap__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 32px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_3_wrap__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_3_wrap__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_4 {
  background-color: #f8f8f8;
  height: 90px;
  width: 100%; }
  .banner_4__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px; }

.banner_4_wrap {
  position: relative; }
  .banner_4_wrap__img_1 {
    position: absolute;
    left: -180px; }
    .banner_4_wrap__img_1 img {
      height: 90px;
      max-width: 100%; }
  .banner_4_wrap__title {
    z-index: 1;
    width: 275px;
    max-width: 100%;
    display: flex; }
    .banner_4_wrap__title .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      margin: 0; }
      .banner_4_wrap__title .title span {
        margin: 13px 0 18px 0;
        color: #3e5af5; }
  .banner_4_wrap__text {
    margin-right: 120px;
    width: 255px; }
    .banner_4_wrap__text p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000;
      margin-bottom: 0;
      line-height: 1.2; }
    .banner_4_wrap__text img {
      width: 80px;
      position: relative;
      top: 5px;
      left: 5px; }
  .banner_4_wrap__img_2 {
    position: absolute;
    right: 140px; }
    .banner_4_wrap__img_2 img {
      height: 90px;
      max-width: 100%;
      position: relative;
      right: 40px; }
  .banner_4_wrap__free {
    position: relative;
    top: -28px;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  .banner_4_wrap__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 32px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_4_wrap__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_4_wrap__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_5 {
  margin: 0 auto;
  padding: 16px 7px 38px 7px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start; }
  .banner_5__img_1 {
    margin-bottom: 200px;
    width: 139px;
    transform: rotate(40deg);
    position: relative;
    top: 20px; }
  .banner_5__img_2 {
    width: 213px;
    margin: 0 auto 7px auto; }
  .banner_5__amazon {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .banner_5__amazon img {
      width: 67px;
      margin: 0 2px 0 5px; }
    .banner_5__amazon p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.96); }
  .banner_5__btn a {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 38px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_5__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_5__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_6 {
  margin: 0 auto;
  padding: 25px 19px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start; }
  .banner_6__title {
    padding: 0 10px; }
    .banner_6__title .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      line-height: 1; }
      .banner_6__title .title span {
        display: block;
        margin: 13px 0 18px 0;
        color: #3e5af5;
        margin: 0; }
  .banner_6__text {
    padding: 0 10px; }
    .banner_6__text p {
      margin-bottom: 7px;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000000; }
      .banner_6__text p img {
        margin-top: 7px;
        display: block; }
  .banner_6__btn a {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 226px;
    height: 38px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_6__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_6__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_6_text_amazon {
  margin-bottom: 235px; }

.banner_7 {
  margin: 0 auto;
  padding: 25px 19px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start; }
  .banner_7__title {
    padding: 0 10px; }
    .banner_7__title .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      line-height: 1; }
      .banner_7__title .title span {
        color: #3e5af5; }
  .banner_7__text {
    padding: 0 10px; }
    .banner_7__text p {
      margin-bottom: 7px;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 1.2; }
      .banner_7__text p span {
        font-family: 'MuseoSansCyrl-Bold';
        color: #ffffff;
        background-color: #e70626;
        padding: 0 7px; }
  .banner_7_text_2 {
    margin-bottom: 13px;
    padding: 0; }
  .banner_7__btn a {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 226px;
    height: 38px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_7__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_7__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_7_text_amazon {
  margin-bottom: 256px; }

.banner_8 {
  margin: 0 auto;
  padding: 25px 19px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start; }
  .banner_8__title {
    padding: 0 10px; }
    .banner_8__title .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #fff;
      text-transform: uppercase;
      line-height: 1; }
      .banner_8__title .title span {
        color: #3e5af5; }
  .banner_8__text {
    width: 175px;
    max-width: 100%;
    padding: 0 10px; }
    .banner_8__text p {
      margin-bottom: 7px;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      line-height: 1.2; }
      .banner_8__text p span {
        font-family: 'MuseoSansCyrl-Bold';
        color: #ffffff;
        background-color: #e70626;
        padding: 0 7px; }
  .banner_8_text_2 {
    margin-bottom: 203px;
    max-width: 100%;
    background-image: linear-gradient(#d0011b, #d0011b);
    background-blend-mode: multiply;
    position: relative;
    right: -26px;
    width: 225px;
    padding: 3px 19px 4px 15px;
    text-align: right;
    opacity: 0.8; }
    .banner_8_text_2 p {
      font-size: 14px; }
  .banner_8__btn a {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 226px;
    height: 38px;
    line-height: 32px;
    border-radius: 38px;
    background-image: linear-gradient(to bottom, #3865f6, #424ff3);
    box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
    font-family: 'MuseoSansCyrl-Light';
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s; }
    .banner_8__btn a:hover {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
    .banner_8__btn a:focus {
      transition: 0.5s;
      color: #fff;
      text-decoration: none;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: none; }

.banner_8_text_amazon {
  margin-bottom: 36px; }

.guide_modal {
  background: #fff;
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  border: 3px solid #f0951c;
  padding-bottom: 40px;
  box-sizing: border-box;
  position: relative; }
  .guide_modal__top {
    margin-bottom: 24px; }

.guide__close {
  position: absolute;
  right: 0;
  top: -40px;
  cursor: pointer; }

.modal_item {
  width: 453px;
  max-width: 100%;
  margin: 0 auto 16px auto; }
  .modal_item__title {
    margin-bottom: 21px;
    text-align: center;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    color: #000000; }
  .modal_item label {
    font-family: 'OpenSans-Regular';
    font-size: 20px;
    color: #000000;
    margin-bottom: 6px; }
  .modal_item input {
    height: 58px;
    background-color: #eeeeee;
    font-family: 'OpenSans-Light';
    font-weight: 900;
    font-size: 20px;
    color: #9b9b9b;
    transition: 0.5s;
    border: 2px solid #eee;
    padding-left: 12px;
    box-sizing: border-box;
    outline: 0; }
    .modal_item input:focus {
      transition: 0.5s;
      border-color: #3852d5;
      color: #000;
      padding-left: 12px; }
    .modal_item input::placeholder {
      font-family: 'OpenSans-Light';
      font-weight: 900;
      font-size: 20px;
      color: #9b9b9b; }
  .modal_item__third label {
    display: flex;
    align-items: center;
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    font-weight: 600;
    color: #000000; }
    .modal_item__third label span {
      margin-right: 7px; }
  .modal_item input[type="checkbox"] {
    display: none; }
  .modal_item .my_check span {
    cursor: pointer;
    padding-left: 0;
    width: 28px;
    height: 28px;
    background-color: #eeeeee; }
    .modal_item .my_check span:before {
      content: "";
      display: inline-block;
      height: 22px;
      width: 24px;
      margin: 0 5px 0 0;
      background: url("../img_banners/accept.png") no-repeat;
      background-position: center;
      background-size: cover;
      left: 5px;
      position: relative;
      font-size: 29px; }
  .modal_item .un_active span:before {
    display: none; }
  .modal_item .wpcf7-form-control-wrap {
    position: relative; }
  .modal_item .wpcf7-submit {
    display: block;
    width: 301px;
    height: 48px;
    border-radius: 7px;
    background-image: linear-gradient(175deg, #fad961, #f76b1c);
    box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.58);
    transition: 0.5s;
    cursor: pointer;
    font-family: 'OpenSans-Light';
    font-weight: 900;
    font-size: 20px;
    color: #ffffff;
    border: 0;
    margin: 0 auto; }
    .modal_item .wpcf7-submit:hover {
      transition: 0.5s;
      opacity: 0.8; }
  .modal_item input[disabled="disabled"] {
    opacity: 0.4; }
    .modal_item input[disabled="disabled"]:hover {
      opacity: 0.4; }
  .modal_item span.wpcf7-not-valid-tip {
    position: absolute;
    width: 100%;
    left: 0;
    top: 19px; }
  .modal_item .ajax-loader {
    position: absolute;
    right: 50px;
    top: 20px; }
  .modal_item .wpcf7-not-valid {
    border: solid 3px #d0021b !important;
    background-color: #f8f8f8;
    margin-bottom: 20px; }
  .modal_item .wpcf7-not-valid-tip {
    font-family: 'OpenSans-Regular' !important;
    font-weight: 600;
    font-size: 14px !important;
    color: #d0021b !important;
    top: 34px !important;
    text-align: center; }

.wppaslink {
  display: flex;
  text-decoration: none !important; }

.banner_catalog__btn .wppaslink {
  display: none; }

@media only screen and (min-width: 200px) and (max-width: 767px) {
  body {
    overflow-x: hidden; }
    body .banner_top {
      position: relative; }
      body .banner_top__close {
        text-align: right;
        cursor: pointer;
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 1; }
        body .banner_top__close img {
          width: 50%; }
    body .banner_1 {
      height: 100px; }
      body .banner_1 .container {
        width: 300px;
        margin: 0 auto;
        max-width: 100%;
        padding: 0; }
      body .banner_1__common {
        z-index: 1;
        display: flex;
        flex-direction: column; }
      body .banner_1__wrapper {
        height: 100px; }
    body .banner_1_wrap {
      padding: 20px 0;
      height: 100px; }
      body .banner_1_wrap__img_1 {
        display: none; }
      body .banner_1_wrap__title {
        margin-right: 20px;
        width: initial;
        justify-content: center;
        margin-left: 0; }
        body .banner_1_wrap__title .title {
          text-align: left;
          font-size: 30px; }
          body .banner_1_wrap__title .title span {
            display: block;
            margin: 5px 0 0 0; }
      body .banner_1_wrap__img_2 {
        position: absolute;
        left: initial;
        right: -60px; }
        body .banner_1_wrap__img_2 img {
          width: initial;
          height: 100px; }
      body .banner_1_wrap__text {
        width: 150px;
        margin-bottom: 8px; }
        body .banner_1_wrap__text p {
          text-align: left;
          font-size: 10px; }
      body .banner_1_wrap__btn a {
        width: 150px;
        font-size: 12px; }
      body .banner_1_wrap__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        top: initial; }
    body .banner_2 {
      background-position: 0;
      height: 100px; }
      body .banner_2 .container {
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0; }
      body .banner_2__common {
        z-index: 1;
        display: flex;
        flex-direction: column; }
    body .banner_2_wrap {
      height: 100px; }
      body .banner_2_wrap__img_1 {
        display: none; }
      body .banner_2_wrap__img_2 {
        margin-right: 15px; }
        body .banner_2_wrap__img_2 img {
          width: 143px; }
      body .banner_2_wrap__amazon {
        display: flex;
        align-items: center;
        margin-bottom: 5px; }
        body .banner_2_wrap__amazon p {
          font-size: 10px;
          position: initial; }
        body .banner_2_wrap__amazon img {
          width: 61px;
          margin: 0 5px; }
      body .banner_2_wrap__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        position: initial;
        top: initial; }
      body .banner_2_wrap__btn a {
        width: 150px;
        font-size: 12px; }
    body .banner_3 {
      height: 100px; }
      body .banner_3 .container {
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0; }
      body .banner_3__common {
        width: 150px;
        z-index: 1;
        display: flex;
        flex-direction: column; }
    body .banner_3_wrap {
      height: 100px; }
      body .banner_3_wrap__title {
        justify-content: center;
        margin-left: 0; }
        body .banner_3_wrap__title .title {
          font-size: 30px; }
      body .banner_3_wrap__img_1 {
        margin-right: 20px;
        position: initial;
        display: block; }
        body .banner_3_wrap__img_1 img {
          height: auto;
          width: 100%; }
      body .banner_3_wrap__text {
        width: 300px;
        max-width: 100%;
        text-align: center;
        margin-bottom: 5px; }
        body .banner_3_wrap__text p {
          text-align: left;
          font-size: 10px;
          display: flex;
          align-items: center; }
        body .banner_3_wrap__text span {
          display: flex;
          align-items: center;
          width: 70px;
          font-size: 10px;
          padding: 3px 6px; }
      body .banner_3_wrap__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        position: initial;
        top: initial; }
      body .banner_3_wrap__btn a {
        width: 150px;
        font-size: 12px; }
    body .banner_4 {
      height: 100px; }
      body .banner_4 .container {
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0; }
      body .banner_4__common {
        z-index: 1;
        display: flex;
        flex-direction: column; }
    body .banner_4_wrap {
      height: 100px; }
      body .banner_4_wrap__title {
        justify-content: center;
        margin: 0 15px 0 0; }
        body .banner_4_wrap__title .title {
          font-size: 30px; }
      body .banner_4_wrap__text {
        width: 300px;
        max-width: 100%;
        text-align: center;
        margin: 0 0 10px 0;
        display: flex; }
        body .banner_4_wrap__text p {
          text-align: left;
          font-size: 10px;
          margin-right: 5px;
          width: 85px; }
        body .banner_4_wrap__text img {
          width: 61px;
          top: initial;
          left: initial; }
      body .banner_4_wrap__img_1 {
        left: -420px; }
        body .banner_4_wrap__img_1 img {
          height: 100px; }
      body .banner_4_wrap__img_2 {
        right: -460px; }
        body .banner_4_wrap__img_2 img {
          height: 100px; }
      body .banner_4_wrap__free {
        background: #ffdf79;
        padding-right: 0;
        text-align: center;
        margin: 0;
        position: initial;
        top: initial; }
      body .banner_4_wrap__btn a {
        width: 150px;
        font-size: 12px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body .banner_1 {
    height: 150px; }
    body .banner_1__wrapper {
      height: 150px; }
  body .banner_1_wrap__img_1 img {
    opacity: 0.3;
    width: 509px; }
  body .banner_1_wrap__img_2 {
    display: none; }
  body .banner_1_wrap__title {
    margin-left: 0; }
  body .banner_1_wrap .title {
    margin-left: 0; }
  body .banner_1_wrap__text p {
    font-size: 14px; }
  body .banner_1_wrap__free {
    position: absolute;
    top: 0;
    right: 0; }
  body .banner_2_wrap__img_1 {
    margin-right: 0; }
    body .banner_2_wrap__img_1 img {
      height: 90px;
      transform: rotate(-20deg);
      position: relative;
      top: 10px;
      left: -30px; }
  body .banner_2_wrap__img_2 img {
    width: 140px; }
  body .banner_3_wrap__img_1 img {
    opacity: 0.3; }
  body .banner_3_wrap__title {
    margin-left: 0; }
  body .banner_4 {
    height: 150px; }
    body .banner_4__wrapper {
      height: 150px; }
  body .banner_4_wrap__img_2 img {
    opacity: 0.3;
    height: 150px; }
  body .banner_4_wrap__free {
    position: absolute;
    top: 0;
    right: 0; }
  body .banner_4_wrap__text {
    margin-right: 85px;
    width: 290px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body .banner_1_wrap__title {
    margin-left: 0; }
  body .banner_1_wrap__text {
    width: 115px; }
  body .banner_2_wrap__img_1 {
    margin-right: 0; }
  body .banner_3_wrap__title {
    margin-left: 0; }
  body .banner_3_wrap__img_1 img {
    opacity: 0.3; }
  body .banner_4_wrap__text {
    margin-right: 0;
    width: 275px; }
  body .banner_4_wrap__img_2 img {
    opacity: 0.3; } }

.faq {
  padding-top: 40px; }

.current {
  display: block; }

.accordion_sub_question__text {
  display: none; }

.accordion_cat__title {
  text-decoration: none !important;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer !important; }
  .accordion_cat__title i {
    transform: rotate(180deg);
    margin-right: 15px;
    width: 30px;
    height: 30px;
    background-color: #3852d5;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
    font-size: 25px;
    transition: 0.5s;
    padding: 0 0 4px 0; }
    .accordion_cat__title i:before {
      line-height: 0; }
  .accordion_cat__title .title {
    color: #000;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    font-weight: 900; }
  .accordion_cat__title:hover .title {
    color: #3852d5;
    transition: 0.5s; }
  .accordion_cat__title:hover i {
    color: #fff;
    background-color: #000; }

.accordion_cat .active i {
  padding: 0 0 2px 2px;
  transform: rotate(0deg); }

.accordion_cat .active .title {
  color: #3852d5; }

.accordion_sub {
  padding-left: 21px; }

.accordion_sub_question__title {
  padding: 8px 20px 10px 20px;
  display: flex;
  align-items: center;
  color: #000;
  font-family: 'OpenSans-Regular';
  font-size: 20px;
  text-decoration: none !important;
  transition: 0.5s;
  cursor: pointer !important;
  transition: 0.5s; }
  .accordion_sub_question__title i {
    transform: rotate(180deg);
    margin-right: 15px;
    width: 30px;
    height: 30px;
    color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
    font-size: 25px;
    transition: 0.5s;
    padding: 0 0 4px 0; }
    .accordion_sub_question__title i:before {
      line-height: 0; }
  .accordion_sub_question__title:hover {
    color: #3852d5;
    transition: 0.5s; }
    .accordion_sub_question__title:hover i {
      color: #fff;
      background-color: #000; }

.accordion_sub_question .active {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background-color: #3852d5;
  color: #fff;
  transition: 0.5s; }
  .accordion_sub_question .active i {
    padding: 0 0 2px 2px;
    background-color: #fff;
    color: #3852d5;
    transform: rotate(0deg); }

.accordion_sub_question__text {
  border-radius: 9px;
  background-color: #fbfbfb;
  padding: 40px 24px 18px 66px; }
  .accordion_sub_question__text p {
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    margin-bottom: 20px;
    color: #000; }

.faq_banner_wrap {
  height: 570px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.faq_banner_item {
  padding: 27px; }
  .faq_banner_item__title {
    margin-bottom: 10px; }
    .faq_banner_item__title .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 25px;
      font-weight: 900;
      text-shadow: 0 2px 17px #fafafc;
      color: #7bc039; }
  .faq_banner_item__text {
    margin-bottom: 10px; }
    .faq_banner_item__text p {
      font-family: 'MuseoSansCyrl-Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      color: rgba(0, 0, 0, 0.68); }
  .faq_banner_item__logo {
    margin-bottom: 272px; }
  .faq_banner_item__form form {
    text-align: center; }
    .faq_banner_item__form form input {
      width: 200px;
      max-width: 100%;
      height: 38px;
      line-height: 36px;
      background-color: #ffffff;
      font-family: 'OpenSans-Regular';
      font-size: 14px;
      padding: 0 0 0 10px;
      box-sizing: border-box;
      color: #d8d8d8;
      outline: 0;
      transition: 0.5s;
      border: 2px solid #fff;
      margin-bottom: 15px; }
      .faq_banner_item__form form input::placeholder {
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        color: #d8d8d8;
        transition: 0.5s; }
      .faq_banner_item__form form input:focus {
        border-color: #7abf39;
        transition: 0.5s; }
    .faq_banner_item__form form label {
      font-family: 'OpenSans-Regular';
      font-size: 11px;
      color: #000000;
      margin-left: 9px; }
    .faq_banner_item__form form .btn-green {
      cursor: pointer;
      margin: 0 auto;
      font-family: "MuseoSansCyrl-Medium" !important;
      transition: 0.5s;
      display: inline-block;
      width: 200px;
      height: 40px;
      background-image: linear-gradient(-288deg, #b4ec51, #429321);
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff; }
      .faq_banner_item__form form .btn-green:hover {
        opacity: .8;
        transition: 0.5s;
        text-decoration: none; }

.sub_accordion {
  background-color: initial !important;
  cursor: pointer; }
