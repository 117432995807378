// service

.breadcrumbs {
  display: flex;
  justify-content: center;
  margin: 25px 0 0 0;
  text-align: center; }


.breadcrumbs a {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: rgba(255, 255, 255, 0.70);
  position: relative; }

.breadcrumbs span {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: rgba(255, 255, 255, 0.70);
  position: relative;
  margin: 0 2px; }

.breadcrumbs a:before {
  position: absolute;
  content: '一>';
  right: -12px;
  top: -2px; }

.breadcrumbs a:last-child:before {
  display: none; }


// service info

.service-info {
  background-color: #fff; }

.service-info .no-padding {
  padding: 18px 40px 57px 40px;
  border-bottom: 2px solid #f3f3f3; }

.info-title {
  text-align: center;
  margin-bottom: 31px; }

.info-left {
  width: 720px;
  max-width: 100%;
  display: inline-block; }

.consist-block {
  border-right: 3px solid #3852d5;
  padding-right: 50px; }

.consist-item {
  padding-bottom: 28px;
  position: relative; }


.consist-item:after {
  position: absolute;
  content: '';
  background: url("../img/checkmark-green.svg") #ffffff no-repeat;
  background-size: cover;
  left: 0;
  top: 0;
  color: #ffffff;
  width: 20px;
  height: 20px; }

.consist-item:before {
  position: absolute;
  content: '';
  left: 8px;
  top: 20px;
  background-color: #86e989;
  width: 3px;
  height: 100%; }

.consist-item:last-child:before {
  display: none; }

.consist {
  padding-left: 40px; }

.consist .title {
  font-family: 'OpenSans-Regular';
  font-size: 20px;
  color: #000000;
  margin: 0 0 10px 0; }

.consist p {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 0; }


.consist-who {
  padding-left: 40px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

.consist-who b,
.consist-who strong {
  font-family: 'OpenSans-Regular';
  font-size: 20px;
  color: #000000;
  margin: 0 0 10px 0;
  font-weight: 500; }

.consist li {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 0; }

.info-right {
  display: inline-block;
  vertical-align: top;
  padding-left: 50px;
  width: 330px;
  max-width: 100%; }

.banner_catalog {
  width: 270px;
  max-width: 100%;
  background-image: linear-gradient(163deg, #b4ed50, #79bf38 29%, #b1ea4f 67%, #90d041);
  padding-bottom: 39px;
  margin-top: 20px;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    background: url("../img/icon_catalog_arrow.svg") no-repeat;
    background-size: cover;
    bottom: 91px;
    right: -17px;
    width: 45px;
    height: 109.8px;
    z-index: 1; }
  &__text {
    margin-bottom: 30px;
    p {
      font-family: 'MuseoSansCyrl-Medium';
      font-size: 15px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 0;
      line-height: 1;
      b {
        font-family: 'MuseoSansCyrl-Bold'; } } }
  &__btn {
    width: 212px;
    a {
      width: 100%;
      margin: 0 auto;
      display: block;
      border-radius: 7px;
      background-image: linear-gradient(169deg, #f5515f, #9f031b);
      box-shadow: 0 2px 4px 0 rgba(56, 82, 213, 0.42), inset 0 -1px 0 0 rgba(255, 255, 255, 0.18);
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: white;
      transition: 0.5s;
      &:hover {
        color: white;
        opacity: 0.8;
        transition: 0.5s;
        text-decoration: none; } } }
  &__img {
    p {
      position: relative;
      top: -50px;
      right: -55px;
      transform: rotate(-16deg);
      opacity: 0.25;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 22px;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase; } } }

.catalog_top {
  display: flex;
  margin-bottom: 27px;
  &__line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 9px;
    width: 198.8px;
    height: 33px;
    background-image: linear-gradient(176deg, #ee342e, #9f031b);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    position: relative;
    left: -15px;
    top: 46px;
    &:before {
      position: absolute;
      content: '';
      right: 0;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid #8cce3e;
      border-bottom: 15px solid transparent; } }
  &__img {
    width: 64px; } }

.catalog_title {
  margin-bottom: 5px;
  .title {
    height: 36px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    text-align: center;
    color: #f8e81c;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    margin: 0 0 3px 0;
    text-transform: uppercase;
    line-height: 1; }
  p {
    font-family: 'MuseoSansCyrl-Medium';
    font-size: 19px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
    margin-bottom: 0;
    line-height: 1;
    b {
      font-family: 'MuseoSansCyrl-Bold'; } } }

.guide {
  top: 50px;
  position: fixed;
  display: none;
  z-index: 3;
  &_over {
    width: 627px;
    max-width: 100%;
    margin: 0 auto;
    .active {
      display: block; } } }

.guide_form {
  width: 427.6px;
  background-image: linear-gradient(157deg, #b4ed50, #79bf38 31%, #b1ea4f 73%, #90d041);
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.07);
  padding-bottom: 25px;
  margin: 0 auto;
  &__wrapper {
    padding: 20px 40px 0 40px;
    label,
    p {
      font-family: 'OpenSans-Light';
      font-size: 10px;
      text-align: left;
      color: #ffffff;
      margin-bottom: 8px; }
    input {

      height: 22px;
      border: 0;
      outline: 0;
      font-family: 'OpenSans-Light';
      font-size: 12px;
      color: rgba(155, 155, 155, 0.71);
      padding-left: 10px;
      margin-bottom: 4px;
      box-sizing: border-box;
      &:focus {
        padding-left: 10px; }
      &::placeholder {
        font-family: 'OpenSans-Light';
        font-size: 12px;
        color: rgba(155, 155, 155, 0.71); } }
    span {
      width: 20px; } } }

.guide_item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  img {
    margin-right: 11px; }
  &__third {
    padding-left: 30px;
    margin-top: 22px;
    span {
      margin-right: 5px; } }
  .wpcf7-form-control-wrap {
    position: relative; }
  .wpcf7-submit {
    display: block;
    width: 212px;
    height: 40px;
    line-height: 38px;
    border-radius: 7px;
    background-image: linear-gradient(169deg, #f5515f, #9f031b);
    box-shadow: 0 2px 4px 0 rgba(56, 82, 213, 0.33), inset 0 -1px 0 0 rgba(0, 0, 0, 0.18);
    font-family: 'OpenSans-Bold';
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    margin: 22px auto 0 auto;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      transition: 0.5s;
      opacity: 0.8; } }
  input[disabled="disabled"] {
    opacity: 0.4;
    &:hover {
      opacity: 0.4; } }
  span.wpcf7-not-valid-tip {
    position: absolute;
    width: 100%;
    left: 0;
    top: 19px; }
  .ajax-loader {
    position: relative;
    right: 50px;
    top: -10px; } }


.guide_top {
  background-image: linear-gradient(333deg, #3d4dd4, #4b6cd6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding-left: 22px;
  &__text {
    p {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 16px;
      color: #ffffff;
      width: 262px;
      max-width: 100%;
      margin: 0; } }
  img {
    width: 140px;
    position: absolute;
    top: -40px;
    right: 15px; } }

.why-item {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #000000;
  width: 270px;
  max-width: 100%;
  border: solid 1px #979797;
  position: relative;
  margin-bottom: 34px;
  padding: 20px 20px 25px;
  line-height: 34px;
  word-wrap: break-word; }

.why-item:last-child {
  margin-bottom: 0; }

.why-item strong {
  color: #3852d5; }

.why-item:before {
  position: absolute;
  content: '';
  background-color: #3852d5;
  height: 14px;
  width: 100%;
  bottom: 0;
  left: 0; }

.complex-arrow {
  margin-bottom: 20px; }

.package-title {
  text-align: center;
  padding: 20px 10px; }

.package-title .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0; }

.price-box p {
  padding: 0 15px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

// Price

.price-block {
  padding: 18px 0 20px 0;
  background-color: #ffffff; }

.price-title {
  text-align: center;
  margin-bottom: 30px; }

.price-package {
  width: 317px;
  max-width: 100%;
  box-shadow: 0 1px 10px 1px rgba(0,0,0,.06);
  border: 2px solid rgba(0,0,0,.06);
  position: relative;
  top: 0;
  display: inline-block;
  margin: 0 30px 20px 30px;
  vertical-align: top;
  transition: 0.5s; }

.price-box {
  position: relative;
  -webkit-box-shadow: 0 22px 19px -13px rgba(0,0,0,.1);
  box-shadow: 0 22px 19px -13px rgba(0,0,0,.1);
  padding-bottom: 20px;
  min-height: 475px; }

.price-box ul li {
  font-family: 'OpenSans-Regular';
  font-size: 13px;
  color: #9b9b9b; }

.package-check {
  margin-top: 24px;
  padding-left: 25px;
  width: 100%;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b; }

.package-check input {
  margin: 0 10px 0 0; }

.package-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px; }

.package-price {
  margin-top: 48px;
  text-align: center; }

.package-price .price {
  margin: 0;
  font-family: 'OpenSans-Regular';
  font-size: 43px;
  color: #d8d8d8; }

.package-price .price span {
  font-family: 'OpenSans-Regular';
  font-size: 19px;
  color: #d8d8d8;
  vertical-align: top;
  position: relative;
  top: 10px;
  left: -5px; }

.package-btn {
  text-align: center;
  margin-top: 10px;
  line-height: 40px; }

.package-btn .order-btn {
  border-radius: 7px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  padding: 0 30px 0 30px!important;
  line-height: 40px;
  border: 0;
  transition: 0.5s; }


.service-modal_additional {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  padding: 25px 15px;
  text-align: center; }

.service-left_form {
  display: inline-block;
  width: 286px;
  max-width: 100%;
  vertical-align: top;
  margin: 0 20px 0 50px; }

.service-form_right {
  display: inline-block;
  width: 286px;
  max-width: 100%;
  vertical-align: top;
  margin: 0 0 0 20px; }

.service-form_right .no_margin {
  margin-bottom: 0; }

.input_block {
  margin-bottom: 19px; }

.choosen_service {
  font-family: 'OpenSans-Bold';
  font-size: 14px;
  color: #000000; }

.service-form_right .input_block label {
  margin-bottom: 8px; }

.input_block label {
  display: block;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  font-weight: normal;
  margin-bottom: 4px; }

.service-form_right .choosen_service {
  margin-bottom: 20px; }

.input_block input {
  background-color: #ffffff;
  border: solid 1px #f1f1f1;
  display: block;
  width: 100%;
  outline: 0;
  height: 27px;
  padding-left: 10px; }

.input_block textarea {
  width: 100%;
  height: 95px;
  background-color: #ffffff;
  border: solid 1px #f1f1f1;
  display: block;
  outline: 0;
  padding-left: 10px; }

.input_submit {
  text-align: center;
  margin-top: 20px; }

.input_submit input {
  border-radius: 7px!important;
  background-image: linear-gradient(343deg, #61e365, #86e989);
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #ffffff;
  border: 0;
  min-width: 113px;
  height: 40px;
  outline: 0; }

.input_submit input:hover {
  background-image: linear-gradient(343deg,#7ae87e,#9be89e); }

.input_submit input:focus {
  background-image: linear-gradient(343deg,#7ae87e,#9be89e); }

.sale-text {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #9b9b9b;
  text-align: center; }


//.package-sale
//  min-height: 86px
//
.package-sale .sale {
  width: 280px;
  margin: 20px auto 0 auto;
  max-width: 100%;
  background-color: #f6a623;
  font-family: 'RobotoMono-Light';
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 4px;
  line-height: 40px;
  text-align: center; }


.and-block {
  width: 100%;
  text-align: center;
  margin: 5px 0;
  font-family: 'RobotoMono-Medium';
  font-size: 14px;
  color: #9b9b9b;
  display: block; }


// Portfolio

.portfolio-block {
  padding: 18px 0 50px 0;
  background-color: #eeeeee; }

.portfolio-title {
  text-align: center; }

.img-wrap img {
  width: 100%;
  opacity: 1;
  transition: opacity .6s; }

.img-wrap {
  float: left;
  position: relative;
  line-height: 0;
  margin: 0 15px 15px;
  cursor: pointer;
  width: 158px!important;
  height: 158px!important;
  outline: 0; }

.img-box {
  width: 158px;
  height: 158px;
  background-size: cover; }

.portfolio-items a:focus {
  outline: 0; }

.portfolio-items img {
  background: url('https://s3-eu-west-1.amazonaws.com/media.lpgenerator.ru/uploads/2016/03/09/4.gif') 50% no-repeat; }

.img-wrap:before {
  content: '';
  position: absolute;
  top: .3em;
  left: .3em;
  right: .3em;
  bottom: .3em;
  transition: opacity 2s; }

.img-wrap:hover:before {
  opacity: 0; }

.portfolio-photo {
  margin: 31px 0 0 0; }

.portfolio-navigate {
  line-height: 40px;
  margin: 0 auto;
  padding: 0;
  width: 695px;
  max-width: 100%;
  display: none; }

.portfolio-items {
  outline: 0; }

.portfolio-items {
  display: flex!important;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; }

.portfolio-items img {
  width: 158px;
  height: 158px; }

.portfolio-menu nav ul li {
  float: left;
  list-style: none; }

.portfolio-menu nav ul li a {
  padding: 11px 21px;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #3852d5;
  transition: color .6s, background-color .2s;
  border-radius: 0;
  background-image: linear-gradient(352deg, #ffffff, #ffffff);
  border: solid 1px #f1f1f1; }

.portfolio-menu nav ul li:first-child a {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }

.portfolio-menu nav ul li:last-child a {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px; }

.portfolio-menu nav ul li a:hover {
  text-decoration: none;
  color: #3852d5; }

.portfolio-menu .active a {
  border-radius: 0;
  background-image: linear-gradient(299deg, #3d4dd4, #4b6cd6);
  border: solid 1px #f1f1f1;
  color: #ffffff;
  text-decoration: none; }

.portfolio-menu .active a:hover {
  color: #ffffff; }

.portfolio-slider {
  outline: none; }

.portfolio-slider .slick-dots {
  padding: 0;
  text-align: center;
  margin-top: 30px; }

.portfolio-slider .slick-dots button {
  display: none; }

.portfolio-slider .slick-dots li {
  display: inline-block;
  list-style: none;
  width: 11.3px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px; }

.portfolio-slider .slick-dots .slick-active {
  background-color: #3852d5; }

.over_blue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(56, 82, 213, 0.95); }

.over_blue_active {
  opacity: 1; }


// Reviews block

.reviews-block {
  padding: 18px 0 50px 0;
  background-color: #ffffff;
  position: relative; }

.review-title {
  text-align: center; }

.review-box {
  max-width: 100%;
  min-height: 330px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
    .rev-wrapper {
      background-image: linear-gradient(352deg, #3d4dd4, #4b6cd6);
      transition: 0.5s;
      &:before {
        background: #fff;
        transition: 0.5s; } }
    .rev-text {
      p {
        color: #fff;
        transition: 0.5s; } }
    .more-fb {
      a {
        color: #fff;
        transition: 0.5s;
        i {
          color: #fff;
          transition: 0.5s; } } } } }

.rev-box-top {
  padding: 20px 30px 15px;
  background-color: #fff!important; }

.rev-box-avatar {
  display: inline-block; }

.rev-box-avatar img {
  width: 75px;
  height: 75px; }

.rev-box-info {
  display: inline-block;
  vertical-align: top;
  margin-left: 23px; }

.rev-box-name {
  font-family: 'OpenSans-Bold';
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #3852d5; }

.rev-box-stars p {
  display: flex;
  color: #ffd052;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 6px; }

.rev-box-date {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
  color: #9b9b9b; }

.rev-wrapper {
  transition: 0.5s;
  background-color: #f8f8f8;
  position: relative;
  min-height: 244px;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 11px;
    background: rgba(56, 82, 213, 0.95);
    bottom: 0;
    transition: 0.5s;
    left: 0; } }

.rev-text {
  padding: 19px 12px 0 30px;
  p {
    min-height: 150px; } }

.rev-text p {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  text-align: left;
  color: #000000; }

.rev-more {
  padding: 15px 10px 15px 30px;
  text-align: right; }

.rev-more .more-btn {
  display: inline-block;
  float: right;
  line-height: 40px; }

.more-btn a {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  border-radius: 7px;
  border: 0;
  color: #ffffff;
  padding: 13px 25px;
  i {
    margin-left: 10px; } }

.rev-more .more-fb {
  display: inline-block;
  vertical-align: top;
  float: right; }

.more-fb {
  a {
    font-family: 'OpenSans-Bold';
    font-size: 12px;
    font-weight: 300;
    color: #3852d5;
    text-decoration: underline;
    i {
      color: #3852d5;
      font-size: 35px;
      padding-top: 0;
      position: relative;
      top: 7px; } } }

.more-fb a:focus {
  outline: 0; }

.more-fb a i:focus {
  outline: 0; }

.responsive-rev {
  margin-top: 35px; }

.responsive-rev .slick-dots {
  padding: 0;
  text-align: center;
  margin-top: 30px; }

.responsive-rev .slick-dots button {
  display: none; }

.responsive-rev .slick-dots li {
  display: inline-block;
  list-style: none;
  width: 11.3px;
  height: 11px;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px; }

.responsive-rev .slick-dots .slick-active {
  background-color: #3852d5; }

.order_fulls {
  width: 758px;
  z-index: 2;
  padding-bottom: 20px;
  left: calc(50% - 400px); }

.rev_fulls {
  width: 555px;
  z-index: 1;
  left: calc(50% - 300px);
  &__close {
    color: #fff;
    opacity: 1;
    font-family: 'OpenSans-Bold';
    transition: all 0.1s;
    text-shadow: none;
    position: absolute;
    right: 15px;
    top: -4px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold; } }

.order_fulls {
  &__close {
    color: #fff;
    opacity: 1;
    font-family: 'OpenSans-Bold';
    transition: all 0.1s;
    text-shadow: none;
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold; } }

.rev_fulls,
.order_fulls {
  display: none;
  position: fixed;
  top: 134px;
  box-shadow: 0 1px 10px 1px rgba(0,0,0,.06);
  max-width: 100%;
  background-color: white;
  color: #333;
  margin: 0;
  border: solid 1px #3852d5;
  z-index: 3;
  .title,
  h3 {
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    color: #ffffff;
    background-color: #3852d5;
    padding: 15px 0;
    text-align: center;
    margin: 0; }
  p {
    padding: 5px 25px; } }

.form_sent {
  z-index: 100;
  display: none;
  position: fixed;
  top: 134px;
  left: 0;
  width: 100%;
  &__block {
    position: relative;
    width: 640px;
    margin: 0 auto;
    max-width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.07);
    z-index: 3;
    border: 2px solid #3852d5;
    padding: 68px 0 53px 0;
    box-sizing: border-box; }
  .title {
    margin-bottom: 39px;
    font-family: 'MuseoSansCyrl-Bold';
    text-align: center;
    font-size: 30px;
    color: #000000; }
  &__wrapper {
    margin-bottom: 43px; }
  &__close {
    display: block;
    margin: 0 auto;
    width: 187px;
    border-radius: 7px;
    background-image: linear-gradient(338deg, #3d4dd4, #4c6cd5);
    box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.58);
    height: 48px;
    line-height: 48px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      transition: 0.5s;
      color: white;
      opacity: 0.8; } } }
.sent_wrap {
  &__text {
    width: 400px;
    max-width: 100%;
    margin: 0 auto 50px auto;
    p {
      font-family: 'OpenSans-Regular';
      font-size: 20px;
      font-weight: 600;
      color: #000000; } } }

.guide_modal__close {
  top: -40px;
  right: 0;
  position: absolute;
  cursor: pointer; }


// hover

.breadcrumbs a:hover {
  color: #ffffff;
  text-decoration: none; }

.package-btn .order-btn:hover {
  text-decoration: none;
  background-image: linear-gradient(343deg,#80f183,#a3f5a6);
  transition: 0.5s; }

.package-btn .order-btn:focus {
  text-decoration: none;
  background-image: linear-gradient(343deg,#80f183,#a3f5a6);
  outline: 0;
  transition: 0.5s; }

.price-package:hover {
  border: 2px solid #3852d5;
  top: -5px;
  cursor: pointer; }

.price-package:hover .price-box {
  -webkit-box-shadow: 0 22px 19px -13px #3852d5;
  box-shadow: 0 22px 19px -13px #3852d5;
  transition: 0.5s; }

.price-box ul {
  overflow: auto;
  max-height: 250px;
  padding: 0 20px 0 40px; }

.more-btn a:hover {
  opacity: .8;
  text-decoration: none;
  color: #fff; }

.more-btn a:focus {
  opacity: .8;
  text-decoration: none;
  color: #fff;
  outline: 0; }

.more-fb a:hover i {
  color: #3852d5; }


//call

body {
  #bingc-passive {
    display: none!important; }
  #bingc-phone-button {
    left: -200%!important; }
  .bingc-blur-whole-page {
    filter: none!important; }
  .bingc-active {
    display: none!important; } }

.call {
  background: url('../img/call_bg.svg') center no-repeat;
  background-size: cover;
  width: 750px;
  max-width: 100%;
  min-height: 131px;
  position: relative;
  padding-top: 10px;
  box-sizing: border-box;
  margin-top: 80px; }

.call_blue {
  background: url('../img/call_bg_blue.svg') center no-repeat!important;
  .call__info {
    .title,
    p {
      color: #fff; } }
  form {
    .btn-blue {
      background-image: linear-gradient(155deg, #fbda61, #ffb521)!important; } } }

.call_home {
  background: url('../img/call_bg_home.svg') center no-repeat; }

.call_blue {
  background: url('../img/call_bg_blue.svg') center no-repeat; }

.call_home,
.call_blue {
  padding: 10px 40px 10px 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .call__img {
    left: 31px;
    top: -23px;
    img {
      width: 88px; } }
  .call__info {
    padding-top: 10px;
    width: 660px;
    max-width: 100%;
    .title {
      margin-bottom: 0; } }
  form .btn-blue {
    margin-left: 50px!important; } }

.call__info {
  padding-left: 140px; }

.call_info .title {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 19px;
  font-weight: 900;
  color: #000000;
  margin: 0 0 10px 0; }

.call_info p {
  font-family:  'OpenSans-Regular';
  font-size: 12px;
  color: #000000; }

.call__img {
  position: absolute;
  left: 6px;
  top: -47px; }

.call__img img {
  width: 119px; }

.call {
  form {
    input {
      width: 200px;
      max-width: 100%;
      height: 27px;
      line-height: 22px;
      background-color: #ffffff;
      font-family: 'OpenSans-Regular';
      font-size: 12px;
      padding: 0 0 0 10px;
      box-sizing: border-box;
      color: #d8d8d8;
      outline: 0;
      transition: 0.5s;
      border: 1px solid #fff;
      &::placeholder {
        font-family: 'OpenSans-Regular';
        font-size: 12px;
        color: #d8d8d8;
        transition: 0.5s; }
      &:focus {
        border-color: red;
        transition: 0.5s; } }
    label {
      font-family: 'OpenSans-Regular';
      font-size: 11px;
      color: #000000;
      margin-left: 9px; }
    .btn-blue {
      font-family: 'OpenSans-Regular'!important;
      transition: 0.5s;
      display: inline-block;
      width: 164px;
      height: 40px;
      margin-left: 100px;
      border-radius: 7px;
      background-image: linear-gradient(337deg, #3d4dd4, #4b6cd6);
      box-shadow: 0 2px 4px 0 rgba(61, 77, 212, 0.41);
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      &:hover {
        opacity: .8;
        transition: 0.5s;
        text-decoration: none; } } } }

// form

.service-form form {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  border: 1px solid #3852d5;
  padding-bottom: 25px; }


.service-form form h3 {
  font-family: 'MuseoSansCyrl-Bold';
  font-size: 30px;
  color: #fff;
  background-color: #3852d5;
  margin: 0;
  text-align: center;
  padding: 20px 10px; }


.service-form form .input_submit {
  display: flex;
  flex-direction: row;
  justify-content: center; }


.service-form form h5 {
  font-family: 'OpenSans-Regular';
  color: #d8d8d8;
  font-size: 43px;
  margin: -3px 15px 0 0; }

// listing prices

.listing_prices {
  background-color: #fff;
  padding: 20px 0;
  &__wrap {
    display: flex;
    justify-content: center; } }

.prices_item {
  margin: 0 30px 30px 30px;
  width: 310px;
  &__shadow {
    box-shadow: 0 13px 21px 0 rgba(0, 0, 0, 0.08);
    transition: 0.5s;
    &:hover {
      transition: 0.5s;
      box-shadow: 0 36px 15px -23px #405cd8, 0 2px 21px 0 rgba(0, 0, 0, 0.09);
      background-color: #ffffff;
      .prices_list {
        &:last-child {
          .prices_services {
            border-color: #435dd8; } } }
      .prices_services,
      .prices_order {
        &:before,
        &:after {
          background-color: #435dd8; } }
      .prices_order {
        border-color: #435dd8; } } }
  &__title {
    .title {
      color: #435dd8;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 30px;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0 22px 0; } } }

.prices_accordion {
  padding: 0;
  border: 0; }

.prices_list {
  &:last-child {
    .prices_services {
      border-bottom: solid 3px #fff; } }
  &__title {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #435dd8;
    padding: 0 48px 0 29px;
    width: 100%;
    cursor: pointer;
    &:hover {
      text-decoration: none; }
    .title {
      color: #fff;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 16px;
      font-weight: 900;
      margin: 0; }
    i {
      font-size: 18px;
      font-weight: bold;
      transition: 0.5s; } }
  .active {
    i {
      transform: rotate(180deg);
      transition: 0.5s; } } }

.prices_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__quantity {
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 25px;
    color: #fff; } }

.prices_services {
  position: relative;
  padding: 20px 30px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 3px;
    height: 100%;
    top: 0;
    transition: 0.5s; }
  &:before {
    left: 0; }
  &:after {
    right: 0; } }

.prices_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  &__title {
    .title {
      color: #9b9b9b;
      margin: 0;
      font-family: 'OpenSans-Regular';
      font-size: 12px; } }
  &__price {
    min-width: 50px;
    text-align: center;
    color: #9b9b9b;
    margin: 0;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    select {
      cursor: pointer;
      outline: 0;
      padding: 0 18px 0 4px;
      color: #9b9b9b;
      font-family: 'OpenSans-Regular';
      font-size: 12px;
      min-width: 38px;
      height: 17px;
      border: solid 1px #e8e8e8; }
    label {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: #000 transparent transparent transparent;
        right: 4px;
        top: 7px; } } } }

.prices_order {
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 3px solid #fff;
  transition: 0.5s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 3px;
    height: 100%;
    top: 0;
    transition: 0.5s; }
  &:before {
    left: 0; }
  &:after {
    right: 0; }
  &__title {
    color: #9b9b9b;
    font-family: 'OpenSans-Regular';
    font-size: 15px; }
  &__total {
    color: #73e676;
    font-family: 'OpenSans-Regular';
    font-size: 36px;
    margin-bottom: 25px; }
  &__btn {
    margin-bottom: 20px;
    a {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      width: 151px;
      height: 40px;
      border-radius: 7px;
      background-image: linear-gradient(338deg, #3d4dd4, #4c6cd5);
      box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67);
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        opacity: 0.8; } } } }

// New prices

.price_table {
  padding: 50px 0 200px 0;
  background-color: #fff;
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 950px;
    max-width: 100%;
    margin: 0 auto; } }

.price_top {
  &__titles {
    display: flex;
    justify-content: space-between; } }

.price_titles {
  &__name {
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 20px;
    color: #435dd8;
    text-transform: uppercase;
    width: 20%;
    &:first-child {
      width: 40%; } } }

.price_item {
  .price_item__row {
    &:last-child {
      .price_row__title {
        border-bottom: 0; }
      .price_row__include {
        span {
          border-bottom: 0; } } } } }

.price_column {
  display: flex;
  &__block {
    position: relative;
    width: 100%;
    border-top: solid 1px #e8e8e8;
    height: 35px;
    &:hover {
      transition: 0.5s;
      .title {
        font-family: 'OpenSans-Bold';
        font-weight: bold; }
      .price_column__text {
        display: block; } } }
  span {
    position: relative;
    display: inline-flex; }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    .title {
      display: inline-flex;
      align-items: center;
      width: 100%;
      color: #0f0f0f;
      font-size: 16px;
      font-family: 'OpenSans-Regular';
      padding-left: 34px;
      margin: 0;
      height: 35px;
      transition: 0.5s; } }
  &__text {
    transition: 0.5s;
    display: none;
    position: absolute;
    bottom: 25px;
    right: -50px;
    p {
      width: 235px;
      min-height: 88px;
      background-color: #ffde62;
      box-shadow: 0 26px 15px -25px rgba(255, 222, 98, 0.59);
      border-radius: 7px;
      padding: 11px 15px;
      font-family: 'OpenSans-Light';
      font-size: 12px;
      color: #000;
      position: relative;
      z-index: 1;
      &:before {
        position: absolute;
        content: '';
        bottom: -10px;
        right: 30px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #ffde62; } } }
  &__include {
    position: relative;
    width: 20%;
    color: #0f0f0f;
    font-size: 20px;
    font-family: 'OpenSans-Regular';
    transition: 0.5s;
    &:hover {
      transition: 0.5s;
      border-color: #3852d5;
      border-width: 3px; }
    span {
      min-height: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid 1px #e8e8e8;
      border-right: 0;
      border-bottom: 0; }
    &:last-child {
      span {
        border-right: solid 1px #e8e8e8; } } } }

.bg_blue {
  display: flex;
  align-items: center;
  border: 0!important;
  background-color: #435dd8;
  .title {
    font-family: 'MuseoSansCyrl-Bold' !important;
    font-size: 16px;
    color: #fff!important;
    line-height: 2.5; } }

.service_padding {
  padding-left: 30px!important; }

.no_border {
  border: 0!important; }

.no_border_top {
  border-top: 0!important; }

.no_border_bottom {
  border-bottom: 0!important; }

.service_price {
  display: flex;
  flex-direction: column;
  padding: 18px 20px 15px 20px;
  border-bottom: 1px solid #e8e8e8!important;
  p {
    text-align: center;
    font-size: 12px;
    font-family: 'OpenSans-Light';
    margin: 0; }
  span {
    margin-top: 23px;
    font-family: 'OpenSans-Regular';
    font-size: 36px;
    color: #73e676;
    border: 0!important; } }

.price_hover {
  transition: 0.5s;
  a {
    color: #0f0f0f;
    text-decoration: none; }
  span {
    transition: 0.5s; }
  &:hover {
    transition: 0.5s;
    a {
      text-decoration: none; }
    span {
      border-right: 3px solid #435dd8;
      border-left: 3px solid #435dd8;
      &:first-child {
        border-top: 3px solid #435dd8; } }
    .service_btn {
      border: 0!important;
      background-image: linear-gradient(338deg, #3d4dd4, #4c6cd5);
      box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67);
      span {
        color: #fff;
        border: 0!important;
        text-decoration: none; } }
    .service_price {
      border-bottom: 3px solid #435dd8!important;
      box-shadow: 0 45px 27px -34px rgba(56,82,213,1); } } }

.service_btn {
  position: absolute;
  left: 0;
  bottom: -76px;
  border: 0!important;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 40px;
  border-radius: 7px;
  margin: 0 auto;
  font-size: 14px;
  font-family: 'OpenSans-Regular';
  color: #fff;
  background-image: linear-gradient(338deg, rgba(61, 77, 212, 0.55), rgba(76, 108, 213, 0.56));
  span {
    border: 0!important; } }

.personal {
  padding: 0 15px;
  background-color: #fff;
  .container {
    border: solid 3px #3852d5;
    padding: 34px 0 0 0; }
  &__list {
    padding: 0 15px;
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    li {
      font-size: 16px;
      font-family: 'OpenSans-Regular';
      font-weight: bold;
      color: #000;
      list-style: disc; } }
  &__title {
    padding: 0 15px;
    text-align: center;
    margin-bottom: 17px;
    .title {
      font-size: 20px;
      font-family: 'OpenSans-Bold';
      color: #3852d5;
      text-transform: uppercase; } }
  &__undertitle {
    padding: 0 15px;
    text-align: center;
    margin-bottom: 18px;
    p {
      font-size: 12px;
      font-family: 'OpenSans-Light'; } }
  &__order {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75px;
    background-image: linear-gradient(305deg, #3d4dd4, #4c6cd5);
    padding: 0 15px; } }

.personal_order {
  &__title {
    font-family: 'OpenSans-Bold';
    font-size: 20px;
    color: #fff;
    margin-right: 30px; }
  &__btn {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 151px;
      height: 40px;
      border-radius: 7px;
      font-size: 14px;
      font-family: 'OpenSans-Regular';
      color: #fff;
      background-image: linear-gradient(338deg, #61e365, #86e989);
      transition: 0.5s;
      &:hover {
        color: #fff;
        transition: 0.5s;
        opacity: 0.7;
        text-decoration: none; } } } }

.popup-widget__form {
  table {
    display: flex;
    flex-direction: column;
    thead {
      tr {
        td {
          width: 33.3%; } } }
    tbody {
      tr {
        td {
          &:first-child {} } } } } }


// Service new

.srvs_top {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  .breadcrumbs {
    margin: 5px 0 40px 0; }
  &__wrapper {
    display: flex;
    justify-content: space-between; }
  &__title {
    margin-bottom: 12px;
    .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff; } }
  &__text {
    margin-bottom: 28px;
    p {
      font-family: 'OpenSans-Light';
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff; } }
  &__btn {
    a {
      font-family: 'OpenSans-Regular';
      font-size: 14px;
      color: #fff;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 238px;
      max-width: 100%;
      height: 40px;
      border-radius: 7px;
      background-image: linear-gradient(328deg, #3d4dd4, #4c6cd5);
      box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67);
      transition: 0.5s;
      &:hover,
      &:focus {
        background-image: linear-gradient(336deg,#2734a0,#465eac);
        box-shadow: 0 15px 10px -10px rgba(8, 15, 52, 0.67);
        transition: 0.5s;
        text-decoration: none;
        color: #fff; } } } }

.srvs_top_item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  &:last-child {
    margin-right: 0; }
  &__img {
    width: 55px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    img {
      z-index: 1; }
    &:before {
      position: absolute;
      content: '';
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-image: linear-gradient(153deg, #fad961, #f76b1c);
      box-shadow: 0 15px 10px -10px #3e50d4; } }
  &__title {
    .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 14px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff; } } }

.srvs_top_left {
  width: 445px;
  max-width: 100%;
  margin: 137px 50px 0 0;
  &__list {
    display: flex;
    margin-bottom: 25px; } }

.srvs_top_right {
  width: 863px;
  max-width: 100%;
  background-size: cover;
  height: 734px;
  .swiper_monitor {
    width: 92%;
    margin: 36px auto 0 auto;
    .swiper-slide {
      height: 450px;
      background-size: cover; } } }

.srvs_slider {
  width: 760px;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 727px;
    height: 724px;
    background: url("../img/br_slider_pseudo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  .swiper-slide {
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center; } }


// Benefits

.benefits {
  padding-bottom: 50px;
  background: #fff;
  &__title {
    margin-bottom: 74px;
    text-align: center;
    .title {
      font-size: 45px;
      margin: 0; } }
  &_list {
    display: flex;
    justify-content: space-between;
    width: 900px;
    max-width: 100%;
    margin: 0 auto; } }

.benefits_item {
  &__icon {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #4b6bd5, #3d4dd4);
    box-shadow: 0 15px 10px -10px #3e50d4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 25px auto;
    img {
      z-index: 1; } }
  &__title {
    margin: 0 auto;
    margin-bottom: 20px;
    width: 186px;
    max-width: 100%;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 25px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center; }
  &__text {
    width: 225px;
    max-width: 100%;
    p {
      font-family: 'OpenSans-Light';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e; } } }

// How to do

.howtodo {
  background: #fff;
  padding: 55px 0;
  background: #fff;
  &__title {
    margin-bottom: 65px;
    text-align: center;
    .title {
      font-size: 45px;
      margin: 0; } }
  &__wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    &:before {
      position: absolute;
      content: '';
      background: url("../img/how_line.svg");
      background-size: contain;
      height: 134px;
      width: 945px;
      top: 200px;
      left: 111px; } } }

.page-id-3192 {
  .howtodo {
    &__wrapper {
      width: 780px;
      max-width: 100%;
      margin: 0 auto;
      &:before {
        width: 515px;
        left: 89px;
        background-repeat: no-repeat;
        background: url("../img/how_line_4.svg"); } } }
  .howtodo_item {
    width: 174px;
    min-width: 174px;
    &:nth-child(odd) {
      margin-top: 120px; } } }

.howtodo_item {
  width: 184px;
  min-width: 184px;
  max-width: 100%;
  &:nth-child(odd) {
    margin-top: 130px; }
  &__title {
    min-height: 62px;
    margin-bottom: 14px;
    .title {
      color: #3852d5;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 25px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; } }
  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 60px auto;
    width: 89px;
    height: 89px;
    border-radius: 50%;
    background-image: linear-gradient(350deg, #f4f4f4, #dedede);
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 45px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      bottom: -17px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 18px solid #f1f1f1; } }
  &__text {
    max-width: 100%;
    padding: 0 30px;
    p {
      font-family: 'OpenSans-Light';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e; } } }

// How much

.howmuch {
  background: #fff;
  padding: 55px 0 110px 0;
  &__title {
    margin-bottom: 65px;
    text-align: center;
    .title {
      font-size: 45px;
      margin: 0; } }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto; } }

.howmuch_item {
  width: 300px;
  max-width: 100%;
  min-height: 450px;
  background-color: #ffffff;
  box-shadow: 0 14px 85px -31px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: 0.5s;
  &:hover,
  &:focus {
    transition: 0.5s;
    color: #333;
    text-decoration: none;
    transform: scale(1.02); }
  &__title {
    height: 40px;
    background-color: #435dd8;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .title {
      margin: 0;
      text-align: center;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff; }
    .number {
      margin: 0;
      position: absolute;
      top: -15px;
      right: 20px;
      width: 49px;
      height: 49px;
      background-color: #435dd8;
      border: solid 3px #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 26px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      span {
        font-size: 9px; } } }
  &__text {
    min-height: 86px;
    padding: 20px 30px 0 30px;
    margin-bottom: 14px;
    ul {
      padding: 0;
      margin: 0; }
    li {
      list-style: none;
      font-family: 'OpenSans-Regular';
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left; } }
  &__preview {
    margin-bottom: 5px;
    text-align: center;
    p {
      font-family: 'OpenSans-Bold';
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      color: #bcbcbc; } }
  &__slider {
    margin-bottom: 40px;
    position: relative;
    min-height: 200px;
    .swiper-slide {
      padding: 0 50px; }
    .swiper-button-prev {
      background-image: none!important;
      left: 5% !important;
      i {
        font-size: 18px;
        font-weight: bold; } }
    .swiper-button-next {
      right: 5% !important;
      background-image: none!important;
      text-align: right;
      i {
        font-size: 18px;
        font-weight: bold; } } }
  &__price {
    padding: 0 30px;
    height: 39px;
    background-color: #ffde62;
    font-family: 'OpenSans-Regular';
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    transition: 0.5s;
    text-decoration: none;
    &:hover {
      transition: 0.5s;
      color: #435dd9;
      text-decoration: none; } } }


.howmuch_last {
  width: 300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .howmuch_item {
    min-height: 175px; } }

.howmuch_full {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 300px;
  max-width: 100%;
  min-height: 210px;
  background-color: #7ed321;
  box-shadow: 0 14px 85px -31px #7ed321, 0 24px 23px -17px #7ed321;
  padding: 20px 30px 8px 30px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: 0.5s;
  &:hover,
  &:focus {
    transition: 0.5s;
    color: #333;
    text-decoration: none;
    transform: scale(1.02); }
  &__title {
    position: relative;
    z-index: 1;
    .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 22px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000; } }
  &__percent {
    z-index: 0;
    position: absolute;
    right: 20px;
    top: 50px;
    width: 101px;
    height: 101px;
    background-color: #7ed321;
    border: solid 5px #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 41px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    transform: rotate(-12deg);
    color: #fff; }
  &__price {
    position: relative;
    z-index: 1;
    font-family: 'OpenSans-Regular';
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
    text-decoration: none;
    &:hover {
      transition: 0.5s;
      color: #435dd9;
      text-decoration: none; } } }

.howmuch_price {
  &__old {
    width: 100%;
    text-align: right;
    font-size: 15px;
    text-decoration: line-through; } }

// Free consultation

.consultation {
  background-color: #fff;
  padding: 110px 0 80px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &__title {
    margin-bottom: 21px;
    text-align: center;
    .title {
      font-size: 45px;
      margin: 0; } }
  form {
    display: flex;
    flex-direction: column;
    input[type="tel"] {
      margin: 0 auto 28px auto;
      width: 333px;
      max-width: 100%;
      height: 40px;
      background-color: #ffffff;
      border: solid 1px #dfdfdf;
      font-family: 'OpenSans-Regular';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #b3b3b3;
      transition: 0.5s;
      outline: 0;
      padding-left: 15px;
      &::placeholder {
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #d8d8d8;
        outline: 0;
        transition: 0.5s;
        border: 1px solid #fff;
        transition: 0.5s; }
      &:focus {
        border-color: red;
        transition: 0.5s;
        padding-left: 15px; } } }
  .btn-blue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 167px;
    max-width: 100%;
    margin: 0 auto;
    height: 40px;
    border-radius: 7px;
    background-image: linear-gradient(336deg, #3d4dd4, #4c6cd5);
    box-shadow: 0 15px 10px -10px rgba(56, 82, 213, 0.67);
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    transition: 0.5s;
    &:hover {
      background-image: linear-gradient(336deg,#2734a0,#465eac);
      box-shadow: 0 15px 10px -10px rgba(8, 15, 52, 0.67);
      transition: 0.5s;
      text-decoration: none; } } }

// You het

.youget {
  background: #fff;
  padding: 55px 0 110px 0;
  &__title {
    margin-bottom: 65px;
    text-align: center;
    .title {
      font-size: 45px;
      margin: 0; } }
  &__wrapper {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center; } }

.youget_item {
  margin: 0 35px;
  width: 260px;
  max-width: 100%;
  position: relative;
  &:first-child {
    .youget_item__bg {
      position: absolute;
      width: 140%;
      height: 100%;
      left: -70%;
      top: -13%;
      z-index: 0; } }
  &:nth-child(2) {
    .youget_item__bg {
      position: absolute;
      width: 140%;
      height: 100%;
      left: -20%;
      top: 4%;
      z-index: 0; } }
  &:nth-child(3) {
    .youget_item__bg {
      position: absolute;
      width: 140%;
      height: 100%;
      right: -70%;
      top: -13%;
      z-index: 0; } }
  &__icon {
    position: relative;
    z-index: 1;
    margin: 0 auto 26px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    background-image: linear-gradient(158deg, #fad961, #f76b1c);
    box-shadow: 0 15px 10px -10px #f5a623;
    border-radius: 50%; }
  &__title {
    position: relative;
    z-index: 1;
    margin-bottom: 14px;
    .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 25px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; } }
  &__text {
    position: relative;
    z-index: 1;
    p {
      font-family: 'OpenSans-Light';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e; } } }

// portfolio

.portfolio {
  background: url("../img/bg_portfolio.jpg");
  padding: 85px 0 35px 0;
  .tab-pane {
    background-color: inherit; }
  &__title {
    margin-bottom: 65px;
    text-align: center;
    .title {
      font-size: 45px;
      margin: 0; } } }

// Reviews

.reviews_new {
  background: #fff;
  padding: 55px 0;
  .reviews-block {
    .title {
      font-size: 45px;
      margin-bottom: 65px; } } }

// White

.white {
  height: 100px;
  background: #fff; }



