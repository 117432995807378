// Banner 1

.banner_click {
  cursor: pointer; }

.banner_1 {
  background-color: #000000;
  height: 109px;
  width: 100%;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 109px; } }

.banner_1_wrap {
  position: relative;
  &__img_1 {
    position: absolute;
    left: -180px;
    img {
      width: 372px;
      max-width: 100%; } }
  &__title {
    margin-left: 140px;
    z-index: 1;
    width: 320px;
    max-width: 100%;
    display: flex;
    .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #ffffff;
      text-transform: uppercase;
      margin: 0;
      span {
        margin: 13px 0 18px 0;
        color: #3e5af5; } } }
  &__text {
    width: 205px;
    p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 0;
      line-height: 1.2; } }
  &__img_2 {
    position: relative;
    left: 30px;
    img {
      width: 169px;
      max-width: 100%; } }
  &__free {
    position: relative;
    top: -38px;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  &__btn {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 32px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

// Banner 2

.banner_2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 182px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 182px; } }

.banner_2_wrap {
  position: relative;
  &__img_1 {
    margin-right: 100px;
    img {
     height: 152px; } }
  &__img_2 {
    img {
      width: 251px; } }
  &__amazon {
    position: relative;
    p {
      position: absolute;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.96);
      &:first-child {
        top: -20px;
        left: -20px; }
      &:last-child {
        right: -25px;
        bottom: -10px; } }
    img {
      width: 158px; } }
  &__free {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  &__btn {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 32px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

// Banner 3

.banner_3 {
  background-color: #fff;
  height: 129px;
  width: 100%;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 129px; } }

.banner_3_wrap {
  position: relative;
  &__img_1 {
    position: absolute;
    left: -270px;
    img {
      height: 129px;
      max-width: 100%; } }
  &__title {
    margin-left: 165px;
    z-index: 1;
    width: 280px;
    max-width: 100%;
    display: flex;
    .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      margin: 0;
      span {
        margin: 13px 0 18px 0;
        color: #3e5af5; } } }
  &__text {
    width: 205px;
    p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000;
      margin-bottom: 0;
      line-height: 1.2;
      span {
        font-family: 'MuseoSansCyrl-Bold';
        color: #ffffff;
        background-color: #e70626;
        padding: 0 7px; } } }
  &__img_2 {
    margin-right: 80px;
    img {
      width: 235px;
      max-width: 100%; } }
  &__free {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  &__btn {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 32px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

// Banner 4

.banner_4 {
  background-color: #f8f8f8;
  height: 90px;
  width: 100%;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px; } }

.banner_4_wrap {
  position: relative;
  &__img_1 {
    position: absolute;
    left: -180px;
    img {
      height: 90px;
      max-width: 100%; } }
  &__title {
    z-index: 1;
    width: 275px;
    max-width: 100%;
    display: flex;
    .title {
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      margin: 0;
      span {
        margin: 13px 0 18px 0;
        color: #3e5af5; } } }
  &__text {
    margin-right: 120px;
    width: 255px;
    p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000;
      margin-bottom: 0;
      line-height: 1.2; }
    img {
      width: 80px;
      position: relative;
      top: 5px;
      left: 5px; } }
  &__img_2 {
    position: absolute;
    right: 140px;
    img {
      height: 90px;
      max-width: 100%;
      position: relative;
      right: 40px; } }
  &__free {
    position: relative;
    top: -28px;
    margin-right: 13px;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 19px;
    color: #000;
    padding-right: 30px;
    width: 200px;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    text-align: right;
    text-transform: uppercase;
    background: url("../img_banners/bg_free.png") no-repeat;
    background-position: center;
    background-size: contain; }
  &__btn {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 32px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

// Banner 5

.banner_5 {
  margin: 0 auto;
  padding: 16px 7px 38px 7px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  &__img_1 {
    margin-bottom: 200px;
    width: 139px;
    transform: rotate(40deg);
    position: relative;
    top: 20px; }
  &__img_2 {
    width: 213px;
    margin: 0 auto 7px auto; }
  &__amazon {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 67px;
      margin: 0 2px 0 5px; }
    p {
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.96); } }
  &__btn {
    a {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 38px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

// Banner 6

.banner_6 {
  margin: 0 auto;
  padding: 25px 19px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  &__title {
    padding: 0 10px;
    .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      line-height: 1;
      span {
        display: block;
        margin: 13px 0 18px 0;
        color: #3e5af5;
        margin: 0; } } }
  &__text {
    padding: 0 10px;
    p {
      margin-bottom: 7px;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      img {
        margin-top: 7px;
        display: block; } } }
  &__btn {
    a {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 226px;
      height: 38px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

.banner_6_text_amazon {
  margin-bottom: 235px; }

// Banner 7

.banner_7 {
  margin: 0 auto;
  padding: 25px 19px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  &__title {
    padding: 0 10px;
    .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #000;
      text-transform: uppercase;
      line-height: 1;
      span {
        color: #3e5af5; } } }
  &__text {
    padding: 0 10px;
    p {
      margin-bottom: 7px;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 1.2;
      span {
        font-family: 'MuseoSansCyrl-Bold';
        color: #ffffff;
        background-color: #e70626;
        padding: 0 7px; } } }
  &_text_2 {
    margin-bottom: 13px;
    padding: 0; }
  &__btn {
    a {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 226px;
      height: 38px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

.banner_7_text_amazon {
  margin-bottom: 256px; }

// Banner 8

.banner_8 {
  margin: 0 auto;
  padding: 25px 19px;
  width: 270px;
  height: 540px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  &__title {
    padding: 0 10px;
    .title {
      margin: 0;
      font-family: 'MuseoSansCyrl-Bold';
      font-size: 35px;
      color: #fff;
      text-transform: uppercase;
      line-height: 1;
      span {
        color: #3e5af5; } } }
  &__text {
    width: 175px;
    max-width: 100%;
    padding: 0 10px;
    p {
      margin-bottom: 7px;
      font-family: 'MuseoSansCyrl-Light';
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      line-height: 1.2;
      span {
        font-family: 'MuseoSansCyrl-Bold';
        color: #ffffff;
        background-color: #e70626;
        padding: 0 7px; } } }
  &_text_2 {
    margin-bottom: 203px;
    max-width: 100%;
    background-image: linear-gradient(#d0011b, #d0011b);
    background-blend-mode: multiply;
    position: relative;
    right: -26px;
    width: 225px;
    padding: 3px 19px 4px 15px;
    text-align: right;
    opacity: 0.8;
    p {
      font-size: 14px; } }
  &__btn {
    a {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 226px;
      height: 38px;
      line-height: 32px;
      border-radius: 38px;
      background-image: linear-gradient(to bottom, #3865f6, #424ff3);
      box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64);
      font-family: 'MuseoSansCyrl-Light';
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #6a8eff, #424ff3);
        box-shadow: 0 10px 21px 0 rgba(56, 100, 246, 0.64); }
      &:focus {
        transition: 0.5s;
        color: #fff;
        text-decoration: none;
        background-image: linear-gradient(to bottom, #3865f6, #424ff3);
        box-shadow: none; } } } }

.banner_8_text_amazon {
  margin-bottom: 36px; }

// Banner guide modal

.guide_modal {
  background: #fff;
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  border: 3px solid rgb(240, 149, 28);
  padding-bottom: 40px;
  box-sizing: border-box;
  position: relative;
  &__top {
    margin-bottom: 24px; } }

.guide {
  &__close {
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer; } }

.modal_item {
  width: 453px;
  max-width: 100%;
  margin: 0 auto 16px auto;
  &__title {
    margin-bottom: 21px;
    text-align: center;
    font-family: 'MuseoSansCyrl-Bold';
    font-size: 30px;
    color: #000000; }
  label {
    font-family: 'OpenSans-Regular';
    font-size: 20px;
    color: #000000;
    margin-bottom: 6px; }
  input {
    height: 58px;
    background-color: #eeeeee;
    font-family: 'OpenSans-Light';
    font-weight: 900;
    font-size: 20px;
    color: #9b9b9b;
    transition: 0.5s;
    border: 2px solid #eee;
    padding-left: 12px;
    box-sizing: border-box;
    outline: 0;
    &:focus {
      transition: 0.5s;
      border-color: #3852d5;
      color: #000;
      padding-left: 12px; }
    &::placeholder {
      font-family: 'OpenSans-Light';
      font-weight: 900;
      font-size: 20px;
      color: #9b9b9b; } }
  &__third {
    label {
      display: flex;
      align-items: center;
      font-family: 'OpenSans-Regular';
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      span {
        margin-right: 7px; } } }
  input[type="checkbox"] {
    display: none; }

  .my_check span {
    cursor: pointer;
    padding-left: 0;
    width: 28px;
    height: 28px;
    background-color: #eeeeee;
    &:before {
      content: "";
      display: inline-block;
      height: 22px;
      width: 24px;
      margin: 0 5px 0 0;
      background: url("../img_banners/accept.png") no-repeat;
      background-position: center;
      background-size: cover;
      left: 5px;
      position: relative;
      font-size: 29px; } }
  .un_active {
    span {
      &:before {
        display: none; } } }


  .wpcf7-form-control-wrap {
    position: relative; }
  .wpcf7-submit {
    display: block;
    width: 301px;
    height: 48px;
    border-radius: 7px;
    background-image: linear-gradient(175deg, #fad961, #f76b1c);
    box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.58);
    transition: 0.5s;
    cursor: pointer;
    font-family: 'OpenSans-Light';
    font-weight: 900;
    font-size: 20px;
    color: #ffffff;
    border: 0;
    margin: 0 auto;
    &:hover {
      transition: 0.5s;
      opacity: 0.8; } }
  input[disabled="disabled"] {
    opacity: 0.4;
    &:hover {
      opacity: 0.4; } }
  span.wpcf7-not-valid-tip {
    position: absolute;
    width: 100%;
    left: 0;
    top: 19px; }
  .ajax-loader {
    position: absolute;
    right: 50px;
    top: 20px; }
  .wpcf7-not-valid {
    border: solid 3px #d0021b!important;
    background-color: #f8f8f8;
    margin-bottom: 20px; }
  .wpcf7-not-valid-tip {
    font-family: 'OpenSans-Regular' !important;
    font-weight: 600;
    font-size: 14px!important;
    color: #d0021b!important;
    top: 34px!important;
    text-align: center; } }

// Custom

.wppaslink {
  display: flex;
  text-decoration: none!important; }

.banner_catalog__btn {
  .wppaslink {
    display: none; } }
